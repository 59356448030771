import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../classes/user';
import { Organisation } from '../classes/organisation';
import { ServerResponse } from '../interfaces/server-response';
import { environment } from '../../../environments/environment';
import { AppService } from 'src/app/shared/services/app.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  org : Organisation;
  user: User;
  bDemo: boolean;
  sessionId : string;
  sessionURL : string;
  versionURL : string;
  
  private _currentUser: BehaviorSubject<User> = new BehaviorSubject(null);
  public readonly currentUser: Observable<User> = this._currentUser.asObservable();

  private _currentOrg: BehaviorSubject<Organisation> = new BehaviorSubject(null);
  public readonly currentOrg: Observable<Organisation> = this._currentOrg.asObservable();

  private currentVersion: string = environment.version;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookies: CookieService,
    private appService: AppService
  ) { 
    this.org = null;
    this.user = null;
    this.bDemo = false;
    this.sessionId = null;
    this.sessionURL = environment.sessionURL;
    this.versionURL = environment.versionURL;

    appService.bDemo.subscribe(bFlag => {
      this.bDemo = bFlag;
    })

  }

  private handleReponse(response : ServerResponse, ) : boolean | string{
    let result : boolean | string = false;
    
    //console.log(response.result);
   
    if (response.result == 'OK')  {

      if (response.hasOwnProperty("response") && response.response.hasOwnProperty("data")) {
        this.user = new User();
        this.user.adoptUser(response.response.data);
        if (response.response.demo) {
          this.appService.setDemo(true);
        }

      } else {
        this.user = null;
      }

      this._currentUser.next(this.user);
      
      result = true;
  
    } else {
      throw response.errors;
    }

    return result;
  }

  private doValidateOrg (strTestOrg: string) : boolean {
    let result : boolean = false;

    if (environment.arrValidOrgs.indexOf(strTestOrg) > -1) {

      this.org = new Organisation(strTestOrg);
      result = true; 

      this._currentOrg.next(this.org);
    } else {
      this.org = null;
      this._currentOrg.next(null);
    }


    return result;
  }

  signOff () : Observable<boolean> {
    let result : boolean = false;
    this.user = null;
    this._currentUser.next(null);

    let http$ = this.http.post<ServerResponse>(this.getServerUrl(), {}, {withCredentials: true});
    
    let request$ = http$.pipe (
      map(response => {
        if (response.result == 'OK') {
          return true;
        } else {
          return true;
        }
      }),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;
  }

  getServerUrl() : string {
    return '/' + this.org.getUrl() + this.sessionURL;
  }

  getVersionUrl(): string {
    return '/' + this.org.getUrl() + this.versionURL + "?" + Math.floor(Math.random() * 1000);  
  }

  hasUser() : boolean {
    return this.user !== null; 
  }
  
  getUser() : User {
    return this.user; 
  }
  
  hasOrg() : boolean {
    return this.org !== null;
  }
  
  getOrgUrl() : string {
    return this.org.getUrl();
  }
  
  validateOrg (strTestOrg : string) : boolean {
    let result : boolean = false;

    if (strTestOrg !== '') {
      result = this.doValidateOrg(strTestOrg);    
    }

    return result;
  }

  restartSession(strSessionId : string, strOrgUrl : string) : Observable<boolean> {

    let http$ = this.http.post<ServerResponse>(this.getServerUrl(), {sessionId: strSessionId}, {withCredentials: true});
    
    let request$ = http$.pipe (
      map(response => {
        if (this.handleReponse(response)) {   
          if (response.resultCode !== undefined && response.resultCode === -1) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }),
      catchError (err => {
        return throwError(err)
      })
    );
    
    return request$
  }  

  matchOrg(strTestOrg : string) : boolean {
    
    let result : boolean = false;
    
    if (strTestOrg && this.hasOrg() && this.org.getUrl() === strTestOrg) {
      result = true;
    } else  {
      result = false; 
    }

    return result;
  }

  validateUser(strUserName: string, strPassword: string) : Observable<boolean>  {
    
    let http$ = this.http.post<ServerResponse>(this.getServerUrl(), {userName: strUserName, password: strPassword, demo:this.bDemo}, {withCredentials: true});
    
    let request$ = http$.pipe (
      catchError (err => {
        let error = "";
        if (err instanceof HttpErrorResponse) {
          if (!err.ok) {
            error = "An unexpected communcation error occurred (" + err.status + ")";

            if (err.status == 200) {
              error = error + ": " + err.message;
            } else if (err.status == 408) {
              error = error + ": Timeout";
            }
          } else {
            error = "An unexpected exception occurred (" + err.status + ")";            
          }
        } else {
          error = "An unknown error occurred";
        }

        return throwError([error]);
      }),      
      map(response => {
        if (this.handleReponse(response)) {
          return true;
        } else {
          return false;
        }
      }),
      catchError (err => {
        return throwError(err)
      })
    );
    
    return request$
  }

  hasPermission (strPermission: string) : boolean {

    let result : boolean = false;

    if (this.hasUser() && this.user.hasPermission(strPermission)) {
      result = true;
    }

    return result;

  } 

  navigate(strUrl : string) : void {
    this.router.navigate([this.getOrgUrl() + strUrl]);
  } 

  location(strUrl: string): void {
    this.setLocation('/' + this.getOrgUrl() + '/' + strUrl);
  }
  
  setLocation(strUrl: string) : void {
    window.location.href = strUrl + (environment.serverSuffix ? (strUrl.indexOf('?') === -1 ? '?' : '&') + environment.serverSuffix : '');
  }

  getVersion(): Observable<boolean> {
    let result: boolean = false;
    this.user = null;
    this._currentUser.next(null);

    let http$ = this.http.get<any>(this.getVersionUrl());

    let request$ = http$.pipe(
      map(response => {
        if (response.hasOwnProperty("version")) {
          if (response.version != this.currentVersion) {
            this.currentVersion = response.version;
            return true;
          } else {
            return false;
          }  
        } else {
          return false;
        }
      }),
      catchError(err => {
        return throwError(() => new Error("cannot read version"));
      })
    );

    return request$;
  }

}
