import {ListEntry} from 'src/app/shared/interfaces/list-entry'
import {OrgConfig} from 'src/app/shared/interfaces/org-config'

export class User {

  strUserName : string;
  strFirstName : string;
  strLastName : string;
  arrPermissions : string [];
  arrLibraries : ListEntry [];
  arrLocations : ListEntry [];
  arrReports : ListEntry [];
  arrPartyReports : ListEntry [];
  arrUtils : ListEntry [];
  arrMaintenance : ListEntry [];
  orgConfig : OrgConfig;
  
  constructor () {
    this.strFirstName = "";
    this.strLastName = "";
    this.strUserName = "";
  }   

  adoptUser(user : User) : boolean {

    let result = true;

    this.strUserName = user.strUserName;
    this.strFirstName = user.strFirstName;
    this.strLastName = user.strLastName;
    this.arrPermissions = user.arrPermissions;
    this.arrReports = user.arrReports;
    this.arrPartyReports = user.arrPartyReports;
    this.arrLocations = user.arrLocations;
    this.arrLibraries = user.arrLibraries;
    this.arrUtils = user.arrUtils;
    this.arrMaintenance = user.arrMaintenance;
    this.orgConfig = user.orgConfig;
    
    return result; 
  }


  hasPermission (strPermission : string) : boolean {

    let result : boolean = false;

    if (this.arrPermissions.indexOf(strPermission) !== -1 ) {
      result = true;
    }

    return result;
  }

  get fullName () : string {
    return this.strFirstName + ' ' + this.strLastName;
  }

  getReportCount() : number {
    var result : number = 0;

    this.arrReports.forEach(entry => {
      if (entry.val) {
        result++;
      } else {
        entry.mem.forEach(subEntry => {
          result++;
        });
      }
    });

    return result;
  }

  getLocationCount() : number {
    var result : number = 0;

    this.arrLocations.forEach(entry => {
      if (entry.val) {
        result++;
      } else {
        entry.mem.forEach(subEntry => {
          result++;
        });
      }
    });

    return result;
  }
}
