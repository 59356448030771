<div class="carina-theme">
  <div class="Homepage">
    <div class="halfColumn" *ngFor="let column of config">
      <div *ngFor="let section of column">
        <div [ngSwitch]="section.type">
          <carina-home-section *ngSwitchCase="'location'" [arrEntries]="user.arrLocations" [bShowCount]="true" strName="Journals" strColour="{{section.colour}}-{{section.shade}}" (selected)="onLocation($event)"></carina-home-section>
          <carina-home-section *ngSwitchCase="'util'"[arrEntries]="user.arrUtils" [bShowCount]="false" strName="Utilities" strColour="{{section.colour}}-{{section.shade}}" (selected)="onUtility($event)"></carina-home-section>
          <carina-home-section *ngSwitchCase="'report'" [arrEntries]="user.arrReports" [bShowCount]="true" strName="Reports" strColour="{{section.colour}}-{{section.shade}}" (selected)="onReport($event)"></carina-home-section>
          <carina-home-section *ngSwitchCase="'report'" [arrEntries]="user.arrPartyReports" [bShowCount]="true" strName="Individual Reports" strColour="{{section.colour}}-{{section.shade}}" (selected)="onReport($event)"></carina-home-section>
          <carina-home-section *ngSwitchCase="'lib'" [arrEntries]="user.arrLibraries" [bShowCount]="false" strName="Libraries" strColour="{{section.colour}}-{{section.shade}}" (selected)="onLibrary($event)"></carina-home-section>
          <carina-home-section *ngSwitchCase="'maint'" [arrEntries]="user.arrMaintenance" [bShowCount]="false" strName="Configure" strColour="{{section.colour}}-{{section.shade}}" (selected)="onMaintenance($event)"></carina-home-section>
        </div>
      </div>
    </div>
    <div class="halfColumn">
    </div>  
  </div>
</div>