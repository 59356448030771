<ng-container *ngIf="stepData.show">
  <div class="actionItem">
    <div class = "assistAction">
      <div class = "actionHeading">
        <span class="actionLabel">{{stepData.title}}</span>
      </div>
    </div>
    <div class="assistAction">
      <div class="actionText">
        <div *ngFor="let stepAction of stepData.stepEntries" >
          <mat-accordion multi>
            <carina-action-entry [stepAction]="stepAction" [allowCancel]="allowCancel"></carina-action-entry>
          </mat-accordion>
        </div>
      </div>
      <div class="actionButtons">
        <button *ngIf="stepData.complete" 
          mat-raised-button 
          (click)="process()"
          [class.actionProcessed] = "stepData.doneProcess && !stepData.processFailure" 
          [class.actionFailure] = "stepData.doneProcess && stepData.processFailure" 
          [class.mat-primary] = "!stepData.doneProcess" 
          [class.spinner]="actionInProgress" 
          [disabled]="actionInProgress || systemInProgress || !stepData.canProcess">{{stepData.buttonText}}</button>
      </div>
    </div>
  </div>
</ng-container>

<!--   -->