
<ng-container *ngIf="stepAction.active && stepAction.entryCount > 0"  > 
  <mat-expansion-panel [hideToggle]="stepAction.contentEntries.length==0">
    <mat-expansion-panel-header>
    <mat-panel-title>
      <div [class.actionHighlight] = "isHighlight" class="actionSection">
        <mat-checkbox *ngIf="allowCancel" class="actionCheckbox" [(ngModel)]="stepAction.canProcess" color="primary" (click)="$event.stopPropagation()">
        </mat-checkbox>
        <div *ngFor="let elements of stepAction.textEntries" [class.actionDisabled] = "!stepAction.canProcess"> 
          <carina-action-element *ngFor="let element of elements" [actionElement]="element"></carina-action-element>
          <span>&nbsp;</span>
        </div>
      </div> 
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div *ngFor="let elements of stepAction.contentEntries" class="actionContent"> 
    <carina-action-element *ngFor="let element of elements" [actionElement]="element"></carina-action-element>
    <span>&nbsp;</span>
  </div>
  </mat-expansion-panel>
</ng-container>