import { Component, OnInit, Input, AfterViewChecked} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { StepPromptMulti } from '../../classes/step-prompt-multi';
import { ValueObject } from '../../classes/value-object';
import { EnumStepType } from '../../enums/enum-step-type';
import { IStepComponent } from '../../interfaces/icomponent-step';
import { StepBaseComponent } from '../steps/step-base.component'

@Component({
  selector: 'carina-step-mu',
  templateUrl: './prompt-multi.component.html'
})
export class PromptMultiComponent extends StepBaseComponent implements OnInit, IStepComponent {

  @Input() stepData: StepPromptMulti;

  isSingleSelect : boolean = false;
  selected : string[] = [];
  checked : boolean[] = [];
  nextText : string = "";
  completeOnSelect : boolean = false;

  strSelected : string = "";
  
  dataValue : ValueObject;
  updateScroll : boolean = false;
  hasNext : boolean = true;
  hasBullet : boolean = false;
  entryStyle : object = null;

  constructor( ) {
    super();
  }

  ngOnInit(): void {

    this.checked = Array(this.stepData.arrEntryValue.length).fill(false);

    if (this.stepData.type == EnumStepType.Prompt_Single) {
      if (this.stepData.promptConfig.hasOwnProperty("hasNext") && !this.stepData.promptConfig.hasNext) {
        this.hasNext = false;  
      }  
      if (this.stepData.promptConfig.hasOwnProperty("justify") && this.stepData.promptConfig.justify == "right") {
        this.entryStyle = {justifyContent:"flex-end"};  
      }  
      if (this.stepData.promptConfig.hasOwnProperty("completeOnSelect") && this.stepData.promptConfig.completeOnSelect) {
        this.completeOnSelect = true;  
      }  
    } else if (this.stepData.type == EnumStepType.Prompt_Checkbox || this.stepData.type == EnumStepType.Prompt_Radio) {
      this.hasNext = false; 
    }

    if (this.stepData.promptConfig.hasOwnProperty("nextText") && this.stepData.promptConfig.nextText) {
      this.nextText = this.stepData.promptConfig.nextText;  
    }  

    if (this.stepData.promptConfig.hasOwnProperty("hasBullet") && this.stepData.promptConfig.hasBullet) {
      this.hasBullet = this.stepData.promptConfig.hasBullet;  
    }  

    this.dataValue = this.stepData.dataValue as ValueObject;

    if (this.stepData.type == EnumStepType.Prompt_Single || 
      this.stepData.type == EnumStepType.Prompt_Radio) {
      this.isSingleSelect = true;
    }

    this.assistUpdateSelection();

  }

  select(index: number) {

    if (this.checked[index]) {
      delete this.selected[index];
      this.checked[index] = false;
    } else {

      if (this.isSingleSelect) {
        this.checked.forEach((val, idx, arr) =>{
          arr[idx] = false;
        });
      }

      this.checked[index] = true;

      if (this.isSingleSelect) {
        this.selected.forEach((val, idx, arr) => {
          if (idx != index) {
            delete arr[idx];
          }
        })
      }
    } 
    
    this.updateStepValue();

  }

  updateStepValue() {

    let arrSel = [];
    let str = "";
    let sep = "";

    this.selected.forEach((entry, idx, arr) => {
      if (this.stepData.arrEntryData) {
        arrSel.push(this.stepData.arrEntryData[idx]);
      } else {
        arrSel.push(this.stepData.arrEntryValue[idx]);
      }
      str += sep + entry;
      if (sep == "") {
        sep = ", ";
      }
    });

    if (this.isSingleSelect) {
      if (arrSel.length > 0) {
        this.stepData.value = arrSel[0];
      } else {
        this.stepData.value = "";
      }

    } else {
      this.stepData.value = arrSel;
    }  

    //this.stepData.updateValue();

    this.strSelected = str;

    if (!this.hasNext || this.completeOnSelect) {
      this.process();
    }

  }

  process() {
    this.stepData.updateValue();

    if (this.isSingleSelect && !this.hasNext && this.strSelected == '') {
      this.stepData.processUpdate();
    } else {
      this.stepData.processNext();
    }
  }

  update() {
    this.stepData.processUpdate(); 
    this.updateScroll = true;  
  }

  onAssistUpdate() {
    if (this.isSingleSelect) {
      if (this.dataValue.isUndefined) {
        this.checked = [];
        this.selected = [];        
      }
    } else {

      let isUpdated : boolean = false;
      let newSelected : string[] = [];
      this.checked = Array(this.stepData.arrEntryValue.length).fill(false);    

      this.selected.forEach((entry, idx, arr) => {
        let index = this.stepData.arrEntryValue.indexOf(entry);

        if (index > -1) {
          newSelected[index] = entry;
          this.checked[index] = true;
          if(index !+ idx) {
            isUpdated = true;
          }
        } else {
          isUpdated = true;
        }
      });      

      this.selected = newSelected;

      if (isUpdated) {
        this.updateStepValue();
      }
    } 

    this.assistUpdateSelection();    

  }

  assistUpdateSelection() {

    if (this.isSingleSelect) {

      this.checked = Array(this.stepData.arrEntryValue.length).fill(false);

      // Assume the selection is a string
      let value : string = this.dataValue.getValue() as unknown as string;

      if (value !== "") {
           
        let index = this.stepData.arrEntryValue.indexOf(value);
        
        if (index >= 0) {
          this.checked[index] = true;
          this.selected[index] = value;
          this.updateStepValue();
        }
      }

    } else {

      // Assume the selection is a string
      let values : string [] = this.dataValue.getValue() as unknown as string[];

      this.stepData.arrEntryValue.forEach((entry, idx) => {

        if (values !== null && values.includes(entry)) {
          this.selected[idx] = entry;
          this.checked[idx] = true;
        } else {
          delete this.selected[idx];
          this.checked[idx] = false;
        }
      }); 
      this.updateStepValue();
    }

  }


  onToggleGroupChange(event: MatButtonToggleChange) {
    const { value } = event;

    let selectedValue = Array.isArray(event.value) ? event.value : [event.value];
    this.selected = [];

    selectedValue.forEach(value => {

      let index = this.stepData.arrEntryValue.indexOf(value);
      
      if (index > -1) {
        this.selected[index] = value;
      }
    })
  }
}
