import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { StepEvalBranch } from '../../classes/step-eval-branch';

import { StepEvalSet } from '../../classes/step-eval-set';
import { StepEvalDeclare } from '../../classes/step-eval-declare';
import { EnumDebugLevel } from '../../enums/enum-debug-level';
import { IStepComponent } from '../../interfaces/icomponent-step';
import { AssistService } from '../../services/assist.service';
import { StepBaseComponent } from './step-base.component';

@Component({
  selector: 'carina-step-eval-set',
  templateUrl: './step-eval.component.html'
})
export class StepEvalComponent extends StepBaseComponent implements OnInit, OnDestroy, IStepComponent {
  
  @Input() stepData: StepEvalSet | StepEvalBranch | StepEvalDeclare;
  protected _subscriptions: Subscription[] = [];
  public debugLevel : EnumDebugLevel;

  constructor(private assistService: AssistService) { 
    super(); 
    this._subscriptions.push (
      this.assistService.debugLevel.subscribe((level) => {
        this.debugLevel = level;
      })
    );
  }  

  ngOnInit(): void {
    
  }

  ngOnDestroy() : void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get show () : boolean {
    return this.stepData.active && (
      (this.stepData.processText !== "") ||
      (this.stepData.dataValue !== null && this.stepData.dataValue.isError) ||
      (this.debugLevel == EnumDebugLevel.debug_all) ||
      (this.debugLevel == EnumDebugLevel.debug_info && this.stepData.dataValue !== null && this.stepData.dataValue.isPending)
    );
  }  

  get showSpinner () : boolean {
    return (this.stepData.processText !== "" && !(this.stepData.dataValue !== null && this.stepData.dataValue.isError));
  }

}
 