import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service'
import { AppService } from '../services/app.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor (
    private authService: AuthService,
    private appService: AppService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    let result : boolean = false;  
    let strLayout : string = 'default'
    
    if (this.authService.hasPermission("SGON")) {

      switch (next.routeConfig.path) {
        case 'home':
          result = true;
        break;
        case 'configure':
          result = this.authService.hasPermission("MTAL");
        break;  
        case 'stats':
          result = this.authService.hasPermission("JRST");
        break;  
        case 'assist':
          result = true  //this.authService.hasPermission("MTAL");
          strLayout = 'wide';
        break;  
        case 'pdf':
          result = true  //this.authService.hasPermission("MTAL");
          strLayout = 'wide';
        break;          
      }
    }

    if (result) {
      this.appService.setPageLayout(strLayout);
    }

    return result;
  }
}
