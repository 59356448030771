
<div *ngIf="stepData.active" class="assistStep">
  <div class="stepOffset">
  </div>
  <div (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" class="stepText">
    <p [innerHTML]="stepData.text"></p>
  </div>
  <div class="stepShowIcon">
    <div class="stepShowIconLeft"></div>
    <div class="stepShowIconRight">
      <div *ngIf="stepData.icon" [ngClass]="'stepIcon ' + stepData.icon"></div>
    </div>
  </div>
</div> 