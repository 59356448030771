<ng-container *ngIf="stepData.active">
  <div class="assistStep">
    <div class="stepOffset">
    </div>
    <div #scrollTarget class="stepText stepData">
      <div>
        <p>{{stepData.text[0]}}</p>
        <ng-container *ngIf="stepData.text.length > 1" >
          <ul>
            <li *ngFor="let txt of stepData.text.slice(1)">
              {{txt}}
            </li>
          </ul>   
        </ng-container>
        </div>
      </div>
    <div class="stepButtons">
      <div class="stepButtonEnd">
        <mat-button-toggle-group *ngIf="!stepData.stepCompleted" name="Next" aria-label="Next">
          <mat-button-toggle class="mat-button" (click)="process()">Next</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
</ng-container>

