<ng-container *ngIf="stepData.active">
  <div #scrollTarget (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" class="assistStep">
    <div class="stepOffset">
      <div class="stepButtonStart">
        <mat-slide-toggle *ngIf="isToggle" [(ngModel)]="toggleValue" (change)="slide()"></mat-slide-toggle>
      </div>
    </div>
    <div class="stepText">
      <div>
        <p>{{stepData.text[0]}}</p>
        <ng-container *ngIf="stepData.text.length > 1" >
          <ul>
            <li *ngFor="let txt of stepData.text.slice(1)">
              {{txt}}
            </li>
          </ul>   
        </ng-container>
      </div>
    </div>
    <div *ngIf="!isToggle" class="stepButtons">
      <div class="stepButtonEnd">
        <mat-button-toggle-group  [(ngModel)]="stepData.value" name="YesOrNo" aria-label="Yes or No">
          <mat-button-toggle class="mat-button" (click)="process()" value="yes">Yes</mat-button-toggle>
          <mat-button-toggle class="mat-button" (click)="process()" value="no">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div *ngIf="isToggle" class="stepShowIcon">
      <div class="stepShowIconLeft"></div>
      <div class="stepShowIconRight">
        <div *ngIf="isToggle && !toggleValue && stepData.icon" [ngClass]="'stepIcon ' + stepData.icon"></div>
      </div>      
    </div>      
  </div> 
</ng-container>
