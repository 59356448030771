export enum EnumStepType {

  Prompt_Top = 0,
  Prompt_Next = 1,
  Prompt_YN = 2,
  Prompt_Entry = 3,
  Prompt_List = 4,
  Prompt_Multi = 5,
  Prompt_Dropdown = 6,
  Prompt_Text = 7,
  Prompt_Section = 8,
  Prompt_SubSec = 9,
  Prompt_Single = 10,
  Prompt_Toggle = 11,
  Prompt_Radio = 12,
  Prompt_Checkbox = 13,
  Prompt_Flags = 14,

  Value_Item = 100,
  Action_Item = 200,
  Action_Entry = 201,
  Action_Entry_Count = 202,
  Action_Entry_Inc = 203,

  Eval_Set = 400,
  Eval_Branch = 401,
  Eval_Declare = 402,
  Eval_While = 403,
  Eval_Group = 404,
  Eval_Proc = 405

  //Eval_Repeat = "Repeat",

}
