import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../shared/services/auth.service';
import { AppService } from '../../../../shared/services/app.service';
import { HeaderButton } from '../../../../shared/classes/header-button';
import { AssistConfig } from '../../classes/assist-config';
import { AssistService } from '../../services/assist.service';
import { Observable, Subscription } from 'rxjs';
import { AssistStateService } from '../../services/assist-state.service';
import { MessagePoster } from '../../classes/message-poster';
import { ViewOptionType } from '../../types/view-option-type'

@Component({
  selector: 'carina-assist',
  templateUrl: './assist.component.html',
  styleUrls: ['./assist.component.scss']
})
export class AssistComponent implements OnInit, OnDestroy {   

  config: AssistConfig = null;

  public stepViewOptions : ViewOptionType = {enable: true, width: 5};
  public dataViewOptions : ViewOptionType = {enable: true, width: 5}
  public actionViewOptions : ViewOptionType = {enable: true, width: 4}

  private _subscriptions: Subscription[] = [];
  private messagePoster = new MessagePoster();

  public isResizing : number = 0;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private appService: AppService,
    private assistService: AssistService,
    private assistStateService : AssistStateService) 
  { }

  ngOnInit(): void {

    this._subscriptions.push (
      this.route.params.subscribe(params => { 
        this.appService.setPageLayout("wide");      
        this.appService.setPageTitle("Workflow Assist");
        if (this.authService.hasUser()) {
          this.initPage();
        }
      })
    );

    this._subscriptions.push (
      this.assistStateService.onLayout.subscribe(options => { 
        this.setLayoutOptions(options);
      })
    );
  }

  ngOnDestroy() : void {
    this.messagePoster.postMessage('fromAppAssistClose', {});  

    this.assistStateService.setActive(false);
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this.assistStateService.assistDeactivate();
    this.assistService.assistDeactivate();
    this.appService.setPageLayout("default");
  }

  initPage(): void {

    let buttons: HeaderButton[] = [];

    buttons.push(new HeaderButton("hdrbtnReset", "reset", "Reset Workflow Assist", false, ""));
    
    buttons.push(new HeaderButton("hdrbtnHome", "home", "Home", false, "/home"));

    this.appService.setHeaderButtons(buttons);
     
    this._subscriptions.push (
      this.assistService.workflowConfig.subscribe(config => {
        this.config = config;
      })
    );

    this._subscriptions.push (
      this.appService.onHeaderButtonsPress.subscribe(button => {
        this.handleHeaderButton(button);
      })
    );

    this.assistStateService.setActive(true);

    this.messagePoster.postMessage('fromAppAssistOpen', {});  
    
  }

  setLayoutOptions(options: any) {

    if (options.hasOwnProperty("stepView")) {
      this.setViewOptions(this.stepViewOptions, options.stepView);
    }
    if (options.hasOwnProperty("dataView")) {
      this.setViewOptions(this.dataViewOptions, options.dataView);
    }
    if (options.hasOwnProperty("actionView")) {
      this.setViewOptions(this.actionViewOptions, options.actionView);
    }


  }

  setViewOptions(options: ViewOptionType, newOptions: ViewOptionType) {

    if (newOptions.hasOwnProperty("enable")) {
      options.enable = newOptions.enable;
    }
    if (newOptions.hasOwnProperty("width")) {
      options.width = newOptions.width;
    }
    if (newOptions.hasOwnProperty("title")) {
      options.title = newOptions.title;
    }

  }

  handleHeaderButton(button : HeaderButton) {

    if (button.name == "reset") {
      this.assistService.reset();
    }

  }

  doResize(e : MouseEvent) {

    if (this.isResizing == 1) {

      this.resize(e, this.stepViewOptions, this.dataViewOptions, "step");     

    } else if (this.isResizing == 2) {

      this.resize(e, this.dataViewOptions, this.actionViewOptions, "data");     

    }
  }

  resize(e : MouseEvent, leftOpts : ViewOptionType, rightOpts : ViewOptionType, leftId : string) {

    let leftVal = leftOpts.width;
    let rightVal = rightOpts.width;
    let totalVal = leftVal + rightVal;

    let leftDiv = document.getElementById(leftId);       
    let leftWidth = leftDiv.clientWidth;

    let width = e.clientX - leftDiv.offsetLeft - 4;

    console.log("e.clientX: "+ e.clientX + " e.clientX: "+ e.clientX + " leftDiv.offsetLeft: "+ leftDiv.offsetLeft);

    console.log("leftVal: "+ leftVal + " width: "+ width + " leftWidth: "+ leftWidth);
    
    if (leftWidth <= 0) {
      if (width > 0) {
        leftVal = 0.01;
      } else {
        leftVal = 0;
      }
    } else {
      leftVal = leftVal * width / leftWidth;
    }

    if (leftVal > totalVal) {
      leftVal = totalVal;
    }

    rightVal = totalVal - leftVal;
    console.log("rightVal: "+ rightVal + " totalVal: "+ totalVal + " leftVal: "+ leftVal);
    
    leftOpts.width = leftVal;
    rightOpts.width = rightVal;

    e.stopPropagation();
    e.preventDefault();

  }

  endResize() {
    this.isResizing = 0;
  }

}
