import { Observable, Subject, Subscription } from 'rxjs';
import { EnumStepType } from '../enums/enum-step-type';
import { EnumEventType } from '../enums/enum-event-type';
import { IStepBase } from '../interfaces/istep-base';
import { EventData } from './event-data';
import { EnumValueState } from '../enums/enum-value-state';
import { IEvalNodeType } from '../types/eval-type';
import { AssistValues } from './assist-values';
import { ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { IStepComponent } from '../interfaces/icomponent-step';
import { AssistEval } from './assist-eval';
import { BranchData } from './step-branch';
import { AssistInjector } from '../services/assist-injector';
import { AssistStateService } from "../services/assist-state.service";
import { Scope } from './scope';
import { IStepPrompt } from '../interfaces/istep-prompt';

export class StepBase {

  public type: EnumStepType;
  public dbgLine : number;
  public dbgOffset : number;
  public index : number;
  public active: boolean;
  public state: EnumValueState;
  public processText : string = "";
  public viewIndex : number;
  public stepCount : number = 1;
  public scope : Scope = null;
  public promptConfig : any;
  
  protected created : boolean = false;

  protected assistValues: AssistValues;
  protected assistEval: AssistEval;
  protected assistStateService: AssistStateService;
  
  protected _stepEvent: Subject<EventData> = new Subject();
  public readonly stepEvent: Observable<EventData> = this._stepEvent.asObservable();

  protected _subscriptions: Subscription[] = [];

  private component: ComponentRef<IStepComponent> = null;  
  private container: ViewContainerRef = null;
  public parent : StepBase = null;

  constructor( 
    index: number, 
    config : IStepBase
  ) {

    this.type = config.type;

    if (config.hasOwnProperty("l")) {
      this.dbgLine = config.l;
    }
    if (config.hasOwnProperty("o")) {
      this.dbgOffset = config.o;
    }

    this.index = index;
    this.active = false;
 
    this.assistValues = AssistValues.getInstance();
    this.assistEval = AssistEval.getInstance();
    this.assistStateService = AssistInjector.get(AssistStateService);

  }

  initialise (topBranch : BranchData) {
    
  }

  async setPromptConfig (config : IStepPrompt) {

    this.promptConfig = {};

    if (config.hasOwnProperty("promptConfig")) {
      
      let configDataValue = await this.assistEval.evaluate(config.promptConfig);
  
      if (configDataValue.isValue && configDataValue.isObject) {
        this.promptConfig = configDataValue.getValue();
      }
    }
  }


  getEvalNodes () : IEvalNodeType[] {
    return [];
  }

  setEvalNodes (evalNodes: IEvalNodeType[]) {

  }

  destroy () {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async activate() : Promise<boolean> { 
    let result : boolean = false;
    
    if (!this.active) {
      this.active = true;
      result = true;

      if (!this.created) {
        this.created = true;
        this.assistValues.stepCreated(this);
      }
    }

    return result;
  }

  deactivate(): boolean {
    let result: boolean = false;

    if (this.active) {
      this.active = false;
      result = true;
    }

    return result;
  }

  sendEvent(type: EnumEventType, data: any) {
    this._stepEvent.next(new EventData(type, data));
  }

  async callStep() {
    
  }

  setComponent(container: ViewContainerRef, component: ComponentRef<IStepComponent> ) {
    this.container = container;
    this.component = component;
  }

  assistUpdateComponent() {
    if (this.component) {
      this.component.instance.onAssistUpdate();
    }
  }
  
  setParentStep(parentStep: StepBase) {
    this.parent = parentStep;
  }

  setScope(scope: Scope) {
    this.scope = scope;
  }

  getGroupOptions() : any {
    if (this.parent == null) {
      return {};
    } else {
      return this.parent.getGroupOptions();
    }
  }

  stepAdded() {
    this.stepCount++;
    if (this.parent) {
      this.parent.stepAdded();
    } else {
      this.assistValues.stepAdded();
    }
  }

  addParentStep() {
    if (this.parent) {
      this.parent.stepAdded();
    } else {
      this.assistValues.stepAdded();
    }
  }

  getStepValueName() {
    return "";
  }

  getAddIndex() : number {

    let baseIndex : number;

    if (this.component && this.container) {
      baseIndex = this.container.indexOf(this.component.hostView);
    } else {
      baseIndex = 0;
    }

    //console.log("getAddIndex for: '"+this.getStepValueName() + " baseIndex = " + baseIndex + " stepCount = " + this.stepCount);

    return baseIndex + this.stepCount;
  }


  getParentAddIndex() : number {

    if (this.parent) {
      return this.parent.getAddIndex();
    } else {
      return this.assistValues.getAddIndex();
    }
  }

}
