import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service'
import { AppService } from 'src/app/shared/services/app.service'
import { User } from 'src/app/shared/classes/user';
import { HeaderButton } from 'src/app/shared/classes/header-button';

@Component({
  selector: 'carina-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../home.scss']
})
export class HomeComponent implements OnInit {

  user : User;

  config : string[][];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private appService: AppService
  ) {
    this.user = null;
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.appService.setPageTitle("Homepage");
      if (this.authService.hasUser()) {
        this.initPage();
      }
    });
  }

  initPage() : void {
    this.user = this.authService.getUser();

    if (this.user.orgConfig?.home) {
      this.config = this.user.orgConfig.home;
    } else {
      this.config = [["location"], ["util", "report", "lib"]];
    }

    let buttons: HeaderButton[] = [];

    if (this.user.hasPermission('MTAL')) {
      buttons.push(new HeaderButton("hdrbtnConfigure", "configure", "Configure Carina", false, "/configure"));
    }

    if (this.user.hasPermission('WFAS')) {
      buttons.push(new HeaderButton("hdrbtnAssist", "assist", "Workflow Assist", false, "/assist"));
    }

    this.appService.setHeaderButtons(buttons);

  }

  onReport(strReportId : string) { 
    this.authService.location('reports.php?Report='+strReportId);
  }

  onLocation(strLocationId : string) { 
    this.authService.location('stats.php?Location=' + strLocationId);
  }  

  onUtility(strUrl : string) { 
    this.authService.location(strUrl);
  }  

  onLibrary(strLibraryId : string) { 
    this.authService.location('library.php?Library=' + strLibraryId);
  } 
    
  onMaintenance(strUrl : string) { 
    this.authService.location('maint_object_list.php?Mode='+strUrl);
  }   
}
