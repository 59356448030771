<div *ngIf="stepData.active && level == 1" class="assistStep">
  <div #scrollTarget class="stepHeading">
    <p>{{stepData.text}}</p>
  </div>
</div> 
<div *ngIf="stepData.active && level == 2" class="assistStep">
  <div class="stepIndent"></div>
  <div #scrollTarget class="stepSubHeading">
    <p>{{stepData.text}}</p>
  </div>
</div>