import { EnumValueType } from "../enums/enum-value-type";
import { IDataValue } from "../interfaces/idata-value";
import { ValueBase } from "./value-base";
import { EvalNode } from "./eval-node";

export class ValueProc extends ValueBase {
  eval : EvalNode;

  constructor(config: IDataValue) {
    super(config);

    if (config.hasOwnProperty("eval")) {
      this.eval = config.eval;
    }
  }  

  reset () {
    super.reset();

  }

  cast(newType : EnumValueType) : boolean | string | number {

    return false;
  }   

  getValue(): string {
    return "[proc:" + this.eval.name +"]";
  }

  setValue(value: string): void {
    //this._value.next(this.value);
  }

  assignValue (newValue : ValueBase) {
    super.assignValue(newValue);
  }  

  getEval() : EvalNode{
    return this.eval;
  }
}
