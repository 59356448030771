import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { YesNoPipe } from "src/app/shared/pipes/yes-no.pipe";
import { NbspOnEmptyPipe } from "src/app/shared/pipes/nbsp-on-empty.pipe";
//import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';

import { AssistComponent } from './pages/assist/assist.component';
import { AssistDataViewComponent } from './components/views/assist-value-view.component';
import { AssistActionViewComponent } from './components/views/assist-action-view.component';
import { AssistStepViewComponent } from './components/views/assist-step-view.component';
import { StepHostDirective } from './directives/step-host.directive';
import { ActionItemHostDirective } from './directives/action-item-host.directive';
import { ValueHostDirective } from './directives/value-host.directive';
import { PromptYNComponent } from './components/prompts/prompt-yn.component';
import { PromptNextComponent } from './components/prompts/prompt-next.component';
import { PromptEntryComponent } from './components/prompts/prompt-entry.component';
import { PromptMultiComponent } from './components/prompts/prompt-multi.component';
import { PromptButtonGroupComponent } from './components/prompts/prompt-buttonGroup.component';
import { PromptFlagGroupComponent } from './components/prompts/prompt-flagGroup.component';
import { StepSectionComponent } from './components/steps/step-section.component';
import { StepTextComponent } from './components/steps/step-text.component';
import { MaterialModule } from 'src/app/material/material.module';
import { ActionEntryComponent } from './components/actions/action-entry.component';
import { ActionElementComponent } from './components/actions/action-element.component';
import { ValueBooleanComponent } from './components/values/value-boolean.component';
import { ValueNumberComponent } from './components/values/value-number.component';
import { ValueStringComponent } from './components/values/value-string.component';
import { ActionItemComponent } from './components/actions/action-item.component';
import { StepEvalComponent } from './components/steps/step-eval.component';
import { ValueTableComponent } from './components/values/value-table.component';
import { StepBaseComponent } from './components/steps/step-base.component';
import { ValueBaseComponent } from './components/values/value-base.component';
import { setAssistInjector } from "./services/assist-injector"
import { CarinaMsgBox } from './components/dialogs/dialog-msg-box';
import { ValuePanelComponent } from './components/values/value-panel.component';
import { ValueChipComponent } from './components/values/value-chip.component';
import { ValueImageComponent } from './components/values/value-image.component';
import { ControlResizeComponent } from './components/controls/control-resize.component';

@NgModule({
  declarations: [
    AssistComponent,
    AssistDataViewComponent,
    AssistActionViewComponent,
    AssistStepViewComponent,
    StepHostDirective,
    ActionItemHostDirective,
    ValueHostDirective,
    PromptYNComponent,
    PromptNextComponent,
    PromptEntryComponent,
    PromptMultiComponent,
    PromptButtonGroupComponent,
    PromptFlagGroupComponent,
    StepSectionComponent,
    StepTextComponent,
    ActionEntryComponent,
    ActionElementComponent,
    ValueBooleanComponent,
    ValueNumberComponent,
    ValueStringComponent,
    ActionItemComponent,
    StepEvalComponent,
    ValueTableComponent,
    YesNoPipe,
    NbspOnEmptyPipe,
    StepBaseComponent,
    ValueBaseComponent,
    CarinaMsgBox,
    ValuePanelComponent,
    ValueChipComponent,
    ValueImageComponent,
    ControlResizeComponent

  ],
  exports: [
    AssistComponent,
    AssistDataViewComponent,
    AssistActionViewComponent,
    AssistStepViewComponent,
    StepHostDirective,
    ActionItemHostDirective,
    ValueHostDirective,
    PromptYNComponent,
    PromptNextComponent,
    PromptEntryComponent,
    PromptMultiComponent, 
    PromptButtonGroupComponent, 
    PromptFlagGroupComponent,
    StepSectionComponent,
    ActionEntryComponent,
    ActionElementComponent,
    ValueBooleanComponent,
    ValueNumberComponent,
    ValueStringComponent,
    StepEvalComponent,
    ValueTableComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    MaterialModule
  ],
  // entryComponents: [
  //   MatButtonToggle, MatButtonToggleGroup
  // ],
  // bootstrap: [MatButtonToggle, MatButtonToggleGroup]
})
export class AssistModule {
  constructor(injector: Injector) {
    setAssistInjector(injector);
  }
}