<mat-toolbar>
  <mat-toolbar-row *ngIf="!bDemo" class="headerBar">
    <img height="40px" [src]="strBannerSrc" alt="Customer Banner">
    <span class="spacer">
      <div class="elements"> 
        <div *ngIf="user" class="fabs">
          <!-- <button *ngIf="hasMaint()" color="basic" class="carina-mini-fab" mat-mini-fab (click)="onSettings()">
            <mat-icon>settings</mat-icon>
          </button>
          <button color="basic" class="carina-mini-fab" mat-mini-fab (click)="onHome()">
            <mat-icon>home</mat-icon>
          </button> -->
          <button color="primary" class="carina-mini-fab" mat-mini-fab (click)="onUser()" title="Sign-off">
            <mat-icon>logout</mat-icon>
          </button>
        </div>
        <span>{{strFullName}}</span>
      </div>
    </span>
    <img height="50px" src="assets/images/Carina-Purple-RGB.svg" alt="Carina">
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="bDemo && user" class="headerBar">
    <img height="50px" src="assets/images/Carina-Purple-RGB.svg" alt="Carina">
    <span class="spacer">
      <div class="elements" style="flex-direction:row;justify-content:flex-end;">
        <span>{{strFullName}}</span>
        <div *ngIf="user" class="fabs" style="justify-content:flex-end;width:36px;margin-right:6px">
          <button color="primary" class="carina-mini-fab" mat-mini-fab (click)="onUser()" title="Sign-off">
            <mat-icon>logout</mat-icon>
          </button>
        </div>
      </div>
    </span>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="strPageTitle" class="pageBar">
    <h1>{{strPageTitle}}</h1>
    <span class="spacer">
    </span>
    <img *ngIf="bInProgress" src="assets/images/inprogress_32x32.gif" title="Loading data" />
    <button *ngFor="let btn of arrHeaderButtons; let i=index" [id]="btn.id" class="carina-header-button" mat-icon-button [title]="btn.title" (click)="onHeaderButton(i)">
    </button>
  </mat-toolbar-row>
</mat-toolbar>

