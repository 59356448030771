import { Component, OnInit, Input } from '@angular/core';
import { ValueBaseComponent } from './value-base.component';

export interface IImageEntry {
  name : string;
  src : string;
  style : any;
}

@Component({
  selector: 'carina-value-image',
  templateUrl: './value-image.component.html',
  styles: [
  ]
})
export class ValueImageComponent extends ValueBaseComponent implements OnInit {

  public defaultWidth: string = "";
  public entries : IImageEntry [];

  constructor() { 
    super();
  }

  ngOnInit(): void {

    super.ngOnInit();

    if (this.stepData.viewConfig.hasOwnProperty("width")) {
      this.defaultWidth = this.stepData.viewConfig.width;
    } else {
      this.defaultWidth = "40px";
    }

  }

  findDeclaredValue() {

    let result = super.findDeclaredValue();

    if (result && this.dataValue) {

      result = true;

      this._subscriptions.push (
        this.dataValue.onChangeValue.subscribe(value => {
          this.handleValue();
        })
      );
    }
    
    return result;
  }

  handleValue () {

    let strValue : string = "";

    this.entries = [];

    if (this.dataValue.isValue) {
      if (this.dataValue.isArray) {
        let arrValue : any[] = this.dataValue.getValue() as unknown as any[];

        arrValue.forEach(element => {

          if (element.hasOwnProperty("name") && element.hasOwnProperty("src")) {

            let entry : IImageEntry = {
              name: element.name, 
              src: element.src,
              style: {width:this.defaultWidth}
            };
       
            if (element.hasOwnProperty("width")) {
              entry.style.width = element.width;
            }

            this.entries.push(entry);

          }    
        });      
      }
    }    
  }
}
