import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignOnComponent } from './modules/sign-on/pages/sign-on/sign-on.component'
import { AssistComponent } from './modules/assist/pages/assist/assist.component'
import { PdfComponent } from './modules/pdf/pages/pdf/pdf.component'
import { HomeComponent } from './modules/home/pages/home/home.component'
import { ConfigureComponent } from './modules/home/pages/configure/configure.component'
import { AuthGuard } from './shared/auth/auth.guard'
import { OrgGuard } from './shared/auth/org.guard'
import { OfflineComponent } from './modules/sign-on/pages/offline/offline.component';

const routes: Routes = [

  {

    path : ':orgUrl',
    children : [
      { path: 'offline', component: OfflineComponent},
      { path: 'signon', component: SignOnComponent},
      { path: 'home', component: HomeComponent, canActivate:[AuthGuard]},
      { path: 'configure', component: ConfigureComponent, canActivate:[AuthGuard]},
      { path: 'assist', component: AssistComponent, canActivate: [AuthGuard] },
      { path: 'pdf', component: PdfComponent, canActivate: [AuthGuard] },
      //{ path: 'stats', loadChildren: () => import('./modules/stat-entry/stat-entry.module').then(m => m.StatEntryModule), canActivate: [AuthGuard] },
      { path: '', redirectTo: '/signon', pathMatch: 'full' },
      { path: '**', redirectTo: '/home', pathMatch: 'full' }
    ],
    canActivate:[OrgGuard],
    data : {ngRoutes:["configure"]}
  },

   { path: '', redirectTo: '/signon', pathMatch: 'full'},
   { path: '**', redirectTo: '/signon', pathMatch: 'full' }      
];
 
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
