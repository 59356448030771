export enum EnumValueItemType {
  ValueItem_boolean = 0,
  ValueItem_string = 1,
  ValueItem_number = 2,
  ValueItem_object = 3,
  ValueItem_table = 4,
  ValueItem_panel = 5,
  ValueItem_chip = 6,
  ValueItem_image = 7
}
