import { Observable, Subject, Subscription } from "rxjs";
import { EnumActionElementType } from "../enums/enum-action-element-type.";
import { AssistValues } from "./assist-values";
import { Scope } from "./scope";
import { ValueBase } from "./value-base";

export class ActionElement {

  type : EnumActionElementType;
  text : string;
  configText : string;
  valueName : string;
  scope : Scope;
  dataValue : ValueBase = null;
  isHighlighted : boolean = false;
  assistValues: AssistValues;

  protected _highlight: Subject<boolean> = new Subject();
  public readonly onChangeHighlight: Observable<boolean> = this._highlight.asObservable();

  private _subscriptions : Subscription [] = [];

  constructor(scope : Scope, type: EnumActionElementType, configText: string, valueName: string = "") {
    this.type = type;
    this.configText = configText; 
    this.valueName = valueName;
    this.scope = scope;

    if (this.type === EnumActionElementType.ActionElement_fixedText) {
      this.text = this.configText;
    }

    this.assistValues = AssistValues.getInstance();

    if (valueName) {

      if (!this.findDeclaredValue()) {

        let subscription : Subscription = 
          this.assistValues.onValueDeclared.subscribe(valueName => {
            if (valueName == this.valueName) {
              this.findDeclaredValue();
              subscription.unsubscribe();
            }
          });
  
        this._subscriptions.push(subscription);
      }
    }

    this.handleValueChange();
  }

  destroy () {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  findDeclaredValue() : boolean {

    let result = false;

    this.dataValue = this.scope.findValue(this.valueName);

    if (this.dataValue !== null) {

      result = true;

      this._subscriptions.push (
        this.dataValue.onChangeValue.subscribe((value) => {
          this.handleValueChange();
        })
      );

      this._subscriptions.push (
        this.dataValue.onChangeHighlight.subscribe((highlight) => {
          this.isHighlighted = highlight;
          this._highlight.next(highlight);
        })
      );
    }

    return result;
  }


  handleValueChange() {
    this.text = this.assistValues.formatText(this.scope, this.configText);
  }

}