import { EnumValueSource } from "../enums/enum-value-source";
import { EnumValueType } from "../enums/enum-value-type";
import { EvalNodeType, EvalValueType, IEvalNodeType } from "../types/eval-type";
import { AssistValues } from "./assist-values";
import { EvalNode } from "./eval-node";
import { ValueBase } from "./value-base";
import { AssistInjector } from '../services/assist-injector';
import { AssistStateService } from "../services/assist-state.service";
import { ValueFactory } from "./value-factory";
import { ValueProc } from "./value-proc";
import { EnumLogType } from "../enums/enum-log-type";

export class AssistEval {

  private static instance : AssistEval;
  private assistValues : AssistValues;
  private assistStateService : AssistStateService;

  private constructor() {
    this.assistValues = AssistValues.getInstance();
    this.assistStateService = AssistInjector.get(AssistStateService);
  };

  public static getInstance(): AssistEval {
    if (!AssistEval.instance) {
      AssistEval.instance = new AssistEval();
    }  

    return AssistEval.instance;
  }  

  async evaluate (evalNodeValue: EvalNodeType, args : any[] = []) : Promise<ValueBase>  {

    let result : ValueBase;

    if (!this.assistStateService.isUserCancelled) {

      if (typeof evalNodeValue === "object" && EvalNode.isEvalNode(evalNodeValue)) {

        let evalNode : EvalNode = evalNodeValue as EvalNode;

        switch (evalNode.source) {
          case EnumValueSource.ValueSource_value:
            if (evalNode.type == EnumValueType.Value_proc) {
              evalNode = evalNode.scope.getDataValue(evalNode.name).getEval();

              result = await this.callProcedure(evalNode, args);
            } else {
              result = evalNode.scope.getDataValue(evalNode.name);
            }
          break;  
          case EnumValueSource.ValueSource_proc_call:
            result = await this.makeProcedureCall(evalNode);
          break;  
          case EnumValueSource.ValueSource_procedure:
            result = await this.callProcedure(evalNode, args);
          break;  
          case EnumValueSource.ValueSource_function:
            if (typeof this[evalNode.name] === "function") {
              result = await this[evalNode.name].call(this, evalNode);
            } else {
              result = ValueFactory.createErrorValue(evalNode.type, "unknown function call: '" + evalNode.name + "'", evalNode);
            }
          break;   
        }

      } else {
        result = ValueFactory.createFromLiteral(evalNodeValue);
      }
    } else {
      result = ValueFactory.createErrorValue(EnumValueType.Value_object, "Script cancelled", null);
    }  

    return result;
  }

  async makeProcedureCall(evalNode: EvalNode) : Promise<ValueBase>  {
    let result : ValueBase;
    let args : any[] = []
    let operand : ValueBase;
    let hasError : boolean = false;
    
    let procName = evalNode.name;
    let paramIndex : number = 0;
    let callEval : EvalNode = evalNode.scope.getDataValue(evalNode.name).getEval();

    while (!hasError && paramIndex < evalNode.params.length) {

      let param = evalNode.params[paramIndex];

      operand = await this.evaluate(param);

      if (operand.isValue) {
        args.push(operand.getValue());
      } else {
        hasError = true;
      }
     
      paramIndex++;
    }

    if (!hasError) {
      result = await this.callProcedure(callEval, args);
    } else {
      return ValueFactory.createErrorValue(evalNode.type, "Could not make call to procedure " + procName, evalNode);
    }

    return result;
  }

  async callProcedure(evalNode: EvalNode, args : any[] = []) : Promise<ValueBase>  {
    let result : ValueBase;
    let procName = "anon";

    if (evalNode.name) {
      procName = evalNode.name;
    }

    result = await evalNode.branch.callBranch(procName, evalNode.args, args).catch(rejectValue => {
      return ValueFactory.createErrorValue(evalNode.type, "Call to procedure " + procName + " cancelled", evalNode);
    });

    return result;
  }

  gatValueTypeNames(type : EnumValueType) {
    
    let name : string = "";
    switch (type) {
      case EnumValueType.Value_boolean:
        name = "boolean";
      break;  
      case EnumValueType.Value_string:
        name = "string";
      break;  
      case EnumValueType.Value_number:
        name = "number";
      break;  
      case EnumValueType.Value_object:
        name = "object";
      break;  
    }
    return name;

  }

  castValue(dataValue : ValueBase, newType : EnumValueType) : ValueBase {

    let result : ValueBase = null;

    if (dataValue.isType(newType)) {
      result = dataValue;
    } else {
      if (dataValue.isValue) {
        if (dataValue.canCast(newType)) {
          result = ValueFactory.createFromLiteral(dataValue.cast(newType));
        } else {
          result = ValueFactory.createErrorValue(newType, "Cannot cast value '"+dataValue.name+"' from " + this.gatValueTypeNames(dataValue.type) + " to " + this.gatValueTypeNames(newType), null);
        }
      } else {
        result = ValueFactory.createResultValue(newType, null, dataValue);
      }
    }

    return result;
  }  

  deepCopy(value : boolean | number | string | object, extractProps? : string[] ) {

    let newValue;
    let valueType = typeof value;
    
    if (valueType === "object") {
      if (Array.isArray(value)) {
        newValue = [];

        value.forEach((entry, index) => newValue[index] = this.deepCopy(entry));

      } else if (value === null) {
        newValue = null;
      } else if (value !== undefined) {

        newValue = {};

        let keys = Object.keys(value);

        if (extractProps != undefined) {

          let extractKeys = extractProps.filter(propName => {
            return (typeof propName === "string") && (keys.indexOf(propName) >= 0); 
          });

          keys = extractKeys;

        }

        keys.forEach(propName => newValue[propName] = this.deepCopy(value[propName]));  

      }
    } else {
      newValue = value;
    }

    return newValue;
  }

  unescapeSlash (sourceString : string) : string {

    const regex = /\\\\/g;

    let result  = sourceString.replace(regex, "\\");
    console.log("sourceString: "+ sourceString);
    console.log("result: "+ result);


    return result;
  }

  async returnFunc (evalNode : EvalNode) : Promise<ValueBase> {

    return await this.evaluate(evalNode.params[0]);

  }

  async getParamsFunc (evalNode : EvalNode) : Promise<ValueBase> {
 
    return ValueFactory.createFromLiteral(this.assistValues.scriptParams);

  }

  async setFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase = await this.evaluate(evalNode.params[0]);

    if (!result.isLiteral) {

      let operand = await this.evaluate(evalNode.params[1]);

      if (!result.isReference) {
        operand = this.castValue(operand, result.type);
      }

      if (operand.isValue) {
        result.setValue(operand.getValue());      
      } else {

        if (EvalNode.isEvalNode(evalNode.params[1])) {

          let errorNode = evalNode.params[1] as EvalNode; 

          if (errorNode && errorNode.dbgLine !== undefined && errorNode.dbgOffset !== undefined) {
            operand.error = operand.error + " (line: " + errorNode.dbgLine + " offset: " + errorNode.dbgOffset + ")";
          }
        } 

        result.setError(operand.error);
      }        
    } else {
      if (!result.isError) {
        result = ValueFactory.createErrorValue(evalNode.type, "Cannot set the value of a literal value: "+ result.name, evalNode);
      }
    }

    return result;
  };  

  async fetchFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let params = {};
    let valueName = "";
    let result : ValueBase;

    let nameDataValue = await this.evaluate(evalNode.params[0]);

    if (nameDataValue.isValue && nameDataValue.isString) {
      valueName = nameDataValue.getValue() as string;
    } else {
      hasError = true;
      result = ValueFactory.createErrorValue(evalNode.type, "fetch value name must be a string", evalNode);      
    }

    if (!hasError) {
      if (evalNode.params.length > 1) {
        let valuesDataValue : ValueBase = await this.evaluate(evalNode.params[1]);
        if (valuesDataValue.isObject) {
          params = this.deepCopy(valuesDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "fetch values must be an object", evalNode);
        }
      }
    }

    if (!hasError) {
      if (evalNode.params.length > 2) {
        let paramsDataValue : ValueBase = await this.evaluate(evalNode.params[2]);
        if (paramsDataValue.isObject) {
          params = Object.assign(params, paramsDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "fetch parameters must be an object", evalNode);
        }
      }
    }

    if (!hasError) {

      result = await this.assistValues.fetchValue(valueName, params);

    }
    
    return result;

  };  
  
  async pdfFetchFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let params = {};
    let valueName = "";
    let result : ValueBase;

    let nameDataValue = await this.evaluate(evalNode.params[0]);

    if (nameDataValue.isValue && nameDataValue.isString) {
      valueName = nameDataValue.getValue() as string;
    } else {
      hasError = true;
      result = ValueFactory.createErrorValue(evalNode.type, "pdfFetch url must be a string", evalNode);      
    }

    if (!hasError) {
      if (evalNode.params.length > 1) {
        let valuesDataValue : ValueBase = await this.evaluate(evalNode.params[1]);
        if (valuesDataValue.isObject) {
          params = this.deepCopy(valuesDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "pdfFetch values must be an object", evalNode);
        }
      }
    }

    if (!hasError) {
      if (evalNode.params.length > 2) {
        let paramsDataValue : ValueBase = await this.evaluate(evalNode.params[2]);
        if (paramsDataValue.isObject) {
          params = Object.assign(params, paramsDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "pdfFetch parameters must be an object", evalNode);
        }
      }
    }

    if (!hasError) {

      let dataValue : ValueBase;

      this.assistValues.pdfStartDownload(valueName);

      result = await this.assistValues.pdfDownload(valueName, params);

      if (result.isError) {
        result = ValueFactory.createFromLiteral(false);
      } else {
        dataValue = await this.assistValues.waitForPdfAction();
      }

    }
    
    return result;

  };  

  async pdfOpenFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let params = {};
    let valueName = "";
    let result : ValueBase;

    let nameDataValue = await this.evaluate(evalNode.params[0]);

    if (nameDataValue.isValue && nameDataValue.isString) {
      valueName = nameDataValue.getValue() as string;
    } else {
      hasError = true;
      result = ValueFactory.createErrorValue(evalNode.type, "pdfOpen value name must be a string", evalNode);      
    }

    if (!hasError) {
      if (evalNode.params.length > 1) {
        let valuesDataValue : ValueBase = await this.evaluate(evalNode.params[1]);
        if (valuesDataValue.isObject) {
          params = this.deepCopy(valuesDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "pdfOpen values must be an object", evalNode);
        }
      }
    }

    if (!hasError) {
      if (evalNode.params.length > 2) {
        let paramsDataValue : ValueBase = await this.evaluate(evalNode.params[2]);
        if (paramsDataValue.isObject) {
          params = Object.assign(params, paramsDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "pdfOpen parameters must be an object", evalNode);
        }
      }
    }

    if (!hasError) {

      let dataValue : ValueBase;

      this.assistValues.pdfStartDownload(valueName);
      result = await this.assistValues.fetchValue(valueName, params);

      if (result.isError) {
        result = ValueFactory.createFromLiteral(false);
      } else {
        dataValue = await this.assistValues.waitForPdfAction();
      }

    }
    
    return result;

  };  

  async pdfFindFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let findString = "";
    let params = {};
    let result : ValueBase;

    let nameDataValue = await this.evaluate(evalNode.params[0]);

    if (nameDataValue.isValue && nameDataValue.isString) {
      findString = nameDataValue.getValue() as string;
    } else {
      hasError = true;
      result = ValueFactory.createErrorValue(evalNode.type, "pdfFind value name must be a string", evalNode);      
    }

    if (!hasError) {
      if (evalNode.params.length > 1) {
        let valuesDataValue : ValueBase = await this.evaluate(evalNode.params[1]);
        if (valuesDataValue.isObject) {
          params = this.deepCopy(valuesDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "pdfFind options must be an object", evalNode);
        }
      }
    }

    if (!hasError) {

      result = await this.assistValues.pdfAction("find", findString, params);

    }
    
    return result;

  };      

  async pdfInfoFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let params = {};
    let result : ValueBase;

    if (evalNode.params.length > 0) {
      let valuesDataValue : ValueBase = await this.evaluate(evalNode.params[0]);
      if (valuesDataValue.isObject) {
        params = this.deepCopy(valuesDataValue.getValue());
      } else {
        hasError = true;
        result = ValueFactory.createErrorValue(evalNode.type, "pdfInfo options must be an object", evalNode);
      }
    }

    result = await this.assistValues.pdfAction("info", "", params);
    
    return result;

  };      

  async taskStopFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let result : ValueBase;

    if (!hasError) {

      result = await this.assistValues.taskAction("stop", {});

    }
    
    return result;

  };      

  async readScriptStateFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let stateValue : ValueBase;

    let stateNode : EvalNode = evalNode.params[0] as EvalNode;

    if (stateNode.source != EnumValueSource.ValueSource_value) {
      result = ValueFactory.createErrorValue(evalNode.type, "script state parameeter must be a variable", evalNode);
    } else {
      stateValue = evalNode.scope.findValue(stateNode.name);

      let stateResult = await this.assistValues.scriptState("read");

      if (!stateResult.isError) {

        if (stateResult.isBoolean) {
          result = stateResult;
        } else {
          stateValue.setValue(stateResult.getValue());
          result = ValueFactory.createFromLiteral(true)
        }
      } else {
        result = stateResult;
      }
    }
    
    return result;

  };

  async writeScriptStateFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let stateValue : ValueBase;

    stateValue = await this.evaluate(evalNode.params[0]);

    if (stateValue.isObject) {

      result = await this.assistValues.scriptState("write", stateValue);

    } else {
      result = ValueFactory.createErrorValue(evalNode.type, "pdfInfo options must be an object", evalNode);
    }
    
    return result;

  };



  async runMacroFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let params = {};
    let macroName = "";
    let result : ValueBase;

    let nameDataValue = await this.evaluate(evalNode.params[0]);

    if (nameDataValue.isValue && nameDataValue.isString) {
      macroName = nameDataValue.getValue() as string;
    } else {
      hasError = true;
      result = ValueFactory.createErrorValue(evalNode.type, "macro name must be a string", evalNode);      
    }

    if (!hasError) {
      if (evalNode.params.length > 1) {
        let valuesDataValue : ValueBase = await this.evaluate(evalNode.params[1]);
        if (valuesDataValue.isObject) {
          params = this.deepCopy(valuesDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "macro values must be an object", evalNode);
        }
      }
    }

    if (!hasError) {
      if (evalNode.params.length > 2) {
        let paramsDataValue : ValueBase = await this.evaluate(evalNode.params[2]);
        if (paramsDataValue.isObject) {
          params = Object.assign(params, paramsDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "macro parameters must be an object", evalNode);
        }
      }
    }

    if (!hasError) {

      result = await this.assistValues.runMacro(macroName, params);

    }
    
    return result;

  };

  async metaDataCheckFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let params = {};
    let metadata : any;
    let metaDataName = "";
    let result : ValueBase;

    let nameDataValue = await this.evaluate(evalNode.params[0]);

    if (nameDataValue.isValue && nameDataValue.isString) {
      metaDataName = nameDataValue.getValue() as string;
    } else {
      hasError = true;
      result = ValueFactory.createErrorValue(evalNode.type, "metadata name must be a string", evalNode);      
    }

    if (!hasError) {
      if (evalNode.params.length > 1) {
        let valuesDataValue : ValueBase = await this.evaluate(evalNode.params[1]);
        if (valuesDataValue.isObject) {
          params = this.deepCopy(valuesDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "macro values must be an object", evalNode);
        }
      }
    }

    if (!hasError) {
      if (evalNode.params.length > 2) {
        let metaDataValue : ValueBase = await this.evaluate(evalNode.params[2]);
        if (metaDataValue.isObject) {
          metadata = metaDataValue.getValue();
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "metadata must be an object", evalNode);
        }
      }
    }

    if (!hasError) {

      result = await this.assistValues.metaDataCheck(metaDataName, params, metadata);

    }
    
    return result;

  };

  async writeLogFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let params = {};
    let logTypeCode = "";
    let result : ValueBase;

    let nameDataValue = await this.evaluate(evalNode.params[0]);

    if (nameDataValue.isValue && nameDataValue.isString) {
      logTypeCode = nameDataValue.getValue() as string;
    } else {
      hasError = true;
      result = ValueFactory.createErrorValue(evalNode.type, "log type code must be a string", evalNode);      
    }

    if (!hasError) {
      if (evalNode.params.length > 1) {
        let valuesDataValue : ValueBase = await this.evaluate(evalNode.params[1]);
        if (valuesDataValue.isObject) {
          params = this.deepCopy(valuesDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "log value must be an object", evalNode);
        }
      }
    }

    if (!hasError) {

      result = await this.assistValues.writeLog(logTypeCode, params);

    }
    
    return result;

  };

  async createDocumentFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let sourceName : string = "";
    let docData : any;
    let targetName : string = "";
    let libName : string = "";
    let groupName : string = "";
    let result : ValueBase;

    let sourceNameDataValue : ValueBase = await this.evaluate(evalNode.params[0]);

    if (sourceNameDataValue.isValue && sourceNameDataValue.isString) {
      sourceName = sourceNameDataValue.getValue() as string;
    } else {
      hasError = true;
      result = ValueFactory.createErrorValue(evalNode.type, "document source name must be a string", evalNode);      
    }

    if (!hasError) {
      if (evalNode.params.length > 1) {
        let docDataValue : ValueBase = await this.evaluate(evalNode.params[1]);
        if (docDataValue.isObject) {
          docData = this.deepCopy(docDataValue.getValue());
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "document data must be an object", evalNode);
        }
      }
    }

    if (!hasError) {
      if (evalNode.params.length > 2) {
        let targetNameDataValue  : ValueBase = await this.evaluate(evalNode.params[2]);
        if (targetNameDataValue.isValue && targetNameDataValue.isString) {
          targetName = targetNameDataValue.getValue() as string;
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "document target name must be a string", evalNode);      
        }
      }
    }    

    if (!hasError) {
      if (evalNode.params.length > 3) {
        let libNameDataValue : ValueBase = await this.evaluate(evalNode.params[3]);
        if (libNameDataValue.isValue && libNameDataValue.isString) {
          libName = libNameDataValue.getValue() as string;
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "document library name must be a string", evalNode);
        }
      }
    }

    if (!hasError) {
      if (evalNode.params.length > 4) {
        let groupNameDataValue : ValueBase = await this.evaluate(evalNode.params[4]);
        if (groupNameDataValue.isValue && groupNameDataValue.isString) {
          groupName = groupNameDataValue.getValue() as string;
        } else {
          hasError = true;
          result = ValueFactory.createErrorValue(evalNode.type, "document group name must be a string", evalNode);
        }
      }
    }

    if (!hasError) {

      result = await this.assistValues.createDocument(sourceName, docData, targetName, libName, groupName);

    }
    
    return result;

  };

  async activateTabFunc (evalNode : EvalNode) : Promise<ValueBase> {
  
    if (evalNode.params.length == 0) {
      this.assistValues.activateTab();  
    } else {

      let hasError = false;
      let params = {};
      let valueName = "";
      let result : ValueBase;

      let nameDataValue = await this.evaluate(evalNode.params[0]);

      if (nameDataValue.isValue && nameDataValue.isString) {
        valueName = nameDataValue.getValue() as string;
      } else {
        hasError = true;
        result = ValueFactory.createErrorValue(evalNode.type, "activate tab type name must be a string", evalNode);      
      }
  
      if (!hasError) {
        if (evalNode.params.length > 1) {
          let valuesDataValue : ValueBase = await this.evaluate(evalNode.params[1]);
          if (valuesDataValue.isObject) {
            params = this.deepCopy(valuesDataValue.getValue());
          } else {
            hasError = true;
            result = ValueFactory.createErrorValue(evalNode.type, "activate tab values must be an object", evalNode);
          }
        }
      }
  
      if (!hasError) {
        if (evalNode.params.length > 2) {
          let paramsDataValue : ValueBase = await this.evaluate(evalNode.params[2]);
          if (paramsDataValue.isObject) {
            params = Object.assign(params, paramsDataValue.getValue());
          } else {
            hasError = true;
            result = ValueFactory.createErrorValue(evalNode.type, "activate tab parameters must be an object", evalNode);
          }
        }
      }

      
    }

  
    return ValueFactory.createFromLiteral(true);
  };

  async makeArrayFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let hasError : boolean;
    let makeArrayValue = [];
    let operand : ValueBase;

    let paramIndex = 0;

    while (!hasError && paramIndex < evalNode.params.length) {
      let param = evalNode.params[paramIndex];

      operand = await this.evaluate(param);

      if (operand.isValue) {
        makeArrayValue.push(operand.getValue() as any);
      } else {
        hasError = true;
      }

      paramIndex++;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_object, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(makeArrayValue);
    }

    return result;
  };  

  async makeObjectFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let hasError : boolean = false;
    let makeObjectValue = {};
    let operand : ValueBase;
    let index : number = 0;

    while (!hasError && index < evalNode.params.length) {

      let propName : string;

      operand = await this.evaluate(evalNode.params[index]);

      if (operand.isValue) {
        if (operand.isString) {
          propName = operand.getValue() as string;

          index++;
          
          if (index < evalNode.params.length) {
            let paramNode : IEvalNodeType = evalNode.params[index];
            index++;

            if (EvalNode.isProc(paramNode)) {
              makeObjectValue[propName] = paramNode;
            } else {
              operand = await this.evaluate(paramNode);
              if (operand.isValue) {
                makeObjectValue[propName] = operand.getValue() as any;
              } else {
                hasError = true;
              }
            }
          } else {
            hasError = true;
            operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Wrong number of parameters to make object " + evalNode.name, evalNode);
          }
        } else {
          hasError = true;
          operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Property name is not a sting making object " + evalNode.name, evalNode);
        }
      } else {
        hasError = true;
      }
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_object, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(makeObjectValue);
    }

    return result;
  };  

  async makeReferenceFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let nameDataValue : ValueBase; 
    let dataValue : ValueBase; 
    let hasError : boolean = false;
    let path : string[] = [];
    let operand : ValueBase;
    let error : string = "";

    nameDataValue = await this.evaluate(evalNode.params[0]);

    if (nameDataValue.isValue && nameDataValue.isString) {

      let valueName = nameDataValue.getValue() as string

      dataValue = evalNode.scope.findValue(valueName);
      if (dataValue !== null && dataValue.isValue) {
        if (dataValue.isObject) {

          let paramIndex : number = 1;

          while (!hasError && paramIndex < evalNode.params.length) {
            
            let param = evalNode.params[paramIndex];

            operand = await this.evaluate(param);
      
            if (operand.isValue) {
              path.push(operand.getValue() as string);
            } else {
              hasError = true;
            }

            paramIndex++;
          }

          if (!hasError) {

            result = ValueFactory.createValueReference(dataValue, path);

          } else {
            hasError = true;
            error = "Cannot get property reference of value '" + dataValue.name + "'";
          }
        } else {
          hasError = true;
          error = "Cannot get property of non-object '" + dataValue.name + "'"; 
        }
      } else {
        hasError = true;
        error = "Cannot get value for reference '" + valueName + "'"; 
      }
    } else {
      hasError = true;
      error = "Cannot get name of reference value";     
    }

    if (hasError) {
      result = ValueFactory.createErrorValue(EnumValueType.Value_object, error, evalNode);
    }

    return result;
  };  

  async getBinary (evalNode : EvalNode) : Promise<ValueBase[]> {

    let result : ValueBase[] = [];

    result[0] = await this.evaluate(evalNode.params[0]);  // "p0"
    var paramType : EnumValueType = result[0].type;
    result[1] = this.castValue(await this.evaluate(evalNode.params[1]), paramType);  // "p1"

    return result;

  };

  async ifFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let inError = false;
    let result : ValueBase;
    let condition : ValueBase = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_boolean);  // p0;

    if (condition.isValue) {
      if (condition.getValue()) {
        result = await this.evaluate(evalNode.params[1]);  // p1
      } else {
        if (evalNode.params.length > 2) {
          result = await this.evaluate(evalNode.params[2]) // p2
        } else {
          result = ValueFactory.createErrorValue(EnumValueType.Value_string, "Missing parameter for if function", evalNode);
          inError = true;
        }
      }
    } else {
      result = condition;
    } 

    if (!inError && !result.isValue) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, result);
    }

    return result;
  };

  async equFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral(params[0].getValue() === params[1].getValue());
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async neqFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral(params[0].getValue() !== params[1].getValue());
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async gtFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral(params[0].getValue() > params[1].getValue());
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async gteFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral(params[0].getValue() >= params[1].getValue());
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async ltFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral(params[0].getValue() < params[1].getValue());
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async lteFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral(params[0].getValue() <= params[1].getValue());
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async addFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral((params[0].getValue() as number) + (params[1].getValue() as number));
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async subFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral((params[0].getValue() as number) - (params[1].getValue() as number));
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };
  
  async multFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral((params[0].getValue() as number) * (params[1].getValue() as number));
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };
  
  async divFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral((params[0].getValue() as number) / (params[1].getValue() as number));
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };
  
  async modFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral((params[0].getValue() as number) % (params[1].getValue() as number));
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };
  
  async sumFunc (evalNode : EvalNode) : Promise<ValueBase> {
    let result : ValueBase;
    let hasError : boolean = false;
    let operand  : ValueBase;
    let sumValue : number = 0;
    let paramIndex : number = 0;

    while (!hasError && paramIndex < evalNode.params.length) {

      let param = evalNode.params[paramIndex];

      operand = this.castValue(await this.evaluate(param), EnumValueType.Value_number);

      if (operand.isValue) {
        sumValue += operand.getValue() as number;
      } else {
        hasError = true;
      }

      paramIndex++;
    }
    
    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(sumValue);
    }

    return result;
  }

  async andFunc (evalNode : EvalNode) : Promise<ValueBase> {
    let result : ValueBase;
    let hasError : boolean = false;
    let operand  : ValueBase;
    let andValue : boolean = true;
    let paramIndex : number = 0;

    while (!hasError && andValue && paramIndex < evalNode.params.length) {

      let param = evalNode.params[paramIndex];

      operand = this.castValue(await this.evaluate(param), EnumValueType.Value_number);
  
      if (operand.isValue) {
        andValue = andValue && operand.getValue() as boolean;
      } else {
        hasError = true;
      }

      paramIndex++;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(andValue);
    }

    return result;
  }   

  async orFunc (evalNode : EvalNode) : Promise<ValueBase> {
    let result : ValueBase;
    let hasError : boolean = false;
    let operand  : ValueBase;
    let orValue : boolean = false;
    let paramIndex : number = 0;

    while (!hasError && !orValue && paramIndex < evalNode.params.length) {

      let param = evalNode.params[paramIndex];

      operand = this.castValue(await this.evaluate(param), EnumValueType.Value_number);

      if (operand.isValue) {
        orValue = operand.getValue() as boolean;
      } else {
        hasError = true;
      }  
      
      paramIndex++;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(orValue);
    }

    return result;
  }   

  async notFunc (evalNode : EvalNode) : Promise<ValueBase> {
    let result : ValueBase;
    
    let operand  : ValueBase = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_boolean);
    
    if (operand.isValue) {
      let notValue : boolean = operand.getValue() as boolean;
  
      result = ValueFactory.createFromLiteral(!notValue);
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    }
 
    return result;
  }

  async negateFunc (evalNode : EvalNode) : Promise<ValueBase> {
    let result : ValueBase;
    
    let operand  : ValueBase = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_number);
    
    if (operand.isValue) {
      let negateValue : number = operand.getValue() as number;
  
      result = ValueFactory.createFromLiteral(-1*negateValue);
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    }
 
    return result;
  }

  async expFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral((params[0].getValue() as number) ** (params[1].getValue() as number));
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async bitAndFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral((params[0].getValue() as number) & (params[1].getValue() as number));
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async bitOrFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral((params[0].getValue() as number) | (params[1].getValue() as number));
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async bitXorFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let params = await this.getBinary(evalNode);

    if (params[0].isValue && params[1].isValue) {
      result = ValueFactory.createFromLiteral((params[0].getValue() as number) ^ (params[1].getValue() as number));
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, ...params);
    }

    return result;
  };

  async bitNotFunc (evalNode : EvalNode) : Promise<ValueBase> {
    let result : ValueBase;
    
    let operand  : ValueBase = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_number);
    
    if (operand.isValue) {
      let bitNotValue : number = operand.getValue() as number;
  
      result = ValueFactory.createFromLiteral(~bitNotValue);
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    }
 
    return result;
  }  

  async isUndefinedFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let isUndefinedValue : boolean = false;
    let operand  : ValueBase;

    operand = await this.evaluate(evalNode.params[0]);

    if (operand.isUndefined) {
      isUndefinedValue = true;
    }

    return ValueFactory.createFromLiteral(isUndefinedValue);
  };  

  async memFunc (evalNode : EvalNode) : Promise<ValueBase> {
    let result : ValueBase;
    let hasError : boolean = false;
    let operand  : ValueBase;
    let memValue : boolean = false;
    let memTest : boolean | string | number | object;
    let paramType : EnumValueType;

    operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      memTest = operand.getValue();
      paramType = operand.type;
    } else {
      hasError = true;
    }

    if (!hasError) {

      let paramIndex : number = 1;

      while (!hasError && !memValue && paramIndex < evalNode.params.length) {
  
        let param = evalNode.params[paramIndex];
        
        operand = this.castValue(await this.evaluate(param), paramType);
  
        if (operand.isValue) {
          memValue = memTest == operand.getValue();
        } else {
          hasError = true;
        }  

        paramIndex++;
      }
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(memValue);
    }

    return result;
  }     

  async contains(matchAll : boolean, evalNode : EvalNode) : Promise<ValueBase> {
    
    let hasError : boolean = false;  // Assume no error initially
    let operand  : ValueBase;

    async function testContains(param) : Promise<boolean> {
      operand = this.castValue(await this.evaluate(param), EnumValueType.Value_string);
      
      if (operand.isValue) {
        let needle : string = operand.getValue() as string;

        if (!matchCase) {
          needle = needle.toUpperCase();
        }

        return haystack.indexOf(needle) >= 0;
      } else {
        hasError = true;
        return false;
      }
    }

    let result : ValueBase;
    let matchCase : boolean = false;
    let containsValue : boolean = false;
    let haystack : string;

    operand = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_boolean);

    if (operand.isValue) {

      matchCase = operand.getValue() as boolean;

      operand = this.castValue(await this.evaluate(evalNode.params[1]), EnumValueType.Value_string);

      if (operand.isValue) {
        haystack = operand.getValue() as string;

        // Don't bother is haystck is empty
        if (haystack) {

          if (!matchCase) {
            haystack = haystack.toUpperCase();
          }

          // no errors so far
          let index = 2;

          if (index < evalNode.params.length) {

            do {

              containsValue = await testContains.call(this, evalNode.params[index]);  

              index++;  
          
            } while (index < evalNode.params.length && ((matchAll && containsValue) || (!matchAll && !containsValue)));
          }
        }
      } else {
        hasError = true;
      }
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(containsValue);
    }

    return result;
  }

  async containsFunc (evalNode : EvalNode) : Promise<ValueBase> {
    return await this.contains(true, evalNode);
  }

  async containsAnyFunc (evalNode : EvalNode) : Promise<ValueBase> {
    return await this.contains(false, evalNode);
  }

  async containsAllFunc (evalNode : EvalNode) : Promise<ValueBase> {
    return await this.contains(true, evalNode);
  }

  async toUpperFunc (evalNode : EvalNode) : Promise<ValueBase> {
    let result : ValueBase;
    
    let operand  : ValueBase = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_string);
    
    if (operand.isValue) {
      let upperValue : string = operand.getValue() as string;
  
      result = ValueFactory.createFromLiteral(upperValue.toUpperCase());
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_string, evalNode, operand);
    }
 
    return result;
  }

  async toLowerFunc (evalNode : EvalNode) : Promise<ValueBase> {
    let result : ValueBase;
    
    let operand  : ValueBase = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_string);
    
    if (operand.isValue) {
      let lowerValue : string = operand.getValue() as string;
  
      result = ValueFactory.createFromLiteral(lowerValue.toLowerCase());
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_string, evalNode, operand);
    }
 
    return result;
  }

  async arrayFilterFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let filteredValues : any[] = [];
    let sourceValues : any[];
    let extractOperand : ValueBase;
    let extractProps : string [];

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {

        //  get properties to extract
        if (evalNode.params.length > 2) {
          extractOperand = await this.evaluate(evalNode.params[2]);

          if (extractOperand.isValue) {
            if (extractOperand.isArray) {
              extractProps = extractOperand.getValue() as any[];
            } else {
              hasError = true;
              operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Extracted properties must be an array: '"+extractOperand.name+"'.", evalNode);              
            }
          } else {
            hasError = true;
            operand = extractOperand;
          }
        }

        if (!hasError) {

          sourceValues = operand.getValue() as any[];

          let sourceIndex : number = 0;

          while (!hasError && sourceIndex < sourceValues.length) {

            let value = sourceValues[sourceIndex];

            if (value !== undefined) {
              operand = await this.evaluate(evalNode.params[1], [value, sourceIndex, sourceValues]);

              if (operand.isValue) {
                if (operand.getValue()) {
                  filteredValues.push(this.deepCopy(value, extractProps));
                }
              } else {
                hasError = true;
              }
            }

            sourceIndex++;
          }          
        }
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot filter non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral((filteredValues as any));
    }

    return result;
  }

  async arrayExtractFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let extractedValues : any[] = [];
    let sourceValues : any[];
    let extractOperand : ValueBase;
    let extractProp : string;

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {

        //  get properties to extract
        if (evalNode.params.length > 2) {
          extractOperand = await this.evaluate(evalNode.params[2]);

          if (extractOperand.isValue) {
            if (extractOperand.isString) {
              extractProp = extractOperand.getValue() as string;
            } else {
              hasError = true;
              operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Extracted property must be a string: '"+extractOperand.name+"'.", evalNode);              
            }
          } else {
            hasError = true;
            operand = extractOperand;
          }
        }

        if (!hasError) {

          sourceValues = operand.getValue() as any[];

          let sourceIndex : number = 0;

          while (!hasError && sourceIndex < sourceValues.length) {

            let value = sourceValues[sourceIndex];

            if (value !== undefined) {
              operand = await this.evaluate(evalNode.params[1], [value, sourceIndex, sourceValues]);

              if (operand.isValue) {
                if (operand.getValue()) {
                  if (value.hasOwnProperty(extractProp)) {
                    extractedValues.push(this.deepCopy(value[extractProp]));
                  }
                }
              } else {
                hasError = true;
              }
            }

            sourceIndex++;
          }
        }
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot extract from non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral((extractedValues as any));
    }

    return result;
  }

  async arrayCountFunc (evalNode : EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let countValue : number = 0;
    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {
        countValue = operand.arrayCount();
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot count non-array value: '"+operand.name+"'.", evalNode);
      }
    }        

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(countValue);
    }    

    return result;
  }

  async arrayCountIfFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let sourceValues : any[];
    let countValue : number = 0;
    let extractProp : string;

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {

        sourceValues = operand.getValue() as any[];

        let sourceIndex : number = 0;

        while (!hasError && sourceIndex < sourceValues.length) {

          let value = sourceValues[sourceIndex];

          if (value !== undefined) {
            operand = await this.evaluate(evalNode.params[1], [value, sourceIndex, sourceValues]);

            if (operand.isValue) {
              if (operand.getValue()) {
                countValue++;
              }
            } else {
              hasError = true;
            }
          }

          sourceIndex++;
        }

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot countif non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(countValue);
    }

    return result;
  }

  async arrayIncludesFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let needleValue : EvalValueType;
    let haystackValues : EvalValueType[];
    let includeValue : boolean = false;

    let operand = await this.evaluate(evalNode.params[1]);

    if (operand.isValue) {
      if (!operand.isObject) {

        needleValue = operand.getValue();

        operand = await this.evaluate(evalNode.params[0]);

        if (operand.isValue) {
          if (operand.isArray) {

            haystackValues = operand.getValue() as EvalValueType[];

            includeValue = haystackValues.includes(needleValue);  

          } else {
            hasError = true;
            operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot perform include on non-array value: '"+operand.name+"'.", evalNode)
          }
        } else {
          hasError = true;
        }   
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot perform include for object value: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(includeValue);
    }

    return result;
  }
  
  async arrayIndexOfFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let needleValue : EvalValueType;
    let haystackValues : EvalValueType[];
    let indexValue : number = -1;

    let operand = await this.evaluate(evalNode.params[1]);

    if (operand.isValue) {
      if (!operand.isObject) {

        needleValue = operand.getValue();

        operand = await this.evaluate(evalNode.params[0]);

        if (operand.isValue) {
          if (operand.isArray) {

            haystackValues = operand.getValue() as EvalValueType[];

            indexValue = haystackValues.indexOf(needleValue);  

          } else {
            hasError = true;
            operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot perform IndexOf on non-array value: '"+operand.name+"'.", evalNode)
          }
        } else {
          hasError = true;
        }   
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot perform IndexOf for object value: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(indexValue);
    }

    return result;
  }
  
  async arrayLengthFunc (evalNode : EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let lengthValue : number = 0;
    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {
        lengthValue = operand.arrayLength();
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot get length of non-array value: '"+operand.name+"'.", evalNode);
      }
    }        

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(lengthValue);
    }    

    return result;
  }

  async arrayUnshiftFunc (evalNode : EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let targetValues : any[];
    let operand = await this.evaluate(evalNode.params[0]);
    let target = operand;

    if (operand.isValue) {
      if (operand.isArray) {

        targetValues = operand.getValue() as any[];

        operand = await this.evaluate(evalNode.params[1]);

        if (operand.isValue) {
          targetValues.unshift(operand.getValue());
          target.setValue(targetValues);
        } else {
          hasError = true;
          operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot unshift a non-value '"+operand.name+"' onto array: '"+target.name+"'.", evalNode);
        }

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot unshift onto a non-array value: '"+target.name+"'.", evalNode);
      }
    }        

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(target.getValue());
    }   

    return result;
  }

  async arrayShiftFunc (evalNode : EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let sourceValues : any[];
    let shiftValue : any;
    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {

        sourceValues = operand.getValue() as any[];

        shiftValue = sourceValues.shift();
        operand.setValue(sourceValues);

        if (shiftValue === undefined) {
          hasError = true;
          operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot shift an empty array '"+operand.name+"'.", evalNode);
        }

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot shift from a non-array value: '"+operand.name+"'.", evalNode);
      }
    }        

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(shiftValue);
    }   

    return result;
  }

  async arrayPopFunc (evalNode : EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let sourceValues : any[];
    let popValue : any;
    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {

        sourceValues = operand.getValue() as any[];

        popValue = sourceValues.pop();
        operand.setValue(sourceValues);

        if (popValue === undefined) {
          hasError = true;
          operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot pop an empty array '"+operand.name+"'.", evalNode);
        }

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot pop from a non-array value: '"+operand.name+"'.", evalNode);
      }
    }        

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(popValue);
    }   

    return result;
  }  

  async arrayPushFunc (evalNode : EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let targetValues : any[];
    let operand = await this.evaluate(evalNode.params[0]);
    let target = operand;

    if (operand.isValue) {
      if (operand.isArray) {

        targetValues = operand.getValue() as any[];

        operand = await this.evaluate(evalNode.params[1]);

        if (operand.isValue) {
          targetValues.push(operand.getValue());
          target.setValue(targetValues);
        } else {
          hasError = true;
          operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot push a non-value '"+operand.name+"' onto array: '"+target.name+"'.", evalNode);
        }

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot push onto a non-array value: '"+target.name+"'.", evalNode);
      }
    }        

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(target.getValue());
    }   

    return result;
  }

  async arrayForEachFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let sourceValues : any[];

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {

        sourceValues = operand.getValue() as any[];

        let sourceIndex : number = 0;

        while (!hasError && sourceIndex < sourceValues.length) {

          let value = sourceValues[sourceIndex];

          if (value !== undefined) {  
            operand = await this.evaluate(evalNode.params[1], [value, sourceIndex, sourceValues]);

            if (operand && operand.isError) {
              hasError = true;
            }
          }

          sourceIndex++;
        }
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot call arrayForEach on non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(0);
    }

    return result;
  }

  async arraySomeFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let someValue : boolean = false;
    let sourceValues : any[];

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {

        sourceValues = operand.getValue() as any[];

        let sourceIndex : number = 0;

        while (!hasError && !someValue && sourceIndex < sourceValues.length) {

          let value = sourceValues[sourceIndex];

          if (value !== undefined) {
            operand = this.castValue(await this.evaluate(evalNode.params[1], [value, sourceIndex, sourceValues]), EnumValueType.Value_boolean);

            if (operand.isValue) {
              
              someValue = operand.getValue() as boolean;
  
            } else if (operand.isError) {
              hasError = true;
            }
          }  

          sourceIndex++;
        }
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot call arraySome on non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(someValue);
    }

    return result;
  }

  async arrayEveryFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let everyValue : boolean = true;
    let sourceValues : any[];

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {

        sourceValues = operand.getValue() as any[];

        let sourceIndex : number = 0;

        while (!hasError && everyValue && sourceIndex < sourceValues.length) {

          let value = sourceValues[sourceIndex];

          if (value !== undefined) {
            operand = this.castValue(await this.evaluate(evalNode.params[1], [value, sourceIndex, sourceValues]), EnumValueType.Value_boolean);

            if (operand.isValue) {
              
              everyValue = operand.getValue() as boolean;

            } else if (operand.isError) {
              hasError = true;
            }
          }

          sourceIndex++;
        }
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot call arrayEvery on non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(everyValue);
    }

    return result;
  }  
  
  async arrayMapFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let mappedValues : any[] = [];
    let sourceValues : any[];

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {

        sourceValues = operand.getValue() as any[];

        let sourceIndex : number = 0;

        while (!hasError && sourceIndex < sourceValues.length) {

          let value = sourceValues[sourceIndex];

          if (value !== undefined) {
            operand = await this.evaluate(evalNode.params[1], [value, sourceIndex, sourceValues]);

            if (operand.isValue) {
              mappedValues.push(operand.getValue());
            } else {
              hasError = true;
            }
          }

          sourceIndex++;
        }

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot call map on non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral((mappedValues as any));
    }

    return result;
  }

  async arraySortFunc(evalNode: EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let sortValues : any[];

    let operand : ValueBase;

    let sortDataValue : ValueBase = await this.evaluate(evalNode.params[0]);

    if (sortDataValue.isValue) {
      if (sortDataValue.isArray) {

        sortValues = sortDataValue.getValue() as any[];

        sortValues.sort();

        sortDataValue.setValue(sortValues);

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot call arraySort on non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      operand = sortDataValue;
      hasError = true;
    }
    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_object, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(sortDataValue);
    }

    return result;    
  }

  async arraySpliceFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let splicedValues : any[];
    let startOffset : number;
    let deleteCount : number = 0;
    let insertValues : any[] = [];
    let deletedValues : any[];
    let operand : ValueBase;

    let spliceDataValue : ValueBase = await this.evaluate(evalNode.params[0]);

    if (spliceDataValue.isValue) {
      if (spliceDataValue.isArray) {

        splicedValues = spliceDataValue.getValue() as any[];

        operand = this.castValue(await this.evaluate(evalNode.params[1]), EnumValueType.Value_number);

        if (operand.isValue) {
          
          startOffset = operand.getValue() as number;
          
          if (evalNode.params.length > 2) {

            operand = this.castValue(await this.evaluate(evalNode.params[2]), EnumValueType.Value_number);

            if (operand.isValue) {
          
              deleteCount = operand.getValue() as number;

              let paramIndex = 3;

              while (!hasError && paramIndex < evalNode.params.length) {

                operand = await this.evaluate(evalNode.params[paramIndex]);   

                if (operand.isValue) {
                  insertValues.push(operand.getValue());
                } else {
                  hasError = true;
                }

                paramIndex++;
              }
            } else {
              hasError = true;
            }

          } else {
            deleteCount = splicedValues.length - startOffset;
          }

          deletedValues = splicedValues.splice(startOffset, deleteCount, ...insertValues);

          spliceDataValue.setValue(splicedValues);

        } else {
          hasError = true;
        }

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot call arraySplice on non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      operand = spliceDataValue;
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_object, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(deletedValues);
    }

    return result;
  }  

  async arrayDeleteFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let sourceValues : any[];
    let deleteOffset : number;
    let deleteCount : number = 0;
    let deletedValues : any[] = [];
    let operand : ValueBase;

    let sourceDataValue : ValueBase = await this.evaluate(evalNode.params[0]);

    if (sourceDataValue.isValue) {
      if (sourceDataValue.isArray) {

        sourceValues = sourceDataValue.getValue() as any[];

        operand = this.castValue(await this.evaluate(evalNode.params[1]), EnumValueType.Value_number);

        if (operand.isValue) {
          
          deleteOffset = operand.getValue() as number;
          
          if (evalNode.params.length > 2) {

            operand = this.castValue(await this.evaluate(evalNode.params[2]), EnumValueType.Value_number);

            if (operand.isValue) {
          
              deleteCount = operand.getValue() as number;
            } else {
              hasError = true;
            }

          } else {
            deleteCount = 1;
          }

          let deleteIndex = deleteOffset;

          while (deleteIndex < sourceValues.length && deleteIndex < deleteOffset + deleteCount) {

            if (sourceValues[deleteIndex] !== undefined) {
              deletedValues.push(sourceValues[deleteIndex]);
              delete sourceValues[deleteIndex];
            }
            deleteIndex++;
          }  

          sourceDataValue.setValue(sourceValues);

        } else {
          hasError = true;
        }

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot call arrayDelete on non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      operand = sourceDataValue;
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_object, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(deletedValues);
    }

    return result;
  }  

  async concatFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let operand : ValueBase;
    let hasError : boolean = false;
    let concatValue : string = "";

    let paramIndex : number = 0;

    while (!hasError && paramIndex < evalNode.params.length) {

      let param = evalNode.params[paramIndex];

      operand = this.castValue(await this.evaluate(param), EnumValueType.Value_string);

      if (operand.isValue) {
        concatValue += operand.getValue();
      } else {
        hasError = true;
      }
     
      paramIndex++;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_string, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral((concatValue as any));
    }

    return result;
  }

  async getTimestampFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let strError : string = "";
    let stampValue : number;

    if (evalNode.params.length > 0) {

      let operand = await this.evaluate(evalNode.params[0]);

      if (operand.isValue) {
        if (operand.isString) {

          let strDate : string = operand.getValue() as string;
          let strFormat : string = "emDate";

          if (evalNode.params.length > 1) {
            operand = await this.evaluate(evalNode.params[1]);
            if (operand.isValue && operand.isString) {
              strFormat = operand.getValue() as string;
            } else {
              hasError = true;
              strError = "Invalid format for getTimestamp: '"+operand.name+"'.";  
            }  
          }

          if (!hasError) {

            if (strFormat == "emDate") {
              strDate = strDate.substring(0, strDate.length - 2) + " " + strDate.substring(strDate.length - 2);
            }

            let dt = new Date(strDate);
            stampValue = dt.getTime()/1000;
          }

          if (isNaN(stampValue)) {
            hasError = true;
            strError = "Cannot convert string to timestamp: '"+operand.name+"'.";
          }
        } else {
          hasError = true;
          strError = "Cannot convert non-string to timestamp: '"+operand.name+"'.";
        }
      } else {
        hasError = true;
      }
    } else {
      let dt = new Date();
      stampValue = dt.getTime()/1000;      
    }

    if (hasError) {
      result = ValueFactory.createErrorValue(EnumValueType.Value_number, strError, evalNode);
    } else {
      result = ValueFactory.createFromLiteral(stampValue);
    }

    return result;
  }

  async getFilesizeFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let hasError : boolean = false;
    let sizeValue : number;

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isString) {

        let sizeRegex = /([\d.]+)\s*(MB|KB|Bytes)/i;

        let fileSize = operand.getValue() as string;
        let matches = fileSize.match(sizeRegex);

        if (matches && 
          matches.length == 3 && 
          matches[1] !== undefined && 
          matches[2] !== undefined) {

          sizeValue = parseFloat(matches[1]);

          if (!isNaN(sizeValue)) {
            if (matches[2] == 'MB') {
              sizeValue *= 1000;
            } else if (matches[2].toUpperCase() == 'Bytes'.toUpperCase()) {
              sizeValue = 0;
            }
          } else {
            sizeValue = 0;
          }
        } else {
          sizeValue = 0;
        }

        // if (hasError) {
        //   operand = ValueFactory.createErrorValue(EnumValueType.Value_number, "Cannot convert string to filesize: '"+operand.name+"'.", evalNode);
        // }
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_number, "Cannot convert non-string to filesize: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_number, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(sizeValue);
    }

    return result;
  }

  async textReplaceFunc(evalNode: EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let strError = "";
    let hasError : boolean = false;
    let replaceValue : string;

    let sourceValue = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_string);

    if (sourceValue.isValue && sourceValue.isString) {

      let matchValue = this.castValue(await this.evaluate(evalNode.params[1]), EnumValueType.Value_string);

      if (matchValue.isValue && matchValue.isString) {

        let insertValue = this.castValue(await this.evaluate(evalNode.params[2]), EnumValueType.Value_string);

        if (insertValue.isValue && insertValue.isString) {
            
          let source = sourceValue.getValue() as string;
          let match = matchValue.getValue() as string;
          let insert = insertValue.getValue() as string;
            
          replaceValue = source.replace(match, insert);

        } else {
          hasError = true;
          strError =  "textReplaceFunc: replacement must be a string";
        }
  
      } else {
        hasError = true;
        strError =  "textReplaceFunc: pattern must be a string";
      }

    } else {
      hasError = true;
      strError =  "textReplaceFunc: source must be a string"; 
    }

    if (hasError) {
      result = ValueFactory.createErrorValue(EnumValueType.Value_boolean, strError, evalNode);
    } else {
      result = ValueFactory.createFromLiteral(replaceValue);
    } 

    return result;

  }


  async regexMatchFunc(evalNode: EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let strError = "";
    let hasError : boolean = false;

    let matchValue : boolean;

    let sourceValue = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_string);

    if (sourceValue.isValue && sourceValue.isString) {

      let patternValue = this.castValue(await this.evaluate(evalNode.params[1]), EnumValueType.Value_string);

      let flagValue = null;
      if (evalNode.params.length > 2) {
        flagValue = this.castValue(await this.evaluate(evalNode.params[2]), EnumValueType.Value_string);
      }     
      
      if (patternValue.isValue  && 
        patternValue.isString && 
        (flagValue == null || 
          (flagValue.isValue  && 
           flagValue.isString))) {

        let source = sourceValue.getValue() as string;
        let pattern = patternValue.getValue() as string;
        let flags = "";
        
        if (flagValue) {
          flags = flagValue.getValue() as string;
        }       

        let regex = new RegExp (pattern, flags);

        matchValue = regex.test(source);
      } else {
        hasError = true;
        strError =  "regexMatch: invalid paramters";
      }

    } else {
      hasError = true;
      strError =  "regexMatch: source must be a string"; 
    }

    if (hasError) {
      result = ValueFactory.createErrorValue(EnumValueType.Value_boolean, strError, evalNode);
    } else {
      result = ValueFactory.createFromLiteral(matchValue);
    } 

    return result;

  }

  async regexReplaceFunc(evalNode: EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let strError = "";
    let hasError : boolean = false;
    let replaceValue : string;

    let sourceValue = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_string);

    if (sourceValue.isValue && sourceValue.isString) {

      let patternValue = this.castValue(await this.evaluate(evalNode.params[1]), EnumValueType.Value_string);

      if (patternValue.isValue && patternValue.isString) {

        let insertValue = this.castValue(await this.evaluate(evalNode.params[2]), EnumValueType.Value_string);

        if (insertValue.isValue && insertValue.isString) {
  
          let flagValue = null;
          if (evalNode.params.length > 3) {
            flagValue = this.castValue(await this.evaluate(evalNode.params[3]), EnumValueType.Value_string);
          }     
          
          if (flagValue == null || 
              (flagValue.isValue  && 
              flagValue.isString)) {

            let source = sourceValue.getValue() as string;
            let pattern = patternValue.getValue() as string;
            let insert = insertValue.getValue() as string;
            let flags = "";
            
            if (flagValue) {
              flags = flagValue.getValue() as string;
            }       

            let regex = new RegExp (pattern, flags);

            replaceValue = source.replace(regex, insert);
          } else {
            hasError = true;
            strError =  "regexReplace: flag must be empty or a string";
          } 
        } else {
          hasError = true;
          strError =  "regexReplace: replacement must be a string";
        }
  
      } else {
        hasError = true;
        strError =  "regexReplace: pattern must be a string";
      }

    } else {
      hasError = true;
      strError =  "regexReplace: source must be a string"; 
    }

    if (hasError) {
      result = ValueFactory.createErrorValue(EnumValueType.Value_boolean, strError, evalNode);
    } else {
      result = ValueFactory.createFromLiteral(replaceValue);
    } 

    return result;

  }

  async regexExtractFunc(evalNode: EvalNode) : Promise<ValueBase> {

    let result : ValueBase;
    let strError = "";
    let hasError : boolean = false;
    let extractValue : string = '';

    let sourceValue = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_string);

    if (sourceValue.isValue && sourceValue.isString) {

      let patternValue = this.castValue(await this.evaluate(evalNode.params[1]), EnumValueType.Value_string);

      let matchValue = this.castValue(await this.evaluate(evalNode.params[2]), EnumValueType.Value_string);

      let flagValue = null;
      if (evalNode.params.length > 3) {
        flagValue = this.castValue(await this.evaluate(evalNode.params[3]), EnumValueType.Value_string);
      }

      if (patternValue.isValue  && 
          patternValue.isString && 
          matchValue.isValue  && 
          matchValue.isString &&
          (flagValue == null || 
            (flagValue.isValue  && 
             flagValue.isString))) {

        let source = sourceValue.getValue() as string;
        let pattern = patternValue.getValue() as string;
        let match = matchValue.getValue() as string;   
        let flags = "";
        
        if (flagValue) {
          flags = flagValue.getValue() as string;
        } 

        let regex = new RegExp (pattern, flags);

        let matches = regex.exec(source);

        if (matches) {
    
          var repRegex = /(\$)?(\$(\d+|&))/g;
    
          extractValue = match.replace(repRegex, function(full, amp, grp, idx) {
    
            var result = '';
    
            if (amp) {
              result = grp;
            } else if (idx === '&') {
              result = matches[0];
            } else {
    
              var index = parseInt(idx);
    
              if (index > 0 && index < matches.length && matches[index] !== undefined) {
                result = matches[index];
              } else {
                result = '';
              }
            }
            return result;
    
          });
        } else {

          if (evalNode.params.length > 4) {
            let defaultValue = this.castValue(await this.evaluate(evalNode.params[4]), EnumValueType.Value_string);
            if (defaultValue.isValue && defaultValue.isString) {

              extractValue = defaultValue.getValue() as string;
            } else { 
              hasError = true;
              strError =  "regexExtract: default must be a string";
            }
          }
        }
      } else {
        hasError = true;
        strError =  "regexExtract: invalid paramters";
      }

    } else {
      hasError = true;
      strError =  "regexExtract: source must be a string"; 
    }

    if (hasError) {
      result = ValueFactory.createErrorValue(EnumValueType.Value_number, strError, evalNode);
    } else {
      result = ValueFactory.createFromLiteral(extractValue);
    }

    return result;    
  }

  async arrayJoinFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let arrValues : string[];
    let joinValue : string = "";
    let separator : string = ",";

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isArray) {

        //  get properties to extract
        if (evalNode.params.length > 1) {
          let separatorOperand = await this.evaluate(evalNode.params[1]);

          if (separatorOperand.isValue) {
            if (separatorOperand.isString) {
              separator = separatorOperand.getValue() as string;
            } else {
              hasError = true;
              operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Join separator must be a string: '"+separatorOperand.name+"'.", evalNode);              
            }
          } else {
            hasError = true;
            operand = separatorOperand;
          }
        }

        if (!hasError) {

          arrValues = operand.getValue() as string[];

          joinValue = arrValues.join(separator);

        }
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot join non-array value: '"+operand.name+"'.", evalNode);
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(joinValue);
    }

    return result;
  }

  async lengthFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let lengthValue : number;

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isString) {
        lengthValue = (operand.getValue() as string).length;
        
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot get length on non-string value: '"+operand.name+"'.", evalNode);     
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(lengthValue);
    }

    return result;    
  }

  async subStringFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let subStringValue : string;

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isString) {

        let sourceString  : string =  operand.getValue() as string;

        operand = this.castValue(await this.evaluate(evalNode.params[1]), EnumValueType.Value_number);

        if (operand.isValue) {
          
          let startOffset : number = operand.getValue() as number;
          
          if (evalNode.params.length > 2) {

            operand = this.castValue(await this.evaluate(evalNode.params[2]), EnumValueType.Value_number);

            if (operand.isValue) {

              let endOffset : number = operand.getValue() as number;

              subStringValue = sourceString.substring(startOffset, endOffset);

            } else {
              hasError = true;
            }   
          } else {

            subStringValue = sourceString.substring(startOffset);

          }
        } else {
          hasError = true;
        }
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot get substring of non-string value: '"+operand.name+"'.", evalNode);     
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(subStringValue);
    }

    return result;    
  }

  async randomIntFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let fromValue : number;
    let toValue : number;
    let swapValue : number;
    let randomValue : number = 0;

    let operand = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_number);

    if (operand.isValue) {

      fromValue =  Math.floor(operand.getValue() as number);

      operand = this.castValue(await this.evaluate(evalNode.params[1]), EnumValueType.Value_number);

      if (operand.isValue) {
        
        toValue = Math.floor(operand.getValue() as number);

        if (toValue < fromValue) {
          swapValue = toValue;
          toValue = fromValue;
          fromValue = swapValue;
        }

        
        randomValue = fromValue + Math.floor(Math.random() * (toValue - fromValue + 1));

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot get 'to' pararmeter for randomInt function.", evalNode);     
      }
    } else {
      hasError = true;
      operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot get 'from' pararmeter for randomInt function.", evalNode);     
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(randomValue);
    }

    return result;    
  }

  async setClipboardFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let clipboardValue : string;

    let operand = this.castValue(await this.evaluate(evalNode.params[0]), EnumValueType.Value_string);

    if (operand.isValue) {
      clipboardValue = operand.getValue() as string;

      await navigator.clipboard.writeText(clipboardValue);  
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(true);
    }

    return result;     
  }

  async textFormatFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let formatValue : string = "";

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {

      if (operand.isString) {

        let sourceString  : string =  operand.getValue() as string;

        if (sourceString.length > 0) {
          formatValue = this.assistValues.formatText(evalNode.scope, sourceString);
        }   
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot format a non-string value: '"+operand.name+"'.", evalNode);     
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(formatValue);
    }

    return result;    
  }

  async splitFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let splitValues : string[] = [];
    let separator : string = "";

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isString) {

        if (evalNode.params.length > 1) {
          let separatorOperand = await this.evaluate(evalNode.params[1]);

          if (separatorOperand.isValue) {
            if (separatorOperand.isString) {
              separator = separatorOperand.getValue() as string;
            } else {
              hasError = true;
              operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Split separator must be a string: '"+separatorOperand.name+"'.", evalNode);              
            }
          } else {
            hasError = true;
            operand = separatorOperand;
          }
        } 

        let sourceString  : string =  operand.getValue() as string;

        if (sourceString.length > 0) {

          if (separator !== "") {
            splitValues = sourceString.split(separator);
          } else {
            splitValues.push(sourceString);
          }

        }

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot format a non-string value: '"+operand.name+"'.", evalNode);     
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(splitValues);
    }

    return result;    
  }

  async objectAssignFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let targetDataValue : ValueBase;
    let sourceDataValue : ValueBase;
    let targetObject : object;
    let result : ValueBase;


    targetDataValue = await this.evaluate(evalNode.params[0]);
    if (targetDataValue.isObject) {
      targetObject = this.deepCopy(targetDataValue.getValue());
    } else {
      hasError = true;
      result = ValueFactory.createErrorValue(evalNode.type, "object assign target must be an object", evalNode);
    }

    if (!hasError) {
      sourceDataValue = await this.evaluate(evalNode.params[1]);
      if (sourceDataValue.isObject) {
        targetObject = Object.assign(targetObject, sourceDataValue.getValue());
      } else {
        hasError = true;
        result = ValueFactory.createErrorValue(evalNode.type, "object assign source must be an object", evalNode);
      }
    }

    if (!hasError) {
      result = ValueFactory.createFromLiteral(targetObject);
    }
    
    return result;

  };
  
  async objectKeysFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let hasError = false;
    let sourceDataValue : ValueBase;
    let keysValue : string[] = [];
    let result : ValueBase;


    sourceDataValue = await this.evaluate(evalNode.params[0]);
    if (sourceDataValue.isObject) {
      keysValue = Object.keys((sourceDataValue.getValue() as object));
    } else {
      hasError = true;
      result = ValueFactory.createErrorValue(evalNode.type, "object keys source must be an object", evalNode);
    }

    if (!hasError) {
      result = ValueFactory.createFromLiteral(keysValue);
    }
    
    return result;

  };

  async getDateFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let format : string = "j M Y";
    let timestamp : number = NaN;

    if (evalNode.params.length > 0) {
      let formatOperand = await this.evaluate(evalNode.params[0]);
      if (formatOperand.isValue) {
        if (formatOperand.isString) {
          format = formatOperand.getValue() as string;
        }
      }

      if (evalNode.params.length > 1) {
        let timestampOperand = await this.evaluate(evalNode.params[1]);
        if (timestampOperand.isValue) {
          if (timestampOperand.isNumber) {
            timestamp = timestampOperand.getValue() as number;
          }
        }
      }
    }

    let months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let dt = new Date();

    if (!isNaN(timestamp)) {
      dt.setTime(timestamp * 1000);
    }

    let dateString : string = format;

    if (dateString.indexOf("d") != -1) {
      let day = "0"+dt.getDate();
      dateString = dateString.replace("d", day.substring(day.length-2));
    } 
    
    if (dateString.indexOf("j") != -1) {
      dateString = dateString.replace("j", ""+dt.getDate());
    } 

    if (dateString.indexOf("y") != -1) {
      let year = ""+dt.getFullYear();
      dateString = dateString.replace("y", year.substring(year.length-2));
    }    

    if (dateString.indexOf("Y") != -1) {
      dateString = dateString.replace("Y", ""+dt.getFullYear());
    }    
    
    if (dateString.indexOf("m") != -1) {
      let month = "0"+(dt.getMonth()+1);
      dateString = dateString.replace("m", month.substring(month.length-2));
    }    

    if (dateString.indexOf("n") != -1) {
      dateString = dateString.replace("n", ""+(dt.getMonth()+1));
    }    

    if (dateString.indexOf("M") != -1) {
      dateString = dateString.replace("M", months[dt.getMonth()]);
    } 

    if (dateString.indexOf("w") != -1) {
      dateString = dateString.replace("w", ""+dt.getDay());
    } 
    
    return ValueFactory.createFromLiteral(dateString);

  };

  async setStepTextFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let textValue : string = "";

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isString) {

        textValue = operand.getValue() as string;
        this.assistStateService.setStepProcessText(textValue);        

      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot set step text with a non-string value: '"+operand.name+"'.", evalNode);     
      }
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(true);
    }

    return result;    
  }  

  async clearStepTextFunc (evalNode : EvalNode) : Promise<ValueBase> {

    this.assistStateService.clearStepProcessText();

    return ValueFactory.createFromLiteral(true);    
  }    

  async displayErrorFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let errorTextValue : string = "";
    let canRetyrValue : boolean = false;
    let errorResponse : boolean;

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      if (operand.isString) {
    
        errorTextValue = operand.getValue() as string;
    
        if (evalNode.params.length > 1) {

          operand = this.castValue(await this.evaluate(evalNode.params[1]), EnumValueType.Value_boolean);

          if (operand.isValue) {
            canRetyrValue = operand.getValue() as boolean;
          } else {
            hasError = true;
          }
        }
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot display non-string error: '"+operand.name+"'.", evalNode);     
      }
    } else {
      hasError = true;
    }          

    if (!hasError) {
      errorResponse =  await this.assistStateService.displayError(errorTextValue, canRetyrValue);
      result = ValueFactory.createFromLiteral(errorResponse);
    } else {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    }

    return result;
  }    

  async consoleLogFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let textValue : string = "";

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {

      if (!operand.isObject) {
        textValue = this.castValue(operand, EnumValueType.Value_string).getValue() as string;
      } else {
        textValue = JSON.stringify(operand.getValue());
      }  
        
      this.assistStateService.consoleLog(textValue);        

    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(true);
    }

    return result;    
  }  

  async setLayoutFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {

      if (operand.isObject) {
        this.assistStateService.setLayoutOptions(operand.getValue());        
      } else {
        hasError = true;
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Cannot setLayout with a non-object value", evalNode);     
      }  
        
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(true);
    }

    return result;    
  }  

  async jsonStringifyFunc (evalNode : EvalNode) : Promise<ValueBase> {

    let result: ValueBase;
    let hasError : boolean = false;
    let jsonValue : string = "";

    let operand = await this.evaluate(evalNode.params[0]);

    if (operand.isValue) {
      jsonValue = JSON.stringify(operand.getValue());        
    } else {
      hasError = true;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_boolean, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral(jsonValue);
    }

    return result;    
  }  

  async pauseUpdateFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let operand : ValueBase;
    let hasError : boolean = false;
    let pauseValue : boolean = true;

    let paramIndex : number = 0;

    while (!hasError && paramIndex < evalNode.params.length) {

      operand = await this.evaluate(evalNode.params[0]);

      if (operand.isValue && !operand.isLiteral && !operand.isReference) {      

        operand.pause();

      } else {
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Literals and references cannot be paused", evalNode);  
        hasError = true;
        pauseValue = false;   
      }
     
      paramIndex++;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_string, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral((pauseValue as any));
    }

    return result;
  }  

  async unpauseUpdateFunc(evalNode: EvalNode) : Promise<ValueBase> {
    
    let result: ValueBase;
    let operand : ValueBase;
    let hasError : boolean = false;
    let unpauseValue : boolean = true;

    let paramIndex : number = 0;

    while (!hasError && paramIndex < evalNode.params.length) {

      operand = await this.evaluate(evalNode.params[0]);

      if (operand.isValue && !operand.isLiteral && !operand.isReference) {      

        operand.unpause();

      } else {
        operand = ValueFactory.createErrorValue(EnumValueType.Value_object, "Only values can be unpaused", evalNode);  
        hasError = true;
        unpauseValue = false;   
      }
     
      paramIndex++;
    }

    if (hasError) {
      result = ValueFactory.createResultValue(EnumValueType.Value_string, evalNode, operand);
    } else {
      result = ValueFactory.createFromLiteral((unpauseValue as any));
    }

    return result;
  }  

}

