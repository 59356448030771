import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, Subject, of, Subscription } from 'rxjs';
import { reduce, filter, concatMap, take, map, catchError, min } from 'rxjs/operators';
import { MessagePoster } from '../classes/message-poster';
import { AssistConfig } from '../classes/assist-config';
import { EnumDebugLevel } from '../enums/enum-debug-level';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AssistStateService } from "./assist-state.service"
import { IAssistConfigResponse } from '../interfaces/iassist-config';

@Injectable({
  providedIn: 'root'
})
export class AssistService {

  workflowData : any = null;
  config : AssistConfig;
  serverPrefix : string;
  serverSuffix : string;
  orgUrl : string;
  isLoading : boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private assistStateService: AssistStateService
  ) { 
    this.addEventListeners();
    this.serverPrefix = environment.serverPrefix;
    this.serverSuffix = '/api/assist';  //?XDEBUG_SESSION_START=netbeans-xdebug
    this.orgUrl = this.authService.getOrgUrl();

    let subscription = this.assistStateService.onAssistWaitResult.subscribe(data => this.handleWaitResult(data));

  }

  private _debugLevel: BehaviorSubject<EnumDebugLevel> = new BehaviorSubject(EnumDebugLevel.debug_info);
  public readonly debugLevel: Observable<EnumDebugLevel> = this._debugLevel.asObservable();   

  private _assistConfig: Subject<AssistConfig> = new Subject();
  public readonly workflowConfig: Observable<AssistConfig> = this._assistConfig.asObservable();   

  private _startWorkflow: Subject<number> = new Subject();
  public readonly startWorkflow: Observable<number> = this._startWorkflow.asObservable();   

  private _resetWorkflow: Subject<number> = new Subject();
  public readonly resetWorkflow: Observable<number> = this._resetWorkflow.asObservable();   

  private messagePoster = new MessagePoster();

  restart() {
    this.reset();
    if (this.workflowData !== null) {
      this.loadAssistConfig(this.workflowData);
    }
  }

  async reset() {

    await this.assistStateService.assistReset(); 

    this._resetWorkflow.next(1);   
    if (this.config) {
      this.config.reset();
    }
  }

  assistDeactivate() {
    this.isLoading = false;
    this._resetWorkflow.next(1);   
    if (this.config) {
      this.config.reset();
    }
  }

  addEventListeners() {
    window.addEventListener("message", (event) => {

      console.log("service listener:" + event.data?.type);

      // We only accept messages from ourselves
      if (event.source != window) {
        return;
      }

      var type = event.data?.type;
      var data = event.data?.data;

      if (type) {

        switch (type) {
          case 'toAppInstalled':
            this.carinaInstalled(); 
          break;
          case 'toAppLoadWorkflow':
            this.getAssistConfig(data);
          break;
          case 'toAppStartWorkflow': 
            this.startWorkFlow();
          break;
          case 'toAppReturnValue': 
            this.setWorkflowValue(data);
          break;
          case 'toAppMacroComplete': 
            this.completeMacro(data);
          break;
          case 'toAppPdfActionComplete': 
            this.completePdfAction(data);
          break;
          case 'toAppPdfProgress': 
            this.pdfProgress(data);
          break;
          case 'toAppTaskActionComplete': 
            this.completeTaskAction(data);
          break;
          case 'toAppAssistWait': 
            this.assistStateService.assistWait(data);
          break;
          case 'toAppResetComplete': 
            this.assistStateService.resetComplete(data);
          break;
        }
      }
    });    
  }

  handleWaitResult(data : any) {
    if (data && data.cancel) {
      this.messagePoster.postMessage("fromAppAssistWaitAbort", data );
    }
  }

  setAssistConfig() {
    this._assistConfig.next(this.config);
  } 

  carinaInstalled () {

    if (this.assistStateService.isActive) {
      this.messagePoster.postMessage('fromAppAssistOpen', {});  
    }
  }

  async getAssistConfig(workflowData: any) : Promise<boolean> {

    let result = false;

    if (this.assistStateService.isActive && !this.isLoading) {

      this.isLoading = true;

      result = this.validateVersion(workflowData);
    
      if (result) {

        if (await this.assistStateService.canStartScript()) {
          await this.reset();
        } else {
          result = false;
        }

      }

      if (result) {
        this.workflowData = workflowData;
        this.loadAssistConfig(workflowData);
      } else {

        this.isLoading = false;
      }
    }

    return result;
  }

  validateVersion(workflowData: any) : boolean {

    let result : boolean = false;

    if (workflowData.hasOwnProperty("version")) {
      let reqVersion = environment.addOnVersion.split(".", 3);
      let curVersion = workflowData.version.split(".",3);
      
      let index : number = 0;
      result = true;

      while ((result == true) && 
             (index < 3) && 
             (parseInt(reqVersion[index]) >= parseInt(curVersion[index]))) {
        if (parseInt(reqVersion[index]) > parseInt(curVersion[index])) {
          result = false;
        }

        index++;
      }
    }

    if (!result) {
      this.assistStateService.displayError("Workflow Assist requires Carina browser extension version " + environment.addOnVersion + " or later.  Please install the latest version.", false);
    }

    return result;
  }


  startWorkFlow () {

    if (this.assistStateService.hasScriptLoaded) {
      this._startWorkflow.next(1);

      if (this.workflowConfig) {
        this.assistStateService.resetLayout();
        this.assistStateService.setScriptRunning(true);
        this.config.startWorkflow();
      }
    }
    //this.getWorkflowFields();

  }

  setWorkflowValue(data : any) {

    if (this.assistStateService.hasScriptLoaded && data.hasOwnProperty("name")) {
      this.config.assistValues.setPendingValue(data.name, data);
    }
  }

  completeMacro (data : any) {

    if (this.assistStateService.hasScriptLoaded && data.hasOwnProperty("macroName")) {
      this.config.assistValues.completeMacro(data.macroName, data);
    }
  }

  completePdfAction (data : any) {

    if (this.assistStateService.hasScriptLoaded && data.hasOwnProperty("action")) {
      this.config.assistValues.completePdfAction(data.action, data);
    }
  }
  pdfProgress (data : any) {

    if (this.assistStateService.hasScriptLoaded) {
      this.config.assistValues.pdfProgress(data);
    }
  }

  completeTaskAction (data : any) {

    if (this.assistStateService.hasScriptLoaded && data.hasOwnProperty("action")) {
      this.config.assistValues.completeTaskAction(data.action, data);
    }
  }

  loadAssistConfig(workflowData: any) {

    let request$ = this.assistStateService.loadConfig(workflowData.values).pipe (
      map(response => {
        if (this.handleResponse(response)) {
          this.workflowData = workflowData;
          this.assistStateService.setScriptIds(workflowData.values.scriptId, response.response.instanceId);
        }
        this.isLoading = false;
        return true;
      }),
      catchError (err => {
        this.isLoading = false;
        return throwError(err)
      })
    ).subscribe();
   
    return request$;

  }

  handleResponse(response : IAssistConfigResponse) : boolean {

    let result = false;

    if (response && response.hasOwnProperty("resultCode")) {

      if (response.resultCode == -1) {

        this.assistStateService.displayOffLineError();
      
      } else if (response.resultCode != 0) {
        
        this.assistStateService.displayError(response.errors, false);

      } else if (response.hasOwnProperty("response") && response.response) {

        result = true;  
    
        this.config = AssistConfig.getInstance();
  
        this.assistStateService.setUserCancel(false);
        this.config.initialise(response.response);
      
        this.setAssistConfig();
      
        if (result) {
          this.messagePoster.postMessage("fromAppWorkflowLoaded", { workflowId: 1, appConfig: this.config.appConfig } );
        }
      }
    }

    return result;
  }

  testSession() {

    let request$ = this.assistStateService.testSession().pipe (
      map(response => {
        if (response) {
          return true;
        } else {
          return true;
        }
      }),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  getServerUrl(workflowData: any) : string {
    return '/' + this.orgUrl + this.serverSuffix + '/app/' + workflowData.values.appId + '/loc/' + workflowData.values.locationId + '/script/' + workflowData.values.scriptId;
  }  
}


  // getWorkflowFields() {

  //   let values: ValueBase[] = this.config.data.values;

  //   const obs1: Observable<ValueBase> = of(...values);

  //   const obs2 = obs1.pipe(
  //     filter(dataValue => 
  //       // Filter out values that are not pending
  //       dataValue.state === EnumValueState.FieldState_Pending
  //     ),
  //     concatMap(
  //       (dataValue: ValueBase, index: number) => {
  //         // Get each value from the app content in turn waiting for the value state to change
  //         console.log('fromAppGetValue : ' + index + ":" + dataValue.name)
  //         this.messagePoster.postMessage('fromAppGetValue', { name: dataValue.name });
  //         // Pass the value state onwards when it arrives
  //         return dataValue.onChangeState.pipe(take(1)) as Observable<EnumValueState>;
  //       }
  //     ),
  //     reduce(
  //       (firstValue : number, nextValue : number) => {
  //         //  Capture any errors along the way  
  //         if (firstValue == EnumValueState.FieldState_Error || nextValue == EnumValueState.FieldState_Error) {
  //           return EnumValueState.FieldState_Error;
  //         } else {
  //           return nextValue;
  //         }
  //       }
  //     )
  //   ); 

  //   let subscription : Subscription = obs2.subscribe(
  //     (val : EnumValueState)  => {
  //       if (val == EnumValueState.FieldState_Error) {
  //         console.log("To do: error getting value " + val)         
  //       }
  //       subscription.unsubscribe();
  //     }
  //   );
  // }