import { EnumValueType } from "../enums/enum-value-type";
import { IDataValue } from "../interfaces/idata-value";
import { ValueBase } from "./value-base";


export class ValueObject extends ValueBase {
  value : object;

  constructor(config: IDataValue) {
    super(config);

    //this.assistEval = AssistEval.getInstance();
    this.value = null;

    if (config.hasOwnProperty("value") && typeof config.value === "object" && config.value !== null) {
      this.setValue(config.value);
    }
  }

  get isArray() : boolean {
    return Array.isArray(this.value);
  }

  get isObjectArray() : boolean {
    return this.isArray && this.value["length"].length > 0 && typeof this.value[Object.keys(this.value)[0]] === "object";
  }

  get isPlural() : boolean {
    return this.isArray ? this.value["length"] > 1 : false;
  }

  get isEmpty() : boolean {
    return super.isEmpty || (this.isArray && this.value["length"] == 0) || (!this.isArray && this.value == null);  
  }  

  reset() {
    super.reset();
    this.value = null;
  }

  canCast(newType : EnumValueType) : boolean {
    return newType === EnumValueType.Value_object;
  }

  getValue() : object {
    return this.value;
  }

  setValue(value: object): void {
    this.value = value;
    super.setValue(value);
    //this._value.next(this.value);
  }

  assignValue (newValue : ValueBase) {

    if (newValue.isObject) {
      this.value =  (newValue as ValueObject).value;
    } else {
      this.value = null;
    }

    super.assignValue(newValue);

  }

  arrayCount() : number {

    let count : number = 0;

    if (this.isArray) {
      count = Object.keys(this.value).length;
    }
    
    return count;
  }  

  arrayLength() : number {

    let length : number = 0;

    if (this.isArray) {
      length = (this.value as any[]).length;
    }
    
    return length;
  }    
}
