import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IValueComponent } from '../../interfaces/ivalue-component';
import { ValueBaseComponent } from './value-base.component';

@Component({
  selector: 'carina-value-table',
  templateUrl: './value-table.component.html'
})
export class ValueTableComponent extends ValueBaseComponent implements OnInit, IValueComponent {

  @ViewChild('viewPaginator') set content(content: ElementRef) {
    if(content) { // initially setter gets called with undefined
      this.dataSource.paginator = content as unknown as MatPaginator;
    }
  };

  values : any[] = [];
  columns : any[] = [];
  rows : any [] = [];
  showColumns : string [] = [];
  secondaryColumns : string [] = [];
  hasSecondary : boolean = false
  showClip : boolean = false;
  hasPaginator : boolean = false;
  pagination : any;
  clipMap : Map<string, string> = null;

  dataSource : MatTableDataSource<any>;
 
  constructor() { 

    super();

    this.dataSource = new MatTableDataSource(this.values);
           
  }

  get showPaginator() : boolean {
    return this.hasPaginator && this.dataSource.data.length > this.pagination.pageSizeOptions[0];
  }

  ngOnInit(): void {

    super.ngOnInit();

    let viewConfig = this.stepData.viewConfig;

    this.columns = viewConfig.columns;
    
    if (viewConfig.hasOwnProperty("rows")) {
      this.rows = viewConfig.rows;
    }

    this.columns.forEach((column) => {
      if (this.rows.length == 0 || this.rows[0].includes(column.name)) {
        this.showColumns.push(column.name)
      }
      if (this.rows.length == 2 && this.rows[1].includes(column.name)) {
        this.secondaryColumns.push(column.name)
        this.hasSecondary = true;
      }
    });

    if (viewConfig.hasOwnProperty("showClip") && viewConfig.showClip) {
      this.showClip = true;
    }

    if (viewConfig.hasOwnProperty("paginator")) {

      let paginator = viewConfig.paginator;
      this.hasPaginator = true;

      this.pagination = {
        pageSizeOptions : [10, 25, 50], 
        pageSize : 10,
        showFirstLastButtons : true,
        hidePageSize : false,
        pageIndex : 0
      };

      if (paginator.hasOwnProperty("pageSizeOptions") && Array.isArray(paginator.pageSizeOptions)) {
        this.pagination.pageSizeOptions = paginator.pageSizeOptions;
      }
      if (paginator.hasOwnProperty("pageSize") && paginator.pageSize > 0) {
        this.pagination.pageSize = paginator.pageSize;
      }
      if (paginator.hasOwnProperty("showFirstLastButtons") && !paginator.showFirstLastButtons) {
        this.pagination.showFirstLastButtons = false;
      }
      if (paginator.hasOwnProperty("hidePageSize") && paginator.hidePageSize) {
        this.pagination.hidePageSize = true;
      }
      if (paginator.hasOwnProperty("pageIndex") && paginator.pageIndex) {
        this.pagination.pageIndex = paginator.pageIndex;
      }
    }

    if (viewConfig.hasOwnProperty("clipMap") && Array.isArray(viewConfig.clipMap)) {
      this.clipMap = new Map<string, string>();

      viewConfig.clipMap.forEach(entry =>{
        if (entry.hasOwnProperty("key") && 
            entry.hasOwnProperty("val")) {
          
          this.clipMap.set(entry.key, entry.val);    

        }    
      });
    }
  }
  
  findDeclaredValue() {

    let result = super.findDeclaredValue();

    if (result && this.dataValue) {

      this._subscriptions.push ( 
        this.dataValue.onChangeValue.subscribe(value => this.updateData(value))
      );

      if (this.dataValue.isValue) {
        this.updateData(this.dataValue.getValue());
      }  

    }
    
    return result;
  }

  updateData (value : any) {

    if (typeof value === "object") {

      let newData : any[];

      if (Array.isArray(value)) {
        newData = value;
      } else {
        newData = [];
        newData.push(value);
      }

      this.dataSource.data = newData;
    }
  }

  process(procName : string, value : string, index: number, entry: any): void {
    this.stepData.processAction(procName, value, index, entry);
  }

  clip () {

    let maxColumn : number = this.columns.length - 1;
    let strClip : string = "";

    let data :any[] = this.dataSource.data;

    this.columns.forEach((column, index) => {

      strClip = strClip + '"' + column.title + '"';

      if (index < maxColumn) {
        strClip = strClip + "\t";
      } else {
        strClip = strClip + "\n";
      }
    });

    data.forEach((row : any) => {

      this.columns.forEach((column, index) => {
        
        let value = "" + row[column.name];

        if (this.clipMap && this.clipMap.has(value)) {
          value = this.clipMap.get(value);

        }  

        strClip = strClip + value as string;

        if (index < maxColumn) {
          strClip = strClip + "\t";
        } else {
          strClip = strClip + "\n";
        }
      });
    });


    navigator.clipboard.writeText(strClip);  

  }

  showSecondary (index, row) :boolean {

    return row.hasOwnProperty("showSecondary") && row.showSecondary == true;
  }

}