import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { PdfComponent } from './pages/pdf/pdf.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  declarations: [
    PdfComponent
  ],
  exports: [
    PdfComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    NgxExtendedPdfViewerModule
  ],
  // entryComponents: [
  //   MatButtonToggle, MatButtonToggleGroup
  // ],
  // bootstrap: [MatButtonToggle, MatButtonToggleGroup]
})
export class PdfModule {}