import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { StepPrompt } from '../../classes/step-prompt';
import { ValueBase } from '../../classes/value-base';
import { EnumStepType } from '../../enums/enum-step-type';
import { IStepComponent } from '../../interfaces/icomponent-step';
import { StepBaseComponent } from '../steps/step-base.component';

@Component({
  selector: 'carina-step-se',
  templateUrl: './prompt-entry.component.html'
})
export class PromptEntryComponent  extends StepBaseComponent implements OnInit, IStepComponent {

  @Input() stepData: StepPrompt;

  public isTextarea : boolean = false;
  public hideButton : boolean = false;
  public rows : number = 10;
  public name : string = "";
  public isPassword : boolean = false;
  public bHidePassword : boolean = true;

  constructor() {
    super(); 
  }

  ngOnInit(): void {

    this.getGroupOptions(this.stepData);

    var promptConfig = this.stepData.promptConfig;

    if (promptConfig.hasOwnProperty("type") && promptConfig.type == "textarea") {
      this.isTextarea = true;    
      if (promptConfig.hasOwnProperty("rows") && promptConfig.rows > 0 && promptConfig.rows < 10) {
        this.rows = promptConfig.rows;
      }
    }

    if (promptConfig.hasOwnProperty("hideButton") && promptConfig.hideButton === true) {
      this.hideButton = promptConfig.hideButton;
    }

    if (promptConfig.hasOwnProperty("name")) {
      this.name = promptConfig.name;
    }

    if (promptConfig.hasOwnProperty("password")) {
      this.isPassword = promptConfig.password === true;
    }
    
    if (this.stepData.type == EnumStepType.Prompt_List) {

      let dataValue : ValueBase = this.stepData.dataValue;

      if (dataValue.isArray) {

        let arrList : string[] = dataValue.getValue() as string[];

        this.stepData.value = arrList.join();    
      } 
    }
  }

  process(choice: string) {
    this.stepData.updateValue();
    this.stepData.processNext();
  }

  change() {
    this.stepData.updateValue();
  }

}
