// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //serverPrefix: 'http://www.carina-software.com',
  serverPrefix: 'https://www.zotoh.co.uk',
  version: "1.1.68",
  sessionURL: '/apisession.php?XDEBUG_SESSION_START=netbeans-xdebug',
  versionURL: '/ng/version.json',
  serverSuffix: 'XDEBUG_SESSION_START=netbeans-xdebug',
  addOnVersion: "3.4.8",
  arrValidOrgs: ['ta','edoff','sagepub','iop','elife','rv','yourco','karger', 'gblme','pnas','tandf','nas', 'acs']

}; 

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
 