
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { IAssistConfig, IAssistConfigResponse, IAssistSessionResponse } from '../interfaces/iassist-config';
import { IMsgBoxData, IMsgBoxButton } from '../interfaces/imsg-box-data';
import { EnumLogType } from '../enums/enum-log-type'
import { ValueBase } from '../classes/value-base';
import { MessagePoster } from '../classes/message-poster';
import { timer } from 'rxjs';
import { state } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class AssistStateService {

  public serverPrefix : string;
  public serverSuffix : string;
  public orgUrl : string;
  public isActive : boolean = false;
  public scriptId : number = 0;
  public scriptInstanceId : number = 0;

  private _systemInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly systemInProgress:Observable<boolean> = this._systemInProgress.asObservable();   

  private _stepProcessText: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public readonly stepProcessText:Observable<string> = this._stepProcessText.asObservable();  

  private _runtimeError: Subject<string> = new Subject<string>();
  public readonly onRuntimeError:Observable<string> = this._runtimeError.asObservable();  

  private _consoleLog: Subject<string> = new Subject<string>();
  public readonly onConsoleLog:Observable<string> = this._consoleLog.asObservable();  

  private _assistWait: Subject<any> = new Subject<string>();
  public readonly onAssistWait:Observable<any> = this._assistWait.asObservable(); 

  private _assistWaitResult: Subject<any> = new Subject<any>();
  public readonly onAssistWaitResult:Observable<any> = this._assistWaitResult.asObservable(); 

  private _displayMsgBox: Subject<IMsgBoxData> = new Subject<IMsgBoxData>();
  public readonly onDisplayMsgBox:Observable<IMsgBoxData> = this._displayMsgBox.asObservable(); 

  private _msgBoxResult: Subject<any> = new Subject<any>();
  public readonly onMsgBoxResult:Observable<any> = this._msgBoxResult.asObservable(); 

  private _userCancel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly onUserCancel:Observable<boolean> = this._userCancel.asObservable(); 

  private _scriptRunning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly onScriptRunning:Observable<boolean> = this._scriptRunning.asObservable(); 

  private _actionsOutstanding: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly onActionsOutstanding:Observable<boolean> = this._actionsOutstanding.asObservable(); 

  private _assistReset: Subject<boolean> = new Subject<boolean>();
  public readonly onAssistReset:Observable<boolean> = this._assistReset.asObservable(); 

  private _assistResetComplete: Subject<boolean> = new Subject<boolean>();
  public readonly onAssistResetComplete:Observable<boolean> = this._assistResetComplete.asObservable(); 

  private _layoutOptions: Subject<any> = new Subject<any>();
  public readonly onLayout:Observable<string> = this._layoutOptions.asObservable();  

  private _fileDownloaded: Subject<string> = new Subject<string>();
  public readonly onFileDownload:Observable<string> = this._fileDownloaded.asObservable();  

  private messagePoster = new MessagePoster();

  private checkSessionExpired : boolean = false;

  private checkSessionSubscription : Subscription = null;

  private fileData : Blob;
  
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {

    this.serverPrefix = environment.serverPrefix;
    this.serverSuffix = '/api/assist';  //?XDEBUG_SESSION_START=netbeans-xdebug
    this.orgUrl = this.authService.getOrgUrl();

  };

  setActive(value : boolean) {
    this.isActive = value;
  }

  activateTab() : void {
    this.messagePoster.postMessage('fromAppActivateTab', {});
  }

  setSystemInProgress(value: boolean) {
    this._systemInProgress.next(value);
  }

  setStepProcessText(text: string) {
    this._stepProcessText.next(text);
  }
  
  clearStepProcessText() {
    this._stepProcessText.next("");
  }

  getStepProcessText() : string {
    return this._stepProcessText.getValue();
  }

  logger(type : EnumLogType, text: string) {
    this._runtimeError.next(text);
  }
  
  consoleLog(text: string) {
    this._consoleLog.next(text);
  }

  setLayoutOptions(options: any) {
    this._layoutOptions.next(options);
  }

  resetLayout() {

    let options = {stepView: {enable:true, width:5}, dataView: {enable:true, width:5}, actionView: {enable:true, width:4}}

    this._layoutOptions.next(options);
  }

  setScriptIds (scriptId : number = 0, scriptInstanceId : number = 0) {
    this.scriptId = scriptId;
    this.scriptInstanceId = scriptInstanceId;

    this.startSessionTimer();
  }

  get hasScriptLoaded () : boolean {
    return this.scriptId !== 0;
  }

  setUserCancel(cancelled : boolean) {

    if (!cancelled || this._scriptRunning.getValue()) {
      this._userCancel.next(cancelled);

      if (cancelled) {
        this.setScriptRunning(false);
      }
    }
  }

  get isUserCancelled() : boolean {
    return this._userCancel.getValue();
  }

  startSessionTimer() {

    if (this.checkSessionSubscription) {
      this.checkSessionSubscription.unsubscribe();
      this.checkSessionSubscription = null;
    }

    let timeout = 1*20*1000;
    this.checkSessionExpired = false;

    this.checkSessionSubscription = timer(timeout).subscribe(() => {

      this.checkSessionSubscription.unsubscribe();
      this.checkSessionSubscription = null;

      this.checkSessionExpired = true;

    });
  }

  checkSession() : Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      if (this.checkSessionExpired) {
  
        this.testSession().subscribe(hasSession => {
          if (!hasSession) {
            this.displayOffLineError();
            resolve(false);
          } else {
            this.startSessionTimer();
            resolve(true);
          }
        });
      } else {
        resolve(true);
      }
    });
  }

  assistReset() : Promise<boolean> {

    return new Promise<boolean>((resolve, reject) => {

      let subscription = this.onAssistWait.subscribe(data => {

        subscription.unsubscribe();

        subscription = this.onAssistResetComplete.subscribe(data => {
          
          subscription.unsubscribe();
          
          this.doReset();      
          
          resolve(true);
        })   
        
        // This tells the add-on to stop any running or waiting macros - send ResetComplete message when done
        this.messagePoster.postMessage('fromAppReset', {});
        
      })   
      
      // This set flag used by StepBranch to stop processing steps
      this.setUserCancel(true);
      // This is used by the Action View to close any macro waiting dialogs
      this._assistReset.next(true);

    });
  }

  doReset() {
    this.setScriptIds();
    this.setScriptRunning(false);
    this.setActionsOutstanding(false);
    this.setSystemInProgress(false);      
  }

  assistDeactivate() {

    // This set flag used by StepBranch to stop processing steps
    this.setUserCancel(true);
    // This is used by the Action View to close any macro waiting dialogs
    this._assistReset.next(true);

    // This tells the add-on to stop any running or waiting macros - send ResetComplete message when done
    this.messagePoster.postMessage('fromAppReset', {});

    this.doReset();  

  }

  resetComplete(data) {
    this._assistResetComplete.next(data);
  }

  setScriptRunning(running : boolean) {
    this._scriptRunning.next(running);
  }
  
  get isScriptRunning () : boolean {
    return this._scriptRunning.getValue();
  } 
  
  setActionsOutstanding(outstanding : boolean) {
    this._actionsOutstanding.next(outstanding);
  }

  get hasActionsOutstanding() : boolean {
    return this._actionsOutstanding.getValue();
  }

  loadConfig (requestData : any) : Observable<IAssistConfigResponse> { 

    var appId: string = "";
    var locationId: string = "";
    var scriptId: string = "";
    var urlData: string = "";

    if (requestData.hasOwnProperty("appId")) {
      appId = ""+requestData.appId;
    }
    if (requestData.hasOwnProperty("locationId")) {
      locationId = ""+requestData.locationId;
    }
    if (requestData.hasOwnProperty("scriptId")) {
      scriptId = ""+requestData.scriptId;
    }
    if (requestData.hasOwnProperty("guid")) {
      urlData = JSON.stringify({g:requestData.guid});
    }

    let url : string = this.getConfigLoadUrl(appId, locationId, scriptId, urlData);

    let http$ = this.http.get<IAssistConfigResponse>(url, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => response),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  testSession () : Observable<boolean> {

    let url : string = this.getSessionCheckUrl();

    let http$ = this.http.get<IAssistSessionResponse>(url, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => {
        let result = false;
        if (response && response.hasOwnProperty("resultCode") && response.resultCode == 0) {
          result = true;      
        }
        return result;
      }),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  metaDataCheck (metaDataName: string, params: any, metadata: any) : Observable<any> {

    let url : string = this.getMetaDataUrl(metaDataName);

    let http$ = this.http.post<any>(url, {name: metaDataName, params: params, metadata: metadata}, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => response),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  generateUUID = function () {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x7|0x8)).toString(16);
    });
    return uuid;
 } 

  createDocument (sourceName: string, docData: any, targetName: string, libName: string, groupName : string) : Observable<any> {

    let url : string = this.getCreateDocUrl(sourceName);

    let requestId = this.generateUUID();

    let http$ = this.http.post<any>(url, {sourceName: sourceName, docData: docData, targetName: targetName, libName: libName, groupName: groupName, requestId : requestId}, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => response),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  writeLog (logTypeCode: string, params: any) : Observable<any> {

    let dt = new Date();

    let url : string = this.getWriteLogUrl(this.scriptInstanceId, logTypeCode);

    let http$ = this.http.post<any>(url, {scriptId: this.scriptId, stamp: dt.getTime()/1000, log:params}, {
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
    
    let request$ = http$.pipe (
      map(response => response),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }

  scriptState (scriptStateMode: string, scriptData: ValueBase) : Observable<any> {

    let dt = new Date();

    let url : string = this.getScriptStateUrl(this.scriptId);

    let http$;

    if (scriptStateMode == "read") {

      http$ = this.http.get<any>(url, {
        headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
      });

    } else {

      let stateValue = {};
      if (scriptData.isValue && scriptData.isObject) {
        stateValue = scriptData.getValue();
      }

      http$ = this.http.post<any>(url, stateValue, {
        headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
      });
    }
    
    let request$ = http$.pipe (
      map(response => response),
      catchError (err => {
        return throwError(err)
      })
    );
   
    return request$;

  }



  getConfigLoadUrl(appId: string, locationId: string, scriptId: string, urlData : string) : string {
    let result : string =  '/' + this.orgUrl + this.serverSuffix + '/app/' + appId + '/loc/' + locationId + '/script/' + scriptId;

    if (urlData !== "") {
      result += "?data=" + btoa(urlData);
    }

    return result; 
  }
  
  fetchFile (urlFile: string, params: any) {

    let url : string = this.getFetchUrl(urlFile);

    let http$ = this.http.get(url, {
      responseType:'blob',
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({'X-Requested-With':'XMLHttpRequest'})
    });
  
    // let request$ = http$.pipe (
    //   concatMap(async (response) => {
    //     let size = await this.sendDownload(response, params);
    //     return size;
    //   }),
    //   catchError (err => {
    //     return throwError(err)
    //   })
    // );
   
    return http$;

  }  

  getFileData() : Blob {
    return this.fileData;
  }  

  getFetchUrl(urlFile : string) : string {
    return '/' + this.orgUrl + '/assistFetch.php?url='+ encodeURIComponent(urlFile);
  }  

  getSessionCheckUrl() : string {
    return '/' + this.orgUrl + this.serverSuffix + '/session/' + 1; 
  }  

  getMetaDataUrl(metaDataName: string) : string {
    return '/' + this.orgUrl + this.serverSuffix + '/meta/' + metaDataName;
  }  

  getCreateDocUrl(sourceName : string) : string {
    return '/' + this.orgUrl + this.serverSuffix + '/create/'+ sourceName;
  }  

  getWriteLogUrl(scriptInstanceId: number, logTypeCode: string) : string {
    return '/' + this.orgUrl + this.serverSuffix + '/log/' + logTypeCode + '/inst/' + scriptInstanceId;
  }  

  getScriptStateUrl(scriptId: number) : string {
    return '/' + this.orgUrl + this.serverSuffix + '/state/' + scriptId;
  }  

  getDownloadUrl(requestId: string) : string {
    return '/' + this.orgUrl + this.serverSuffix + '/?download=' + requestId;
  }  

  assistWait(data: any) {
    this._assistWait.next(data);  
  }

  setWaitResult(data: any) {
    this._assistWaitResult.next(data);
  }

  displayOffLineError() {

    let msgData : IMsgBoxData = {
      title: "Workflow Assist Error", 
      message : ["The Carina session has timed out.", "", "You must sign-on and restart Workflow Assist."],
      buttons : [{text: "Return to sign-on", value: 0}]
    };

    this._displayMsgBox.next(msgData);

    let subscription = this.onMsgBoxResult.subscribe(value =>{

      subscription.unsubscribe();

      this.authService.navigate("/signon");

    });

  }

  async canRetryError(dataValue : ValueBase): Promise<boolean> {

    return new Promise<boolean> ((resolve, reject) => {

      let msgData : IMsgBoxData = {
        title: "Workflow Assist Error", 
        message : [],
        buttons : [{text: "Cancel", value: 0}, {text:"Retry", value :1}]
      };

      if (dataValue.errData && dataValue.errData.errNum == 100) {
        msgData.message = ["Cannot find page " + dataValue.errData.data.pageType];
      }
  
      this._displayMsgBox.next(msgData);

      let subscription = this.onMsgBoxResult.subscribe(value =>{

        subscription.unsubscribe();

        if (value == 1) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async displayError(errorText : string | string[], canRetry : boolean): Promise<boolean> {

    let arrErrorText : string[];

    if (Array.isArray(errorText)) {
      arrErrorText = errorText;
    } else {
      arrErrorText = [errorText];
    }

    let buttons = [];

    if (canRetry) {
      buttons.push({text: "Cancel", value: 0},{text:"Retry", value :1});
    } else {
      buttons.push({text: "OK", value: 0});
    }

    return this.createMessage("Workflow Assist Error", arrErrorText, buttons);

  }

  async createMessage (title : string, msgText : string [], buttons : any[]) {

    return new Promise<boolean> ((resolve, reject) => {

      let msgData : IMsgBoxData = {
        title: title, 
        message : msgText,
        buttons : buttons
      };

      this._displayMsgBox.next(msgData);

      let subscription = this.onMsgBoxResult.subscribe(value =>{

        subscription.unsubscribe();

        if (value == 1) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
    });
  }

  async canStartScript () : Promise<boolean> {

    if (this.hasScriptLoaded && (this.isScriptRunning || this.hasActionsOutstanding)) {

      let message : string [];

      if (this.isScriptRunning) {
        message = [ 
          "Workflow Assist is already running a script.", 
          "",  
          "Do you want to quit the running script?",
        ]
      } else {
        message = [ 
          "The current Workflow Assist script still has incomplete actions.", 
          "",  
          "Do you want to quit the current script without completeing all available actions?",
        ]
      }

      message.push(
        "", 
        "Press Yes to start a new script.", 
        "Press No to continue with the currently running script."
      );

      this.activateTab();

      return this.createMessage (
        "Worflow Assist", message,
        [
          {text: "Yes", value: 1}, 
          {text:"No", value: 0}
        ]);
    } else {
      return true;
    }
  }

  setRetryResult(value: any) {
    this._msgBoxResult.next(value);
  }

}