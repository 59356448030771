import { ListEntry } from 'src/app/shared/interfaces/list-entry'

export class EntryList {
  
  public entries: ListEntry[];

  constructor(entries: ListEntry[]) {
    this.entries = entries;
  }

  count(): number {

    var result: number = 0;

    this.entries.forEach(entry => {
      if (entry.val) {
        result++;
      } else {
        entry.mem.forEach(subEntry => {
          result++;
        });
      }
    });    
    
    return result;
  }

}
