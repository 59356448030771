<h3>{{viewTitle}}</h3>
<div carinaActionItemHost></div>
<div>
  <!-- <button mat-raised-button color="primary" style="margin:10px 10px 0 0;" (click)="reset()">Reset</button>  -->
  <!-- <button mat-raised-button color="primary" style="margin:10px 10px 0 0;" (click)="restart()">Restart</button> -->
  <!-- <button *ngIf="complete" mat-raised-button color="primary" style="margin:10px 10px 0 0;">Save</button>
  <button *ngIf="complete" mat-raised-button color="primary" style="margin:10px 10px 0 0;">Complete Actions</button> -->
</div>

<div *ngIf="waitText.length" class="logText">{{waitText}}</div>


<div *ngIf="consoleText.length" class="consoleLog">{{consoleText}}</div>
