import { EnumValueState } from "../enums/enum-value-state";
import { EnumValueType } from "../enums/enum-value-type";
import { EvalNode } from "./eval-node";
import { ValueBase } from "./value-base";
import { ValueFactory } from "./value-factory";

export class ValueReference extends ValueBase {
  value : any;
  path: string[];
  pathNodes : EnumValueType[];
  dataValue: ValueBase;

  constructor(dataValue: ValueBase, path: string[]) {

    super({name: dataValue.name, type:EnumValueType.Value_object});

    this.path = path;
    this.dataValue = dataValue;

    this.initialiseValue();

  }

  get isArray() : boolean {
    return Array.isArray(this.value);
  }

  get isObjectArray() : boolean {
    return this.isArray && this.value["length"].length > 0 && typeof this.value[Object.keys(this.value)[0]] === "object";
  }

  get isPlural() : boolean {
    return this.isArray ? this.value["length"] > 1 : false;
  }

  get isReference() : boolean {
    return true;
  }

  get isEmpty() : boolean {
    return true;  // TO DO - support references
  }

  cast(newType : EnumValueType) : boolean | string | number {

    let result : boolean | string | number;

    switch (this.type) {
      case EnumValueType.Value_boolean:
      case EnumValueType.Value_number:
      case EnumValueType.Value_string:

        switch (newType) {
          case EnumValueType.Value_boolean:
            result = Boolean(this.value);
          break;   
          case EnumValueType.Value_number:
            result = Number(this.value);
          break;   
          case EnumValueType.Value_string:
            result = String(this.value);
          break; 
        }
      break;   
      case EnumValueType.Value_object:
        switch (newType) {
          case EnumValueType.Value_boolean:
            result = false;
          break;   
          case EnumValueType.Value_number:
            result = 0;
          break;   
          case EnumValueType.Value_string:
            result = "";
          break; 
        }
      break;   
    };

    return result;
  }

  getValue() : any {
    return this.value;
  }

  initialiseValue() : void  {

    let startPath : string;
    let errProp : string; 
    let value = this.dataValue.getValue();
                
    let hasError = !this.path.slice(0, -1).every((propName, index) => {

      if (value.hasOwnProperty(propName)) {
        value = value[propName];
        return true;
      } 

      startPath = this.path.slice(0, index).join(".");
      errProp = propName;
      return false;

    });  

    if (!hasError) {

      let propName = this.path[this.path.length-1];
  
      if (value.hasOwnProperty(propName)) {
        
        this.value = value[propName];                
              
        this.setType();
        this.setState(EnumValueState.FieldState_Value);       
                    
      }

    } else {

      let error :string;  
      if (startPath) {
        error = "Cannot get property '"+ errProp + "' of property '" + startPath + "' of '" + this.dataValue.name + "'";
      } else {
        error = "Cannot get property '"+ errProp + "' of '" + this.dataValue.name + "'";
      }

      this.setError(error, true);
    }
  }

  setType() {
    let type : EnumValueType;

    let valueType = typeof this.value;
  
    switch (valueType) {
      case "boolean":
        type = EnumValueType.Value_boolean;
      break;  
      case "number":
        type = EnumValueType.Value_number;
      break;  
      case "string":
        type = EnumValueType.Value_string;
      break;  
      case "object":
        type = EnumValueType.Value_object;
      break;  
    }

    this.type = type;
  }

  setValue(propValue): void {
    let hasError = false;
    let value : object = this.dataValue.getValue() as object;
    let subValue = value;

    hasError = !this.path.slice(0,-1).every(propName => {
      if (subValue.hasOwnProperty(propName)) {
        subValue = subValue[propName];
        return true;
      } else {
        return false;
      }
    });

    if (!hasError) {
      subValue[this.path[this.path.length-1]] = propValue;

      this.value = propValue;
      this.setType();
      this.setState(EnumValueState.FieldState_Value);

      //this.dataValue.setValue(this.dataValue.getValue());

    } else {
      this.setError ("Could not find property reference "+this.path.join('.'), true);
    }
  }

  assignValue (newValue : ValueBase) {

    //this.value = newValue.cast(this.type) as boolean;
    //super.assignValue(newValue);

  }

  arrayCount() : number {

    let count : number = 0;

    if (this.isArray) {
      count = Object.keys(this.value).length;
    }
    
    return count;
  }  

  arrayLength() : number {

    let length : number = 0;

    if (this.isArray) {
      length = (this.value as any[]).length;
    }
    
    return length;
  }   

  getPathText() : string {
    let result : string = "";

    this.path.forEach(pathValue => {
      if (typeof pathValue === "number") {
        result += '.['+pathValue+']';
      } else {
        result += '.'+pathValue;
      }
    })

    return result;
  }
}
