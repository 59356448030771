<div id="carina-pdf-name" [style.display]="'none'">{{pdfName}}</div>
<ngx-extended-pdf-viewer 
  [src]="pdfSource" 
  [page]="pdfPage" 
  [useBrowserLocale]="true"
  [showFindButton]="true"
  [textLayer]="true"
  (pdfLoaded)="onPdfLoaded($event)"
  (pagesLoaded)="onPagesLoaded($event)"
  (pageRendered)="onPageRendered($event)"
  (updateFindMatchesCount)="onFindMatches($event)"
  (updateFindState)="onFindState($event)"
  (textLayerRendered)="onTextLayerRendered($event)"
>
</ngx-extended-pdf-viewer>