import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'carina-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['../sign-on.scss']
})
export class OfflineComponent {

  constructor(
  ) {
  }
   
}
