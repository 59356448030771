
<ng-container *ngIf="stepData.active" >
  <div #scrollTarget class="assistStep assistRow">
    <div class="stepOffset">
    </div>
    <div class="stepText">
      <div>
        <p>{{stepData.text[0]}}</p>
        <ng-container *ngIf="stepData.text.length > 1" >
          <ul>
            <li *ngFor="let txt of stepData.text.slice(1)">
              {{txt}}
            </li>
          </ul>   
        </ng-container>
      </div>
    </div>
    <div class="stepButtons">
    </div>
  </div>
  <ng-container *ngIf="!hasNext || !stepData.stepCompleted" >
    <div class="assistStep" *ngFor="let entry of stepData.arrEntryText;index as idx">
      <div class="stepOffset">
      </div>
      <ng-container *ngIf="hasBullet" ><div class="stepBullet">&#9679;</div></ng-container>
      <div class="stepText" [style]="entryStyle">
        <ng-container *ngIf="isArray(entry)" >
          <div>
            <span>{{entry[0]}}</span> 
            <ul>
              <li *ngFor="let txt of entry.slice(1)">
                {{txt}}
              </li>
            </ul>
          </div>   
        </ng-container>
        <ng-container *ngIf="!isArray(entry)" >
          <span>{{entry}}</span>
        </ng-container>
      </div>
      <div class="stepButtons">
        <div class="stepButtonEnd">
          <mat-button-toggle-group [(ngModel)]="selected[idx]" name="Select" aria-label="Select entry">
            <mat-button-toggle value="{{stepData.arrEntryValue[idx]}}" class="mat-button" (click)="select(idx)">Select</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>  
    </div> 
    <ng-container *ngIf="hasNext" >  
      <div class="assistStep">
        <div class="stepOffset">
        </div>
        <div class="stepText stepData">
          <span>{{nextText}}</span>
        </div>
        <div class="stepButtons">
          <div class="stepButtonEnd">
            <mat-button-toggle-group *ngIf="!stepData.stepCompleted" name="Next" aria-label="Next">
              <mat-button-toggle class="mat-button" (click)="process()">Next</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="hasNext && stepData.stepCompleted" >  
    <div class="assistStep">
      <div class="stepOffset">
      </div>
      <div class="stepText stepData">
        <p class="stepSelected">{{strSelected}}</p>
      </div>
      <div class="stepButtons">
        <div class="stepButtonEnd">
          <mat-button-toggle-group  *ngIf="stepData.stepCompleted" name="Update" aria-label="Update selection">
            <mat-button-toggle class="mat-button" (click)="update()">Update</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>