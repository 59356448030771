import { Component, OnInit, Input } from '@angular/core';
import { StepText } from '../../classes/step-text';
import { EnumStepType } from '../../enums/enum-step-type';
import { IStepComponent } from '../../interfaces/icomponent-step';
import { StepBaseComponent } from './step-base.component';

@Component({
  selector: 'carina-step-section',
  templateUrl: './step-section.component.html'
})
export class StepSectionComponent  extends StepBaseComponent  implements OnInit, IStepComponent {

  @Input() stepData: StepText;
  level : number = 1;

  constructor() {
    super(); 
  }

  ngOnInit(): void {

    if (this.stepData.type == EnumStepType.Prompt_Section) {
      if (this.stepData.promptConfig && this.stepData.promptConfig.hasOwnProperty("level") && this.stepData.promptConfig.level > 1) {
        this.level = this.stepData.promptConfig.level;  
      }  
    }
    
  }

}