import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewOptionType } from '../../types/view-option-type';

@Component({
  selector: 'carina-resize',
  templateUrl: './control-resize.component.html',
  styles: [
  ]
})
export class ControlResizeComponent implements OnInit {

  @Input() leftOpts: ViewOptionType;
  @Input() rightOpts: ViewOptionType;
  @Input() resizeId : number;
  @Input() resize : number;
  @Output() resizeChange = new EventEmitter<number>();

  constructor() { 
  
  }

  ngOnInit(): void {

  }

  startResize(e : MouseEvent) {
    if (!this.resize) {

      let target = e.target as HTMLElement;

      let rect = target.getBoundingClientRect();

      const y = e.clientY - rect.top;
      // Get the bounding rectangle of target

      if (y > 40) {
        this.resizeChange.emit(this.resizeId);
      } else {

        if (this.resizeId == 1) {
          
        }

        if (y < 20) {

        } else {

        }
      }
    }
  }
}
