import { IDataValue } from "../interfaces/idata-value";
import { EnumValueType } from "../enums/enum-value-type";
import { ValueBase } from "./value-base";

export class ValueNumber extends ValueBase {
  value : number;

  constructor(config: IDataValue) {
    super(config);
    this.value = 0;

    if (config.hasOwnProperty("value")) {
      this.setValue(Number(config.value));
    }
  }

  get isPlural() : boolean {
    return this.value > 1;
  }  

  get isEmpty() : boolean {
    return super.isEmpty || this.value == 0;
  }    

  reset() {
    super.reset();
    this.value = 0;
  }

  cast(newType : EnumValueType) : boolean | string | number {

    let result : boolean | string | number = this.value;

    if (newType == EnumValueType.Value_string) {
      result = String(result);
    } else if (newType == EnumValueType.Value_boolean) {
      result = Boolean(result);
    }

    return result;
  }  

  getValue(): number {
    return this.value;
  }

  setValue(value: number): void {
    this.value = value;
    super.setValue(value);
    //this._value.next(this.value);
  }

  assignValue (newValue : ValueBase) {

    this.value = newValue.cast(this.type) as number;
    super.assignValue(newValue);

  }


}
