import { Component } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service'

@Component({
  selector: 'carina-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'carina';
  strLayout = 'default';
  strClass = "Page";

  constructor(
    private appService: AppService
  ) {
    this.appService.pageLayout.subscribe(strLayout => {
      this.strLayout = strLayout;
      if (this.strLayout == 'default') {
        this.strClass = 'Page';
      } else {
        this.strClass = 'WidePage';
      }
    });    
  }
}

