export class Organisation {
  strUrl : string;

  constructor(strUrl : string) {
    this.strUrl = strUrl;
  }

  getUrl () : string {
    return this.strUrl;
  }

  matchOrg (strTestOrg : string) : boolean {
    let result : boolean = false;

    result = strTestOrg === this.getUrl();

    return result;

  }
}
