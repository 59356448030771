import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material/material.module';
import { AssistModule } from './modules/assist/assist.module';
import { PdfModule } from './modules/pdf/pdf.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignOnComponent } from './modules/sign-on/pages/sign-on/sign-on.component';
import { HomeComponent } from './modules/home/pages/home/home.component';
import { ConfigureComponent } from './modules/home/pages/configure/configure.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { ControlBarComponent } from './shared/components/control-bar/control-bar.component';
import { HomeSectionComponent } from './modules/home/components/home-section/home-section.component';
import { OfflineComponent } from './modules/sign-on/pages/offline/offline.component';
//import { SwitchComponent } from './shared/components/switch/switch.component';

@NgModule({
  declarations: [
    AppComponent,
    SignOnComponent,
    OfflineComponent,
    HomeComponent,
    ConfigureComponent,
    HeaderComponent,
    ControlBarComponent,
    HomeSectionComponent
    //SwitchComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    AssistModule,
    PdfModule


  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
