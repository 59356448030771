import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { StepActionItem } from '../../classes/step-action-item';
import { AssistStateService } from '../../services/assist-state.service';

@Component({
  selector: 'carina-action-item',
  templateUrl: './action-item.component.html'
})
export class ActionItemComponent implements OnInit, OnDestroy {

  @Input() stepData: StepActionItem;
  strButtonText : string = "Create";
  actionInProgress : boolean = false;
  systemInProgress : boolean = false;
  allowCancel : boolean = false;

  private _subscriptions : Subscription [] = [];

  constructor(
    private assistStateService: AssistStateService
  ) { }

  ngOnInit(): void {
    let subscription : Subscription = this.assistStateService.systemInProgress.subscribe(value=> {
      this.systemInProgress = value
    });

    this._subscriptions.push(subscription);

    if (this.stepData.actionConfig) {
      if (this.stepData.actionConfig.hasOwnProperty("allowCancel")) {
        this.allowCancel = this.stepData.actionConfig.allowCancel;
      }
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  process(): void {
    this.callProcess();
  }

  async callProcess() {
    this.actionInProgress = true;
    this.assistStateService.setSystemInProgress(true);
    await this.stepData.processAction();
    this.assistStateService.setSystemInProgress(false);
    this.actionInProgress = false;
  }

}
