import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../shared/services/auth.service';
import { AppService } from '../../../../shared/services/app.service';
import { Subscription, Subject, Observable, BehaviorSubject} from 'rxjs';
import { PdfLoadedEvent, PagesLoadedEvent, NgxExtendedPdfViewerService, FindOptions, FindResultMatchesCount, FindState, PageRenderedEvent, TextLayerRenderedEvent } from 'ngx-extended-pdf-viewer';
import { MessagePoster } from 'src/app/modules/assist/classes/message-poster';
import { AssistStateService } from 'src/app/modules/assist/services/assist-state.service';

interface IPageInfo {
  page: number;
  lineNums : boolean;
  wordCount : number;
}

@Component({
  selector: 'carina-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit, OnDestroy {   

  private _subscriptions: Subscription[] = [];

  private _infoComplete: BehaviorSubject<number> = new BehaviorSubject <number>(1);
  public readonly onInfoComplete:Observable<number> = this._infoComplete.asObservable();  

  private docText : string = "";
  private docLineCount : number = 0;

  private pdfParams = {page: 1, name : ""};

  public pdfSource : ArrayBufferLike;
  
  public pdfPage : number;
  public pdfName : string;

  private messagePoster = new MessagePoster();
  private titlePrefix : string = "PDF Viewer";
  private isLoading = false;
  private isFinding = false;
  private findParams = {reset: true};

  private pageInfo = [];
  
  constructor(
    private route: ActivatedRoute,
    private pdfViewerService: NgxExtendedPdfViewerService,
    private authService: AuthService,
    private appService: AppService
  )
  { }

  ngOnInit(): void {

    this._subscriptions.push (
      this.route.params.subscribe(params => { 
        this.appService.setPageLayout("wide");      
        this.setPageTitle("");
        if (this.authService.hasUser()) {
          this.initPage();
        }
      })
    ); 
  
    window.onerror = function (e) {
      alert("unhandled error: " + e);
    };
  
    window.addEventListener('unhandledrejection', (event) => {
      console.error('Carina here (promise: ', event.promise, ', reason: ', event.reason, ').');
      if (this.isLoading && event && event.reason && event.reason.name && event.reason.name == 'InvalidPDFException') {
        this.isLoading = false;
        this.messagePoster.postMessage("fromAppPdfActionComplete", {action:"open", result: false});
      }  

    });   
        
  }

  ngOnDestroy() : void {
    this.messagePoster.postMessage('fromAppPdfClose', {});
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setPageTitle (name :string) {
    
    let title : string = this.titlePrefix
    
    if (name != "") {
      title = title + " - " + name;
    }

    this.appService.setPageTitle(title);
  }

  initPage(): void {

    console.log("pdf initPage");

    this.addEventListeners();

    this.messagePoster.postMessage('fromAppPdfOpen', {});  

  }

  onPdfLoaded(event: PdfLoadedEvent) {

    console.log("document loaded - pages:", event.pagesCount);
  
    if (event.pagesCount > 0) {
      //this.messagePoster.postMessage("fromAppPdfActionComplete", {action:"open", result: true});
    } 
  }

  onPageRendered(event: PageRenderedEvent) {

    console.log("isLoading: " + this.isLoading + " page rendered:", event.pageNumber);
  
    if (this.isLoading && event.pageNumber > 0) {
      this.isLoading = false;
      this.messagePoster.postMessage("fromAppPdfActionComplete", {action:"open", result: true});
    } 

  }

  onTextLayerRendered(event: TextLayerRenderedEvent): void {

    if (event.source.textLayer &&  event.source.textLayer.textDivs) {
      this.pageRendered({page: event.pageNumber, pageDiv: event.source.textLayer.div ,textDivs: event.source.textLayer.textDivs})
    }
  }

  public onPagesLoaded(event: PagesLoadedEvent): void {
    console.log("document loaded - pages:", event.pagesCount);
  }

  onFindMatches (event : FindResultMatchesCount) {
    
    console.log("isFinding: " + this.isFinding + " matches: " + JSON.stringify(event));

    if (this.isFinding && event.matches != undefined && event.matches[0].length > 1) {
      this.messagePoster.postMessage("fromAppPdfActionComplete", {action:"find", result: true});
      if (this.findParams.reset) {
        this.pdfViewerService.find("");
      }
      this.isFinding = false;
    }

    //this.messagePoster.postMessage("fromAppPdfActionComplete", {action:"find", result: true});
    
  }  

  onFindState (event : FindState ) {

    console.log("isFinding: " + this.isFinding + " findState: " + event);

    if (this.isFinding && event != FindState.PENDING && event != FindState.WRAPPED) {
      this.messagePoster.postMessage("fromAppPdfActionComplete", {action:"find", result: event == FindState.FOUND});
      if (this.findParams.reset) {
        this.pdfViewerService.find("");
      }
      this.isFinding = false;
    }    

  }

  pdfLoad(data) {

    function base64ToArrayBuffer(base64) {
      var binaryString = atob(base64);
      var bytes = new Uint8Array(binaryString.length);
      for (var i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }

    this.pageInfo = [];
    this.docText = "";
    this.docLineCount = 0;

    if (data.params) {
      if (data.params.pdfName) {
        this.pdfParams.name = data.params.pdfName;

        this.setPageTitle(data.params.pdfName);
      }
      if (data.params.pdfPage) {
        this.pdfParams.page = data.params.pdfPage;
      } else {
        this.pdfParams.page = 1;      
      }

      this.pdfPage = this.pdfParams.page;
      this.pdfName = this.pdfParams.name;
    }

    try {
      this.isLoading = true;
      this.pdfSource = base64ToArrayBuffer(data.data);
    }
    catch(err) {
      this.messagePoster.postMessage("fromAppPdfActionComplete", {action:"open", result: false});
    }
  }
  
  pdfFind(searchText, params) {

    let opts : FindOptions = {};
    //opts.highlightAll = true;

    if (params.hasOwnProperty("reset")) {
      this.findParams.reset = params.reset;
    } else {
      this.findParams.reset = true;
    }

    this.isFinding = true;
    this.pdfViewerService.find(searchText, opts);

  }

  pageRendered(info) {

    let dets = {pageText: "", lineNums: false};

    if (this.pageInfo[info.page] == undefined) {

      if (info.page > 1) {
        
        let lineTop = -1;
  
        let lineCount = 0;
        let lineNum = 1;
        let pageText = "";
        let pageDiv = info.pageDiv;
  
        let pageHeight = pageDiv.offsetHeight;
  
        let maxTop = pageHeight - 50;
        let textDivs = info.textDivs;
  
        textDivs.forEach(div => {
  
          let text = div.innerText.trim();
          let top = div.offsetTop;
  
          if (top > 50 && top < maxTop) {
            if (text == ""+lineNum) {
              lineNum++;
            } else {
  
              if (top > lineTop) {
                lineTop = top;
                lineCount++;
              }
  
              if (text.length > 0) {
                pageText = pageText + ' ' + text;
              }
            }
          } else {
            console.log("margin Text: " + text);
          }
  
        });
  
        if (lineNum > 10) {
          dets.lineNums = true;
        }
  
        dets.pageText = pageText;
  
      }      

      this.pageInfo[info.page] = dets; 
      this._infoComplete.next(info.page);
    }
    
  }

  async pdfInfo(data : any) {

    let maxPage = this.pdfViewerService.numberOfPages();
    let curPage = 1;
    let docText = "";
    let docLineCount = 0;
    let findResult = [];

    while (curPage <= maxPage) {

      let pageDets = await this.getPageInfo(curPage) as any

      if (pageDets.lineNums) {
        docLineCount ++;
      } 

      docText = docText + pageDets.pageText;

      curPage++;
    }

    let wordCount = docText.split(' ').filter(txt => txt !='').length;

    if (data?.pageCount) {
      data.pageCount = maxPage; 
    } 

    if (data?.wordCount) {
      data.wordCount =  wordCount;
    } 

    if (data?.lineNums) {
      data.lineNums = docLineCount;
    } 

    if (data?.find && Array.isArray(data.find)) {

      data.find.forEach((entry) => {
        
        let flags = "g";
        let terms = [];
        
        if (!entry?.case) {
          flags = "i" + flags;
        }

        if (entry?.terms && Array.isArray(entry.terms)) {
          terms = entry.terms;
        }

        let found = terms.find(term =>{

          let regex = new RegExp(term, flags);

          return (docText.search(regex) > -1);

        });

        if (found != undefined) {
          findResult.push({found: true, term : found});
        } else {
          findResult.push({found: false, term : ""});
        }

      });

      data.find = findResult;
      data.result = true;

    }

    this.messagePoster.postMessage("fromAppPdfActionComplete", {action:"info", error: "", result: true, info: data});
  }

  
  getPageInfo(newPage) {

    return new Promise((resolve, reject) => {

      if (this.pageInfo[newPage] !== undefined) {

        resolve(this.pageInfo[newPage])

      } else {

        let subscription = 
          this.onInfoComplete.subscribe(pageNum => {
    
            if (pageNum == newPage) {
              subscription.unsubscribe();
              resolve(this.pageInfo[newPage]);
            }
          });

        this.pdfPage = newPage;  
      }
    }) 
  }

    // 




    //     console.log("onInfoComplete: "+ pageInfo.page);

    //     // if (pageNum < maxPage) {
    //     //   this.pdfPage = pageNum + 1;
    //     // } else {

    //     //   let wordCount = this.docText.split(' ').length;        

    //     //   if (data?.pageCount) {
    //     //     data.pageCount = maxPage;
    //     //   } 
      
    //     //   if (data?.wordCount) {
    //     //     data.pageCount = wordCount;
    //     //   } 

    //     //   if (data?.lineNums) {
    //     //     data.lineNums = this.docLineCount;
    //     //   } 

    //     //   this.messagePoster.postMessage("fromAppPdfActionComplete", {action:"info", result: true, info: data});

    //     //   subscription.unsubscribe();
    //     // }

    //   })
    // ) 
    
    // if (data?.pageCount) {
    //   data.pageCount = maxPage; 
    // } 

    // if (data?.wordCount) {
    //   data.pageCount = 10 // wordCount;
    // } 

    // if (data?.lineNums) {
    //   data.lineNums = 5 // this.docLineCount;
    // } 

    // this.messagePoster.postMessage("fromAppPdfActionComplete", {action:"info", result: true, info: data});




  //}

  addEventListeners() {
    window.addEventListener("message", (event) => {

      console.log("pdf listener:" + event.data?.type);

      // We only accept messages from ourselves
      if (event.source != window) {
        return;
      }

      var type = event.data?.type;
      var data = event.data?.data;

      if (type) {

        switch (type) {
          case 'toAppPdfLoad':
            this.pdfLoad(data); 
          break;
          case 'toAppPdfFind':
            this.pdfFind(data.searchText, data.params);
          break;
          case 'toAppPdfInfo':
            this.pdfInfo(data.data);
          break;
        }
      }
    });    
  }


}
