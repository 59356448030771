import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service'
import { AppService } from 'src/app/shared/services/app.service'
import { User } from 'src/app/shared/classes/user';
import { HeaderButton } from 'src/app/shared/classes/header-button';

@Component({
  selector: 'carina-configure',
  templateUrl: './configure.component.html',
  styleUrls: ['../../home.scss']
})
export class ConfigureComponent implements OnInit {

  user : User;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.appService.setPageTitle("Configure Carina");
      if (this.authService.hasUser()) {
        this.initPage();
      }
    });
  }

  initPage() : void {
    this.user = this.authService.getUser();

    let buttons : HeaderButton[] = [];

    buttons.push(new HeaderButton("hdrbtnHome", "home", "Home", false, "/home"));

    this.appService.setHeaderButtons(buttons);

  }

  onMaintenance(strUrl : string) { 
    this.authService.location('maint_object_list.php?Mode='+strUrl);
  } 
}
