import { EnumValueType } from "../enums/enum-value-type";
import { IDataValue } from "../interfaces/idata-value";
import { ValueBase } from "./value-base";

export class ValueBoolean extends ValueBase {
  value : boolean;

  constructor(config: IDataValue) {
    super(config);
    this.value = false;

    if (config.hasOwnProperty("value")) {
      this.setValue(Boolean(config.value));
    }
  }

  get isEmpty() : boolean {
    return super.isEmpty || !this.value;
  }    

  reset() {
    super.reset();
    this.value = false;
  }

  cast(newType : EnumValueType) : boolean | string | number {

    let result : boolean | string | number = this.value;

    if (newType == EnumValueType.Value_string) {
      result = String(result);
    } else if (newType == EnumValueType.Value_number) {
      result = Number(result);
    }

    return result;
  }

  getValue() : boolean {
    return this.value;
  }

  setValue(value: boolean): void {
    this.value = value;
    super.setValue(value);
    //this._value.next(this.value);
  }

  assignValue (newValue : ValueBase) {

    this.value = newValue.cast(this.type) as boolean;
    super.assignValue(newValue);

  }

}
