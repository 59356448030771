<div class="carina-theme content">
  <div class="frontPage">
    <img height="100px" src="../../../../assets/images/Carina-Purple-RGB.svg" alt="Carina">
  </div>
  <div class="signonForm">
    <div class="form-signin-heading">
      <h4>Carina is currently unavailable during essential maintenance.</h4>
      <h4>Please try again later.</h4>
    </div>
  </div>
</div>