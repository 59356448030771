
import { IStepActionEntry } from '../interfaces/istep-action-entry';
import { IStepEval } from '../interfaces/istep-eval';
import { IStepPrompt } from '../interfaces/istep-prompt';
import { IStepText } from '../interfaces/istep-text';
import { StepBase } from './step-base';
import { StepPrompt } from './step-prompt';
import { StepText } from './step-text';
import { StepEvalBranch } from './step-eval-branch';
import { StepActionEntry } from './step-action-entry';
import { StepEvalSet } from './step-eval-set';
import { StepEvalProc } from './step-eval-proc';
import { EnumStepType } from '../enums/enum-step-type';
import { StepEvalDeclare } from './step-eval-declare';
import { StepActionItem } from './step-action-item';
import { IStepActionItem } from '../interfaces/istep-action-item';
import { StepValueItem } from './step-value-item';
import { IStepValueItem } from '../interfaces/istep-value-item';
import { StepPromptMulti } from './step-prompt-multi';
import { StepActionEntryInc } from './step-action-entry-inc';
import { IStepActionEntryInc } from '../interfaces/istep-action-entry-inc';

export class StepFactory {

  private constructor() { }

  static createStep (index: number, config: IStepPrompt | IStepEval | IStepActionEntry, defaultName : string) : StepBase {

    let result: StepBase = null;

    switch (config.type) {
      case EnumStepType.Prompt_Text:
      case EnumStepType.Prompt_Section:
      case EnumStepType.Prompt_SubSec:
        result = new StepText(index, config as IStepText);
      break;
      case EnumStepType.Prompt_YN:
      case EnumStepType.Prompt_Next:
      case EnumStepType.Prompt_Entry:
      case EnumStepType.Prompt_List:
      case EnumStepType.Prompt_Toggle:
        result = new StepPrompt(index, config as IStepPrompt, defaultName);
      break; 
      case EnumStepType.Prompt_Multi:
      case EnumStepType.Prompt_Single:
      case EnumStepType.Prompt_Radio:
      case EnumStepType.Prompt_Checkbox:
      case EnumStepType.Prompt_Flags:
        result = new StepPromptMulti(index, config as IStepPrompt, defaultName);
      break;  
      case EnumStepType.Eval_Declare:
        result = new StepEvalDeclare(index, config as IStepEval);
      break;
      case EnumStepType.Eval_Proc:
        result = new StepEvalProc(index, config as IStepEval);
      break;
      case EnumStepType.Eval_Branch:
      case EnumStepType.Eval_While:
      case EnumStepType.Eval_Group:
        result = new StepEvalBranch(index, config as IStepEval, defaultName);
      break;
      case EnumStepType.Eval_Set:
        result = new StepEvalSet(index, config as IStepEval, defaultName);
      break;
      case EnumStepType.Value_Item:
        result = new StepValueItem(index, config as IStepValueItem);
      break;
      case EnumStepType.Action_Item:
        result = new StepActionItem(index, config as IStepActionItem);
      break;
      case EnumStepType.Action_Entry:
      case EnumStepType.Action_Entry_Count:
        result = new StepActionEntry(index, config as IStepActionEntry);
      break;  
      case EnumStepType.Action_Entry_Inc:
        result = new StepActionEntryInc(index, config as IStepActionEntryInc);
      break;  
    }

    return result;
  }

}

