<section *ngIf="iCount">
  <h2>{{strTitle}}</h2>
  <div class="sectionBody">
    <div *ngFor="let entry of entryList.entries" class="sectionEntry">
      <button *ngIf="entry.val" [color]="strColour" class="linkButton" mat-raised-button (click)="select(entry.val)">{{entry.txt}}</button>
      <div class="linkSection" *ngIf="!entry.val">
        <h3 *ngIf="entry.txt" class="entryTitle">{{entry.txt}}</h3>
        <div class="entryBody">
          <button [color]="strColour" class="linkButton" mat-raised-button *ngFor="let subentry of entry.mem"
            (click)="select(subentry.val)">{{subentry.txt}}</button>
        </div>
      </div>
    </div>
  </div>
</section>