import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute} from '@angular/router';
import { CookieService } from 'ngx-cookie-service'
import { AuthService } from 'src/app/shared/services/auth.service'
import { AppService } from 'src/app/shared/services/app.service'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'carina-sign-on',
  templateUrl: './sign-on.component.html',
  styleUrls: ['../sign-on.scss']
})
export class SignOnComponent implements OnInit {

  signOnForm = this.builder.group({
    userName : [''],
    password : ['']
  })

  strError: string;
  strPage: string;
  bDemo: boolean;
  bHide: boolean;
  strVersion : string = environment.version;
  
  strAnounce1 : string = "";
  strAnounce2 : string = "";
  
  //strLink : string = "/edoff/signon.php";
  strLink : string = "#";

  constructor(
    private route: ActivatedRoute,
    private builder: FormBuilder,
    private cookies: CookieService,
    private authService: AuthService,
    private appService: AppService
  ) { 
    this.strPage = "";
    this.bDemo = false;
    this.strError = "";
    this.bHide = true;

    authService.currentOrg.subscribe(org => {
      // if (org.strUrl == "edoff" || org.strUrl == "iop") {
      //   this.strAnounce1 = 
      //     "The TaskAdept Portal and Workflow Assist is unavailable on Saturday 29th June 2024 from 10:00 GMT (11:00 BST) for up to 36 hours for essential maintenance.";
      //   this.strAnounce2 = 
      //     "If you are not using Workflow Assist, you may continue to work as normal. "+
      //     "During the maintenance period your task information is stored by your browser. "+
      //     "Once the maintenance is complete your task information will be sent to the portal when you next use your browser.";
      // } else {
      //   this.strAnounce1 = 
      //     "The TaskAdept Portal is unavailable on Saturday 29th June 2024 from 10:00 GMT (11:00 BST) for up to 36 hours for essential maintenance.";
      //   this.strAnounce2 = 
      //     "You may continue to work as normal. "+
      //     "During the maintenance period your task information is stored by your browser. "+
      //     "Once the maintenance is complete your task information will be sent to the portal when you next use your browser.";
      // }
    }); 

  }

  ngOnInit(): void {
    this.authService.getVersion().subscribe(reload => {
      if (reload) {
        location.reload();
      }
    });

    this.appService.setPageTitle("");
    this.authService.signOff().subscribe();
    this.route.queryParamMap.subscribe(params => {
      this.strPage = params.get('page') || '';
      this.appService.setDemo(params.get('demo') !== null);
    })

   
  }

  onSignOn() { 
    
    if (this.signOnForm.valid) {
      this.strError = "";

      this.authService.validateUser(this.signOnForm.value.userName, this.signOnForm.value.password).subscribe({
        next: 
          (result) => {
            if (result) {

              this.cookies.set("carinaworkflow", "set", 366);

              if (this.strPage) {
                this.authService.setLocation(this.strPage);
              } else {
                this.authService.navigate('/home');
              }
            }
          },
        error :    
          (strError) => {
            this.strError = strError
          }
      })
      
    }
  }

}
