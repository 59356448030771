import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { AuthService } from '../../services/auth.service'
import { AppService } from '../../services/app.service'
import { Organisation } from '../../classes/organisation'
import { User } from '../../classes/user';
import { HeaderButton } from '../../classes/header-button';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'carina-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  org : Organisation;
  user : User;
  bDemo : boolean;
  strFullName : string;
  strBannerSrc : string;
  strPageTitle : string;
  arrHeaderButtons: HeaderButton[];
  bInProgress : boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private appService: AppService
  ) {

    this.bDemo = false;

    this.activatedRoute.queryParams.subscribe(params => {
      //let date = params['demo'];
      //console.log(date); // Print the parameter to the console. 
    });

    authService.currentUser.subscribe(user => {
      this.user = user;
      if (this.user) {
        this.strFullName = this.user.fullName;
      } else {
        this.strFullName = "";
      }
    });

    authService.currentOrg.subscribe(org => {
      this.org = org;
      if (this.org) {
        let strOrg = this.org.getUrl();
        this.strBannerSrc = 'assets/images/' + strOrg + '/' + strOrg + '_banner.jpg?' + environment.version;
      } else {
        this.strBannerSrc = "";
      }
    });

    appService.bDemo.subscribe(bFlag => {
      this.bDemo = bFlag;
    });

    appService.pageTitle.subscribe(title => {
      this.strPageTitle = title;
    });

    appService.inProgress.subscribe(flag => {
      this.bInProgress = flag;
    });

    appService.headerButtons.subscribe(buttons => {
      this.arrHeaderButtons = buttons;
    });
    
    this.strFullName = "";
    this.strPageTitle = "";
    this.bInProgress = false;
    this.arrHeaderButtons = [];

  }

  ngOnInit(): void {

  }

  hasUser() : boolean { 
    return this.user !== null;
  }

  hasMaint(): boolean {
    return this.user && this.user.hasPermission('MTAL');
  }

  onUser() { 
    this.authService.navigate('/signon');
  }

  onSettings () { 
    this.authService.navigate('/configure');
  }

  onHome (){ 
    this.authService.navigate('/home');
  }

  onHeaderButton(index: number) {
    if (index < this.arrHeaderButtons.length) {
      let btn : HeaderButton = this.arrHeaderButtons[index];

      if (btn.navigate) {
        this.authService.navigate(btn.navigate);
      } else {

        this.appService.headerButtonPress(btn);
      }
    }
  }


}
