import { Component, OnInit, Input, AfterViewChecked} from '@angular/core';
import { IStepComponent } from '../../interfaces/icomponent-step';
import { PromptMultiComponent } from './prompt-multi.component'

@Component({
  selector: 'carina-step-bg',
  templateUrl: './prompt-flagGroup.component.html'
})
export class PromptFlagGroupComponent extends PromptMultiComponent implements OnInit, IStepComponent {

  constructor( ) {
    super();
  }
}
