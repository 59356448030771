import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { StepBase } from '../../classes/step-base';

@Component({
  template: '',
  styleUrls: []
})
export class StepBaseComponent {

  private scrollTarget:ElementRef;
  public groupOptions = {indent:0};
  public indent : string = "0";

  @ViewChild('scrollTarget') set viewActive(view : ElementRef) {
    if (view) {
      view.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  constructor() { }

  isArray(entry: any) : boolean {
    return Array.isArray(entry);
  }  


  getGroupOptions(step: StepBase) {

    Object.assign(this.groupOptions, step.getGroupOptions());

    if (this.groupOptions.hasOwnProperty("indent")) {
      this.indent = "" + this.groupOptions.indent;
    }

  }

  onAssistUpdate() : void {};

}
