import { Component, OnInit, OnDestroy, Input, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { Subscription } from 'rxjs';
import { StepActionEntry } from '../../classes/step-action-entry';
import { AssistConfig } from '../../classes/assist-config';
import { ActionItemHostDirective } from '../../directives/action-item-host.directive';
import { EnumEventType } from '../../enums/enum-event-type';
import { AssistService } from '../../services/assist.service';
import { ActionItemComponent } from '../actions/action-item.component';
import { StepActionItem } from '../../classes/step-action-item';
import { AssistValues } from '../../classes/assist-values';
import { StepBase } from '../../classes/step-base';
import { EnumStepType } from '../../enums/enum-step-type';
import { StepActionEntryInc } from '../../classes/step-action-entry-inc';
import { AssistStateService } from '../../services/assist-state.service';
import { IMsgBoxData } from '../../interfaces/imsg-box-data';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CarinaMsgBox } from '../dialogs/dialog-msg-box'
import { EventData } from '../../classes/event-data';

@Component({
  selector: 'carina-assist-action-view',
  templateUrl: './assist-action-view.component.html'
})
export class AssistActionViewComponent implements OnInit, OnDestroy {

  @Input() assistConfig: AssistConfig;
  @ViewChild(ActionItemHostDirective, { static: true }) actionItemHost: ActionItemHostDirective;
  
  complete: boolean = false;
  consoleText: string = "";
  waitText : string  = "";
  waitData : any = null;
  waitTimeout : number = 0;

  private actionItems = new Map<string, StepActionItem>();
  private assistValues : AssistValues;
  private _subscriptions: Subscription[] = [];
  private _branchSubscription: Subscription = null;
  private waitDialogRef : MatDialogRef<CarinaMsgBox, any> = null;
  public viewTitle : string = "Manuscript Actions";

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    public dialog: MatDialog,
    private assistService : AssistService,
    private assistStateService: AssistStateService
  ) {
    this.assistValues = AssistValues.getInstance();
  }

  ngOnInit(): void {

    this._subscriptions.push (
      this.assistService.startWorkflow.subscribe((workflowId) => {
        this.startWorkflow();
       })
    );

    this._subscriptions.push (
      this.assistService.resetWorkflow.subscribe((workflowId) => {
        this.resetWorkflow();
       })
    );

    this._subscriptions.push (
      this.assistValues.onStepCreated.subscribe((stepData) => {
        this.handleStepCreated(stepData);
       })
    );

    this._subscriptions.push (
      this.assistStateService.onRuntimeError.subscribe(text => this.appendConsole(text))
    )   

    this._subscriptions.push (
      this.assistStateService.onConsoleLog.subscribe(text => this.appendConsole(text))
    )   
    
    this._subscriptions.push (
      this.assistStateService.onAssistWait.subscribe(data => this.handleWaitEvent(data))
    )   

    this._subscriptions.push (
      this.assistStateService.onDisplayMsgBox.subscribe(data => this.handleRetryEvent(data))
    )   

    this._subscriptions.push (
      this.assistStateService.onAssistReset.subscribe(data => this.handleAssistReset())
    )   

    this._subscriptions.push (
      this.assistStateService.onLayout.subscribe(options => { 
        this.setLayoutOptions(options);
      })
    )    
  }
  
  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this.closeWaitDialog();
    this.clearWaitTimeout();
  }

  get hasConsoleLog () : boolean {
    return this.consoleText.length > 0;
  }
  
  setLayoutOptions(options: any) {
    if (options.hasOwnProperty("actionView") && options.actionView.hasOwnProperty("title")) {
      this.viewTitle = options.actionView.title;
    }
  }

  appendConsole(text) {
    this.consoleText += text + "\n"
  }

  startWorkflow() {
    if (this.assistConfig !== null) {
      this._branchSubscription = 
        this.assistConfig.branch.branchEvent.subscribe((event) => {
          this.handleBranchEvent(event);
        }
      );
    }
  }  

  resetWorkflow() {
    if (this._branchSubscription) {
      this._branchSubscription.unsubscribe();
      this._branchSubscription = null;
    }
    const viewContainerRef = this.actionItemHost.viewContainerRef;
    viewContainerRef.clear();

    this.complete = false;
    this.consoleText = "";
  }  

  handleStepCreated (stepData : StepBase) {

    switch (stepData.type) {
      case EnumStepType.Action_Item:
        this.createActionItem(stepData as StepActionItem);
      break;
      case EnumStepType.Action_Entry:
      case EnumStepType.Action_Entry_Count:
        this.createActionEntry(stepData as StepActionEntry);
      break;  
      case EnumStepType.Action_Entry_Inc:
        this.createActionEntryInc(stepData as StepActionEntryInc);
      break;  
    }

  }

  createActionItem(stepData : StepActionItem) {

    const viewContainerRef = this.actionItemHost.viewContainerRef;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ActionItemComponent);

    const componentRef = viewContainerRef.createComponent<ActionItemComponent>(componentFactory);
    componentRef.instance.stepData = stepData;

    this.actionItems.set(stepData.name, stepData);

    this._subscriptions.push (
      stepData.stepEvent.subscribe(event => this.handleActionEvent(event))
    )  

  }

  createActionEntry(stepData : StepActionEntry) {

    let actionItem : StepActionItem = this.actionItems.get(stepData.actionName);

    if (actionItem) {
      actionItem.addEntry(stepData);
    }
  }

  createActionEntryInc(stepData : StepActionEntryInc) {

    let actionItem : StepActionItem = this.actionItems.get(stepData.actionName);

    if (actionItem) {
      actionItem.addEntryInc(stepData);
    }
  }

  handleBranchEvent (event) {

    let complete = this.complete;
    if (event.type == EnumEventType.branchComplete) {
      complete = true;
      this.findActionsOutstanding();
      this.assistStateService.setScriptRunning(false);
    } else if (event.type == EnumEventType.branchIncomplete) {
      complete = false;
      this.assistStateService.setScriptRunning(true);
      this.assistStateService.setActionsOutstanding(false);
    }

    if (complete !== this.complete) {
      this.complete = complete;
    }
  }

  handleActionEvent(event: EventData) {
    
    if (event.type == EnumEventType.actionCompleted) {
      this.findActionsOutstanding();
    }  
  }
  
  findActionsOutstanding() {
    let actionsTodo : boolean = [...this.actionItems.values()].some(stepData => stepData.isOutstanding);

    this.assistStateService.setActionsOutstanding(actionsTodo);
  }

  handleWaitEvent (data : any) {

    if (data.action == "start") {

      if (data.type == "page") {

        this.clearWaitTimeout();

        let waitText : string = "";

        if (data.desc) {
          waitText = "Waiting for '" + data.desc + "' to load.";
        } else {
          waitText = "Waiting for page to load.";
        }

        this.waitTimeout = setTimeout(()=>{

          let msgData : IMsgBoxData = {
            title: "Workflow Assist Waiting", 
            message : [waitText],
            buttons : []
          };

          this.waitDialogRef = this.dialog.open(CarinaMsgBox, {width:"400px", disableClose: true, hasBackdrop: false, position:{bottom:"10px", right:"10px"}, data:msgData});

        }, 5000) as any;

        console.log (JSON.stringify(data));

      } else {
        if (data.desc) {
          this.waitData = data;

          let msgData : IMsgBoxData = {
            title: "Workflow Assist Waiting", 
            message : [data.desc],
            buttons : [{text: "Cancel", value: 0}]
          };

          this.waitDialogRef = this.dialog.open(CarinaMsgBox, {width:"400px", disableClose: true, hasBackdrop: false, position:{bottom:"10px", right:"10px"}, data:msgData});

          this.waitDialogRef.afterClosed().subscribe(result => {
            if (this.waitData !== null) {
              this.waitData.cancel = true;
            }
            this.assistStateService.setWaitResult(this.waitData);
            //this.waitDialogRef = null;
          });          

        } else {
          this.waitText += "Wating for script action to complete (" + data.callId + ")";
        }  
      }
    } else {
      console.log (JSON.stringify(data));

      this.waitText = "";

      this.closeWaitDialog();
      this.clearWaitTimeout();
    }
  }

  closeWaitDialog() {

    if (this.waitDialogRef) {
      this.waitDialogRef.close();
    }
    this.waitData = null;
    this.waitDialogRef = null;
  }

  clearWaitTimeout() {

    if (this.waitTimeout) {
      clearTimeout(this.waitTimeout);
      this.waitTimeout = 0;
    }  
  }

  handleRetryEvent (msgData : IMsgBoxData) {

    let dialogRef = this.dialog.open(CarinaMsgBox, {width:"400px", disableClose: true, data:msgData});

    dialogRef.afterClosed().subscribe(result => {
      this.assistStateService.setRetryResult(result);
    });
  }

  handleAssistReset () {

    if (this.waitData !== null &&  this.waitDialogRef !== null) {
      this.waitData.cancel = true;
      this.assistStateService.setWaitResult(this.waitData);
    }
    this.assistStateService.assistWait({action:"end"});

  }

  restart () {
    this.complete = false;
    this.consoleText = "";
    this.assistService.restart();
  }

  reset () {
    this.complete = false;
    this.consoleText = "";
    this.assistService.reset();
  }
}
