
<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="valueLabel">{{stepData.label}}</span>
      <div *ngIf="isPending">
        <div class="valuePendingLarge"><img src="/assets/images/CarinaInprogress_32x32.gif"></div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div *ngIf="isValue">
    <mat-table #viewTable [dataSource]="dataSource" multiTemplateDataRows>

      <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
        <mat-header-cell *matHeaderCellDef [style]="column.style">{{column.title}}</mat-header-cell>
        <ng-container *ngIf="column.type=='text'" class="valueTableCell">
          <mat-cell *matCellDef="let entry" [style]="column.style">{{entry[column.name]}}</mat-cell>
        </ng-container>
        <ng-container *ngIf="column.type=='trueOk'" class="valueTableCell">
          <mat-cell *matCellDef="let entry" [style]="column.style"><div [ngClass]="entry[column.name] ? 'stepIcon iconGreenTick': 'stepIcon iconRedCross'"></div></mat-cell>
        </ng-container>          
        <ng-container *ngIf="column.type=='icon'" class="valueTableCell">
          <mat-cell *matCellDef="let entry; let i = index" [style]="column.style"><div (click)="process(column.click, entry[column.name], i, entry)" [ngClass]="'stepIcon ' + column.icon"></div></mat-cell>
        </ng-container>
        <ng-container *ngIf="column.type=='trueShow'" class="valueTableCell"> 
          <mat-cell *matCellDef="let entry" [style]="column.style"><div [ngClass]="entry[column.name] ? 'stepIcon iconGreenTick': 'stepIcon iconGreyCross'"></div></mat-cell>
        </ng-container> 
        <ng-container *ngIf="column.type=='trueBad'" class="valueTableCell"> 
          <mat-cell *matCellDef="let entry" [style]="column.style" class="valueTableCell">
            <div [ngClass]="{'stepIcon' : true, 'iconGreyTick': !entry[column.name], 'iconRedCross': entry[column.name] == 1, 'iconOrangeWarn': entry[column.name] >1}"></div>
          </mat-cell>
        </ng-container>
        <ng-container *ngIf="column.type=='iconSel'" class="valueTableCell"> 
          <mat-cell *matCellDef="let entry" [style]="column.style" class="valueTableCell">
            <div [ngClass]="{'stepIcon' : entry[column.name]>=0, 'iconGreyTick': entry[column.name]==0, 'iconGreenTick': entry[column.name]==1, 'iconRedCross': entry[column.name]==2, 'iconOrangeWarn': entry[column.name]==3}"></div>
          </mat-cell>
        </ng-container>                           
        <ng-container *ngIf="column.type=='trueOnly'" class="valueTableCell">
          <mat-cell *matCellDef="let entry" [style]="column.style"><div [ngClass]="entry[column.name] ? 'stepIcon iconGreenTick': ''"></div></mat-cell> 
        </ng-container>                  
        <ng-container *ngIf="column.type=='image'" class="valueTableCell">
          <mat-cell *matCellDef="let entry" [style]="column.style"><img [src]="entry[column.name]?column.src:''" [style]="entry[column.name]?column.imgStyle:'display:none'" [title]="entry[column.name]?column.alt:''"></mat-cell> 
        </ng-container>                  
      </ng-container>
    
      <mat-header-row *matHeaderRowDef="showColumns" class="valueTableHeader"></mat-header-row>
      <mat-row *matRowDef="let row; columns: showColumns;" class="valueTableRow"></mat-row>
      <mat-row *matRowDef="let row; columns: secondaryColumns; when showSecondary" class="valueTableSecRow"></mat-row>
    </mat-table>
    <mat-paginator *ngIf="showPaginator" #viewPaginator 
      [pageSizeOptions]="pagination.pageSizeOptions"
      [pageSize]="pagination.pageSize"
      [showFirstLastButtons]="pagination.showFirstLastButtons"
      [hidePageSize]="pagination.hidePageSize"
      [pageIndex]="pagination.pageIndex"       
    >
    </mat-paginator>

  </div>
  <div *ngIf="isPending">
    <div class="valuePendingLarge"><img src="/assets/images/CarinaInprogress_32x32.gif"></div>
  </div>
  <mat-action-row *ngIf="showClip"><div (click)="clip()" [ngClass]="'stepIcon ' + 'iconCopy'"></div></mat-action-row>
</mat-expansion-panel>



<!-- <div class="assistValue"></div> --> 