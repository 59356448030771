
<div class="carina-theme content">
  <div class="frontPage">
    <img height="100px" src="../../../../assets/images/Carina-Purple-RGB.svg" alt="Carina">
  </div>
  <div class="signonForm">
    <div class="form-signin-heading">
      <h4>Please enter your Carina account username and password to sign-on</h4>
    </div>
    <form [formGroup]="signOnForm" (ngSubmit)="onSignOn()">

      <div class="form-group">
        <mat-form-field class="form-element" appearance="outline" style="width:222px">
          <input matInput placeholder="Username" formControlName="userName">
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field class="form-element" appearance="outline">
          <input matInput placeholder="Password" formControlName="password" [type]="bHide ? 'password' : 'text'">
          <mat-icon matSuffix (click)="bHide = !bHide">{{bHide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
      </div>

      <div class="signonButton">
        <button mat-raised-button color="primary" [ngClass]="" type="submit">Sign-on</button>
      </div>

      <div *ngIf="strError" class="form-group ErrorDiv">
        <p class="ErrorHeader">The following error must be addressed to continue:</p>
        <p class="ErrorMessage">- {{strError}}</p>
      </div>
      <div class="form-group versionPanel">
        <span>{{strVersion}}</span>
      </div>      
      <div class="form-group anouncePanel">
        <p style="font-size: 16px;">{{strAnounce1}}</p>
        <p>{{strAnounce2}}</p>
      </div>
    </form>
  </div>
</div>