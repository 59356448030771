import { Component, OnInit, Input } from '@angular/core';
import { StepText } from '../../classes/step-text';
import { IStepComponent } from '../../interfaces/icomponent-step';
import { StepBaseComponent } from './step-base.component';

@Component({
  selector: 'carina-step-text',
  templateUrl: './step-text.component.html'
})
export class StepTextComponent  extends StepBaseComponent implements OnInit, IStepComponent {
  
  @Input() stepData: StepText;

  constructor() { 
    super(); 
  }

  ngOnInit(): void {
  }
 
  onMouseOver() {
    this.stepData.setHighlights(true);
  }

  onMouseOut() {
    this.stepData.setHighlights(false);
  }

}
 