<div class='assistPage'>
  <div class='assistContent' (mousemove)="doResize($event)" (mouseup)="endResize()">
    <div *ngIf="stepViewOptions.enable" id="step" class='assistColumn' [style.flex]="stepViewOptions.width">
      <div class="assistContainer">
        <div class="assistSection">
          <div class="assistInner">        
            <carina-assist-step-view [assistConfig]="config"></carina-assist-step-view>
          </div>
        </div>
      </div>
    </div>
    <carina-resize style="display:flex" resizeId = "1" [(resize)]="isResizing" [leftOpts]="stepViewOptions" rightOpts="dataViewOptions"></carina-resize>
    <div *ngIf="dataViewOptions.enable" id="data" class='assistColumn' [style.flex]="dataViewOptions.width">
      <div class="assistContainer">
        <div class="assistSection">
          <div class="assistInner">          
            <carina-assist-data-view [assistConfig]="config"></carina-assist-data-view>
          </div>
        </div>
      </div>
    </div>
    <carina-resize style="display:flex" resizeId = "2" [(resize)]="isResizing" [leftOpts]="dataViewOptions" rightOpts="actionViewOptions"></carina-resize>
    <div *ngIf="actionViewOptions.enable" id="action" class='assistColumn' [style.flex]="actionViewOptions.width">
      <div class="assistContainer">
        <div class="assistSection">
          <div class="assistInner">    
            <carina-assist-action-view [assistConfig]="config"></carina-assist-action-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 
