
<div #scrollTarget *ngIf="stepData.active" class="assistStep assistRow">
  <div class="stepOffset">
  </div>
  <div class="stepText">
    <div>
      <p>{{stepData.text[0]}}</p>
      <ng-container *ngIf="stepData.text.length > 1" >
        <ul>
          <li *ngFor="let txt of stepData.text.slice(1)">
            {{txt}}
          </li>
        </ul>   
      </ng-container>
    </div>
  </div>
  <div class="stepButtons">
  </div>
</div>
<div *ngIf="stepData.active && stepData.prompt!=''" class="assistStep assistRow">
  <div class="stepOffset">
  </div>
  <div class="stepText stepData">
    <p>{{stepData.prompt}}</p>
  </div>
  <div class="stepButtons">
  </div>
</div>
<div *ngIf="stepData.active" class="assistStep">
  <div class="stepOffset">
  </div>
  <div class="stepText">
    <mat-form-field class="assistEntry" appearance="outline" >
      <input *ngIf="!isTextarea" autocomplete="off" matInput [name]="name" [type]="isPassword && bHidePassword?'password':'text'" [placeholder]="stepData.placeholder" [(ngModel)]="stepData.value" (input)="change()">
      <mat-icon *ngIf="isPassword" matSuffix (click)="bHidePassword = !bHidePassword">{{bHidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      <textarea *ngIf="isTextarea" matInput [name]="name" [placeholder]="stepData.placeholder" [(ngModel)]="stepData.value" (input)="change()" rows="{{rows}}"></textarea>
    </mat-form-field> 
  </div>
  <div class="stepButtons">
    <div class="stepButtonEnd">
      <mat-button-toggle-group *ngIf="!hideButton && !stepData.stepCompleted" name="Next" aria-label="Yes or No">
        <mat-button-toggle class="mat-button" (click)="process('next')">Next</mat-button-toggle>
      </mat-button-toggle-group>
    </div>  
  </div>  
</div> 